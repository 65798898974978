import React, { useState, useEffect, useCallback } from 'react'
import { FiTrash } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import { HeadTitle } from '../../components/HeadTitle'
import api from '../../services/api'
import { privateRoutePaths } from '../../routes/private'

import {
  Container,
  DigitalPresenceContainer,
  DigitalPresence,
  DigitalPresenceItem,
} from './style'

type DigitalPresenceProps = {
  id: string
  name: string
  description: string
  color: string
  fontSize: number
  x: number
  y: number
  image: {
    path: string
  }
}

type DigitalPresenceGroupProps = {
  id: string
  name: string
  description: string
  digital_presence: DigitalPresenceProps[]
}

const DigitalPresenceGroup: React.FC = () => {
  const [digitalPresenceGroup, setDigitalPresenceGroup] =
    useState<DigitalPresenceGroupProps>()

  const { state } = useLocation<DigitalPresenceGroupProps>()

  useEffect(() => {
    async function loadDigitalPresenceGroup(): Promise<void> {
      const response = await api.get(`/digital-presence-group/${state.id}`)
      setDigitalPresenceGroup(response.data)
    }

    loadDigitalPresenceGroup()
  }, [state.id])

  const deleteDigitalPresence = useCallback(async (id: string) => {
    if (
      window.confirm(
        'Deseja realmente excluir? Todos os cards desse grupo também serão excluídos.',
      )
    ) {
      await api.delete(`/digital-presence/${id}`)

      window.location.reload()
    }
  }, [])

  return (
    <Container>
      <HeadTitle
        title={`Presença Digital ${digitalPresenceGroup?.name}`}
        description={digitalPresenceGroup?.description}
        navigate={{
          name: 'Criar Presença Digital',
          to: privateRoutePaths.createDigitalPresenceImage,
          state,
        }}
        goBack
      />

      <DigitalPresenceContainer>
        {digitalPresenceGroup?.digital_presence.map(digitalPresence => (
          <DigitalPresenceItem key={digitalPresence.id}>
            <FiTrash
              title="Excluir card"
              onClick={() => deleteDigitalPresence(digitalPresence.id)}
            />
            <DigitalPresence>
              <img
                src={digitalPresence.image.path}
                alt={digitalPresence.name}
              />
            </DigitalPresence>
          </DigitalPresenceItem>
        ))}
      </DigitalPresenceContainer>
    </Container>
  )
}

export default DigitalPresenceGroup
