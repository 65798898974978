import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  ChangeEvent,
} from 'react'
import { useField } from '@unform/core'

import { Icon } from '../Icon'

import {
  Container,
  Error,
  IconContainer,
  InputButton,
  InputContainer,
  InputInfoContainer,
  InputUploadContainer,
} from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  visible?: boolean
  label?: string
  icon?: string
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const InputUpload: React.FC<InputProps> = ({
  name,
  visible = true,
  label,
  icon = 'file',
  onInputChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [fileName, setFileName] = useState<string | null>(null)

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    setIsFilled(!!inputRef.current?.value)
  }, [])

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      clearError()
      if (onInputChange) onInputChange(e)
      if (e.target.files && e.target.files[0]) {
        setFileName(e.target.files[0].name)
        return
      }

      setFileName(null)
    },
    [onInputChange, clearError],
  )

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      isVisible={visible}
    >
      <IconContainer>
        <Icon name={icon} fontSize={16} />
      </IconContainer>

      <InputContainer>
        <InputInfoContainer>
          <p>{label}</p>
          <p>{fileName ?? 'Escolha o arquivo...'}</p>
        </InputInfoContainer>

        <InputUploadContainer>
          <InputButton htmlFor={name}>Escolher</InputButton>
          {error && (
            <Error title={error}>
              <Icon name="exclamation" />
            </Error>
          )}
        </InputUploadContainer>
      </InputContainer>

      <input
        id={name}
        defaultValue={defaultValue}
        ref={inputRef}
        {...rest}
        type="file"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
      />
    </Container>
  )
}

export default InputUpload
