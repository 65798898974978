import React, { useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Container, FormContainer } from './styles'
import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import InputUpload from '../../components/InputUpload'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { useToast } from '../../hooks/toast'
import * as Yup from 'yup'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { HeadTitle } from '../../components/HeadTitle'
import { AxiosError } from 'axios'

interface PlanItem {
  itemName: string
  updateRoute: string
  id: string
  plan_id: string
  active: string
  name: string
  attachment_path: string
  attachments: {
    id: string
    name: string
    path: string
    updated_at: string
    created_at: string
  }
  created_at: string
  updated_at: string
}

interface PlanItemFormData {
  id: string
  name: string
  attachment_path: string
}

const EditPlanItem: React.FC = () => {
  const { state }: { state: PlanItem } = useLocation()
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { addToast } = useToast()

  const planItem = { ...state }

  const handleSubmit = useCallback(
    async (data: PlanItemFormData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          attachment_path: Yup.string(),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newData = new FormData()

        newData.append('name', data.name)
        if (data.attachment_path)
          newData.append('attachment_path', data.attachment_path)

        await api.put(`/${planItem.updateRoute}/${planItem.id}`, newData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        addToast({
          type: 'success',
          title: 'Atualizado com sucesso!',
          description: 'Atualizado com sucesso.',
        })

        history.goBack()
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      }
    },
    [addToast, history, planItem],
  )

  return (
    <Container>
      <HeadTitle
        title={`Alterar ${planItem.itemName}: ${planItem.name}`}
        goBack
      />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          name: planItem.name,
        }}
      >
        <FormContainer>
          <InputUpload name="attachment_path" label="Anexo" />
          <Input name="name" label="Nome" />
          <Button type="submit">Atualizar {planItem.itemName}</Button>
        </FormContainer>
      </Form>
    </Container>
  )
}

export default EditPlanItem
