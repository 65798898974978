import styled, { css } from 'styled-components'
import { appearFromRight, growDown } from '../../styles/global'

export const Container = styled.div`
  display: flex;
  position: relative;
`

interface SideMenuProps {
  isSideMenuOpen: boolean
}

export const SideMenu = styled.div<SideMenuProps>`
  position: sticky;
  width: 285px;
  top: 0;
  height: 100vh;
  background: var(--element);
  z-index: 9999;
  box-shadow: 0px 0px 1px gray;
  transition: width 0.4s ease;

  @media (max-width: 500px) {
    position: fixed;
    width: 100%;
  }

  ${({ isSideMenuOpen }) =>
    !isSideMenuOpen &&
    css`
      width: 5rem;

      @media (max-width: 500px) {
        width: 0;
        overflow: hidden;
      }
    `}
`

interface SideLogoContainerProps extends SideMenuProps {
  isDarkTheme: boolean
}

export const SideLogoContainer = styled.div<SideLogoContainerProps>`
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 1.25rem;
  justify-content: space-between;
  margin-bottom: 4rem;

  img {
    width: 7.5rem;
    transition: width 0.4s ease;

    ${({ isSideMenuOpen }) =>
      !isSideMenuOpen &&
      css`
        width: 0;
      `}

    ${({ isDarkTheme }) =>
      isDarkTheme &&
      css`
        filter: brightness(200);
      `}
  }

  i {
    cursor: pointer;

    ${({ isSideMenuOpen }) =>
      !isSideMenuOpen &&
      css`
        transform: translateX(-70%);
      `}
  }

  i:last-child {
    display: none;
  }

  @media (max-width: 500px) {
    i {
      display: none;
    }

    i:last-child {
      display: block;
    }
  }
`

export const SideList = styled.ul<SideMenuProps>`
  display: grid;
  list-style: none;
  row-gap: 0.5rem;
  align-items: center;
  padding: 0 1.25rem;
  transition: padding 0.2s ease;

  ${({ isSideMenuOpen }) =>
    !isSideMenuOpen &&
    css`
      padding: 0 0.5rem;
    `}

  a {
    display: flex;
    font-size: 0.9375rem;
    padding: 0 1.39rem;
    height: 50px;
    cursor: pointer;
    position: relative;
    align-items: center;
    max-height: 60px;
    text-decoration: none;
    color: var(--text);
    border-radius: 15px;
    position: relative;

    span {
      max-width: 150px;
      transition: all 0.4s ease;
      margin-left: 1rem;
    }

    ${({ isSideMenuOpen }) =>
      !isSideMenuOpen &&
      css`
        text-align: center;
        padding: 0 1.39rem;

        span {
          opacity: 0;
          pointer-events: none;
          max-width: 0;
          margin: 0;
        }

        &:before {
          content: attr(data-title);
          position: absolute;
          background: var(--element);
          width: 100px;
          right: -150px;
          pointer-events: none;
          visibility: hidden;
          padding: 10px;
          border-radius: 8px;
          color: var(--text);
        }

        &:after {
          content: '';
          rotate: 45deg;
          position: absolute;
          width: 10px;
          height: 10px;
          background: var(--element);
          right: -35px;
          visibility: hidden;
        }

        &:hover {
          &:after,
          &:before {
            animation: ${appearFromRight} 0.2s;
            visibility: visible;
          }
        }
      `}

    &:hover,
    &.active {
      background: var(--app-gradient);
      color: #fff;

      i {
        color: #fff;
      }
    }
  }
`

export const NavMenu = styled.nav`
  display: flex;
  padding: 0 1.5rem;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  position: sticky;
  width: 100%;
  top: 0;
  height: 72px;
  background: var(--background);
  z-index: 9998;
  box-shadow: gray 0px 0.2px;
`

export const AdviceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  background: var(--purple);
  color: var(--white);

  span {
    font-size: 12px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`

export const SideMenuToggleContainer = styled.div`
  display: none;

  i {
    cursor: pointer;
    transition: filter 0.2s ease;

    &:hover {
      filter: brightness(0.7);
    }
  }

  @media (max-width: 500px) {
    display: block;
  }
`

export const NavProfileDropdown = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
`

export const SwitchThemeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  background: var(--element);
  transition: background 0.4s ease-in-out;

  span {
    max-width: 1rem;
    max-height: 1rem;
    color: var(--text);
  }

  i {
    font-size: 1rem;
  }

  &:focus {
    outline: none;
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  height: 100%;

  img {
    max-width: 40px;
    max-width: 40px;
    border-radius: 50%;
    object-fit: contain;
  }

  div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &:hover {
    ul {
      display: block;
    }
  }
`

export const ProfileDropdownMenu = styled.ul`
  display: none;
  position: absolute;
  top: 72px;
  width: 12.5rem;
  background: var(--element);
  padding: 0;
  margin: 0;
  animation: ${growDown} 300ms ease-in-out forwards;
  transform-origin: top center;
  list-style: none;

  li {
    width: 100%;

    a,
    button {
      display: flex;
      width: 100%;
      padding: 1rem 2rem;
      align-items: center;
      gap: 1rem;
      text-decoration: none;
      color: var(--text);
      font-size: 1.1rem;
    }

    button {
      border: 0;
      background: transparent;
    }

    svg {
      font-size: 1.5rem;
    }

    &:hover,
    .active {
      background: var(--background);
    }
  }
`

export const ContentContainer = styled.div`
  width: 100%;
`

export const Content = styled.div`
  margin: 2rem auto;
  max-width: 1256px;
  width: 100%;
  padding: 0 2rem;
`
