import axios from 'axios'
import { getToken, removeToken } from './auth'

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  baseURL: 'https://api.fs.grupotexas.com.br',
})

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (
      error.request._hasError === true &&
      error.request._response.includes('connect')
    ) {
      alert(
        'Não foi possível conectar aos nossos servidores, sem conexão a internet',
      )
    }

    if (error.response.status === 401) {
      const requestConfig = error.config

      removeToken()

      window.location.reload()

      return axios(requestConfig)
    }

    return Promise.reject(error)
  },
)

api.interceptors.request.use(async config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default api
