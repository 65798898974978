import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  gap: 1rem;
  max-height: 126px;

  div {
    display: flex;
    align-items: flex-end;
  }

  div:first-child {
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;

    button {
      background: var(--red-500);
      margin: 0;
    }
  }
`

export const LogoPreview = styled.img`
  width: 18.75rem;
  background: var(--white);
  border-radius: 1rem;
  padding: 1rem;
  height: 126px;
`

export const LabelButton = styled.label`
  background: var(--app-gradient);
  height: 56px;
  border-radius: 10px;
  border: 0;
  color: #fff;
  width: 12rem;
  font-weight: 500;
  transition: filter 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: end;

  &:hover {
    filter: brightness(0.8);
  }
`

export const ProfileForm = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`
