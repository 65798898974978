import React, { useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'

import profileLogo from '../../assets/profile-logo.svg'
import { useAuth } from '../../hooks/auth'
import { Icon } from '../Icon'
import { privateRoutePaths } from '../../routes/private'
import { useTheme } from '../../hooks/theme'

import {
  Container,
  Content,
  SideMenu,
  NavMenu,
  ContentContainer,
  SideLogoContainer,
  SideList,
  NavProfileDropdown,
  SwitchThemeButton,
  ProfileContainer,
  ProfileDropdownMenu,
  SideMenuToggleContainer,
  AdviceContainer,
} from './styles'

const SidebarAndNavbar: React.FC = ({ children }) => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(
    window.innerWidth >= 1000,
  )
  const { company, signOut } = useAuth()
  const { theme, toggleTheme } = useTheme()
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        toggleSideMenu(window.innerWidth >= 1000)
      },
      false,
    )
  }, [])

  history.listen(() => {
    if (window.innerWidth <= 500) {
      setIsSideMenuOpen(false)
    }
  })

  const toggleSideMenu = (state: boolean) => {
    setIsSideMenuOpen(state)
  }

  return (
    <Container>
      <SideMenu isSideMenuOpen={isSideMenuOpen}>
        <SideLogoContainer isSideMenuOpen={isSideMenuOpen} isDarkTheme={theme}>
          <img src={company.image.path} alt={company.name} />
          <Icon
            name="bars-sort"
            onClick={() => toggleSideMenu(!isSideMenuOpen)}
          />
          <Icon name="cross" onClick={() => toggleSideMenu(!isSideMenuOpen)} />
        </SideLogoContainer>
        <SideList isSideMenuOpen={isSideMenuOpen}>
          <NavLink to={privateRoutePaths.home} data-title="Início">
            <Icon name="home" />
            <span>Início</span>
          </NavLink>
          <NavLink to={privateRoutePaths.insurances} data-title="Seguros">
            <Icon name="table-list" />
            <span>Seguros</span>
          </NavLink>
          <NavLink to={privateRoutePaths.campaigns} data-title="Campanhas">
            <Icon name="megaphone" />
            <span>Campanhas</span>
          </NavLink>
          <NavLink
            to={privateRoutePaths.digitalPresenceGroups}
            data-title="Presença Digital"
          >
            <Icon name="copy-image" />
            <span>Presença Digital</span>
          </NavLink>
          <NavLink to={privateRoutePaths.quote} data-title="Cotação">
            <Icon name="file-invoice-dollar" />
            <span>Cotação</span>
          </NavLink>
          <NavLink
            to={privateRoutePaths.notifications}
            data-title="Informativos"
          >
            <Icon name="bell" />
            <span>Informativos</span>
          </NavLink>
        </SideList>
      </SideMenu>
      <ContentContainer>
        <NavMenu>
          <SideMenuToggleContainer>
            <Icon
              name="menu-burger"
              onClick={() => toggleSideMenu(!isSideMenuOpen)}
            />
          </SideMenuToggleContainer>
          <AdviceContainer>
            <span>
              Você está no painel administrativo como:{' '}
              <strong>{company.name}</strong>
            </span>
          </AdviceContainer>
          <NavProfileDropdown>
            <SwitchThemeButton onClick={toggleTheme}>
              <span role="img" aria-label={theme ? 'sun' : 'moon'}>
                <Icon name={theme ? 'brightness' : 'moon-stars'} />
              </span>
            </SwitchThemeButton>
            <ProfileContainer>
              <img src={profileLogo} alt={company.name} />
              <div>
                <span>{company.name}</span>
                <Icon name="angle-down" fontSize={12} />
              </div>
              <ProfileDropdownMenu>
                <li>
                  <NavLink to={privateRoutePaths.profile}>
                    <Icon name="user" /> Perfil
                  </NavLink>
                </li>
                <li>
                  <button onClick={signOut}>
                    <Icon name="exit" /> Sair
                  </button>
                </li>
              </ProfileDropdownMenu>
            </ProfileContainer>
          </NavProfileDropdown>
        </NavMenu>
        <Content>{children}</Content>
      </ContentContainer>
    </Container>
  )
}

export default SidebarAndNavbar
