import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { AxiosError } from 'axios'

import Input from '../../../components/Input'
import Select from '../../../components/Select'
import Button from '../../../components/Button'
import { HeadTitle } from '../../../components/HeadTitle'
import { useToast } from '../../../hooks/toast'
import api from '../../../services/api'
import { Product } from '../../../@types/Product'
import { CustomPriceTable } from '../../../@types/CustomPriceTable'

import { Container, CustomTableFormContainer } from './styles'
import { privateRoutePaths } from '../../../routes/private'
import getValidationErrors from '../../../utils/getValidationErrors'

export const EditPlanTable = () => {
  const [currentTable, setCurrentTable] = useState<CustomPriceTable>(
    {} as CustomPriceTable,
  )
  const [products, setProducts] = useState<Product[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { addToast } = useToast()
  const { planId, tableId } = useParams<{ planId: string; tableId: string }>()

  useEffect(() => {
    const loadProducts = async () => {
      const createdTables = await api.get<CustomPriceTable[]>(
        `/custom-price-tables/${planId}`,
      )
      const products = await api.get<Product[]>(`/plan-products/${planId}`)

      const filteredProducts = products.data.filter(
        p =>
          !createdTables.data
            .filter(ct => ct.id !== tableId)
            .some(ct => ct.products.some(ctp => ctp.id === p.id)),
      )
      const currentTable = createdTables.data.find(
        ct => ct.id === tableId,
      ) as CustomPriceTable

      setCurrentTable(currentTable)
      setProducts(filteredProducts)
      setIsLoading(false)
    }

    loadProducts()
  }, [])

  const handleSubmit = async (data: { name: string; productsId: string[] }) => {
    setIsLoading(true)
    try {
      formRef.current?.setErrors({})

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome da associação é obrigatório.'),
        productsId: Yup.array(Yup.string().uuid()),
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      await api.put(`/custom-price-tables/${tableId}`, { ...data, planId })

      addToast({
        type: 'success',
        title: 'Êxito!',
        description: `Tabela ${data.name} editada com sucesso!`,
      })

      history.push(
        privateRoutePaths.insurancePlanTables.replace(':planId', planId),
      )
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current?.setErrors(errors)

        return
      }

      const error = err as AxiosError<{ message: string }>
      addToast({
        type: 'error',
        title: 'Oops!',
        description:
          error.response?.data.message || 'Erro interno no servidor.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading && !currentTable?.id) return <h1>Carregando informações...</h1>

  return (
    <Container>
      <HeadTitle
        title="Crie uma nova Tabela"
        description="Preencha as informações abaixo para cadastrar uma nova tabela."
        goBack
      />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <CustomTableFormContainer>
          <Input
            type="text"
            name="name"
            label="Nome da Tabela"
            icon="id-card-clip-alt"
            defaultValue={currentTable.name}
          />

          <Select
            name="productsId"
            label="Produtos da Tabela"
            options={products.map(p => ({ value: p.id, label: p.name }))}
            defaultValue={currentTable.products.map(p => ({
              value: p.id,
              label: p.name,
            }))}
            isMulti
          />
        </CustomTableFormContainer>

        <Button type="submit" disabled={isLoading}>
          Cadastrar
        </Button>
      </Form>
    </Container>
  )
}
