import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  row-gap: 3rem;

  form {
    display: grid;
    row-gap: 1rem;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HasIOFContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const HasIOFLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 95px;
  height: 34px;

  input {
    display: none;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3c3c3c;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:after {
      content: 'NÃO';
      color: white;
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      font-size: 10px;
      font-family: Verdana, sans-serif;
    }

    &:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + span {
    background-color: var(--purple);
  }

  input:focus + span {
    box-shadow: 0 0 1px var(--purple);
  }

  input:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(60px);
  }

  input:checked + span:after {
    content: 'SIM';
  }
`

export const ImportProductDataContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  input[type='file'] {
    display: none;
  }

  label {
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    background: var(--app-gradient);
    width: fit-content;
    border-radius: 15px;
    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const ImportRefNetDataContainer = styled(ImportProductDataContainer)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const FirstFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
`

export const SecondFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ThirdFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
`

export const LastFormContainer = styled(ThirdFormContainer)`
  margin-top: 50px;
  display: unset;

  > div {
    display: flex;
    gap: 1rem;

    button {
      max-width: 140px;
    }

    @media (max-width: 1000px) {
      flex-direction: column;

      button {
        max-width: unset;
      }
    }
  }
`

export const RefnetContainer = styled.div`
  margin: 30px 0;

  p {
    display: flex;
    white-space: nowrap;

    hr {
      width: -webkit-fill-available;
      margin: 10px;
    }

    i {
      margin-left: 15px;
      cursor: pointer;
    }
  }
`
