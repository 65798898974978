export const darkTheme = {
  background: '#0F0F0F',
  element: '#191919',
  titles: '#E8ECFF',
  cardTitleColor: '#E8ECFF',
  text: '#E8ECFF',
  icons: '#E8ECFF',
  variant1: '#7032FF',
  variant2: '#2266AA',
  variant3: '#F1F1F1',
  variant4: '#F1F1F1'
}
