import React, { useEffect, useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import { HeadTitle } from '../../../../components/HeadTitle'
import Input from '../../../../components/Input'
import Select from '../../../../components/Select'
import Button from '../../../../components/Button'
import api from '../../../../services/api'

import { AssociationFormContainer, Container } from './styles'
import { useToast } from '../../../../hooks/toast'
import getValidationErrors from '../../../../utils/getValidationErrors'
import { AxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import { privateRoutePaths } from '../../../../routes/private'

interface ProductEntity {
  id: string
  active: boolean
  name: string
  updated_at: Date
  created_at: Date
}

interface FormData {
  name: string
  professions: string[]
}

const CreateAssociation = () => {
  const [professions, setProfessions] = useState<ProductEntity[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { addToast } = useToast()

  useEffect(() => {
    const getProfessions = async () => {
      const professions = await api.get('/professions')
      setProfessions(professions.data)
    }
    getProfessions()
  }, [])

  const handleSubmit = async (data: FormData) => {
    setIsLoading(true)
    try {
      formRef.current?.setErrors({})

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome da associação é obrigatório.'),
        professions: Yup.array(Yup.string().uuid()).required(
          'Selecione ao menos uma profissão para vincular à associação.',
        ),
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      await api.post('/associations', data)

      addToast({
        type: 'success',
        title: 'Êxito!',
        description: `Associação ${data.name} cadastrada com sucesso!`,
      })

      history.push(privateRoutePaths.quoteAssociations)
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current?.setErrors(errors)

        return
      }

      const error = err as AxiosError<{ message: string }>
      addToast({
        type: 'error',
        title: 'Oops!',
        description:
          error.response?.data.message || 'Erro interno no servidor.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <HeadTitle
        title="Crie uma nova Associação"
        description="Preencha as informações abaixo para cadastrar uma nova associação."
        goBack
      />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <AssociationFormContainer>
          <Input
            type="text"
            name="name"
            label="Nome da Associação"
            icon="id-card-clip-alt"
          />

          <Select
            name="professions"
            label="Profissões da Associação"
            options={professions.map(p => ({ value: p.id, label: p.name }))}
            isMulti
          />
        </AssociationFormContainer>

        <Button type="submit" disabled={isLoading}>
          Cadastrar
        </Button>
      </Form>
    </Container>
  )
}

export default CreateAssociation
