import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  margin-top: 72px;
  right: 0;
  top: 0;
  padding: 30px;
  overflow: hidden;
  max-height: 100%;
  z-index: 10000;
`
