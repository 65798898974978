import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as Yup from 'yup'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { AxiosError } from 'axios'
import Lottie from 'lottie-react'

import InputUpload from '../../components/InputUpload'
import Input from '../../components/Input'
import Button from '../../components/Button'
import getValidationErrors from '../../utils/getValidationErrors'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import Select from '../../components/Select'
import { HeadTitle } from '../../components/HeadTitle'
import emptyCampaigns from '../../assets/empty-campaigns.json'
import { Icon } from '../../components/Icon'

import {
  CallToAction,
  Campaign,
  CampaignButton,
  CampaignHeader,
  CampaignsContainer,
  Container,
  FormContainer,
  ObsContainer,
} from './styles'

export enum PlatformType {
  MOBILE = 'mobile',
  WEB = 'web',
}

interface CampaignFormData {
  image: string
  link?: string
  platform: PlatformType
}

interface Campaign {
  id: string
  platform: PlatformType
  company_id: string
  image_path: string
  link?: string
  updated_at: Date
  created_at: Date
  image: {
    id: string
    name: string
    created_at: Date
    updated_at: Date
    path: string
  }
}

const Campaigns: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const [isLoading, setIsLoading] = useState({
    request: false,
    page: true,
  })
  const [callToAction, setCallToAction] = useState(true)
  const [platform, setPlatform] = useState<PlatformType | null>()
  const [imageToTest, setImageToTest] = useState<string | null>()
  const [tab, setTab] = useState<PlatformType>(PlatformType.MOBILE)
  const formRef = useRef<FormHandles>(null)
  const { addToast } = useToast()

  useEffect(() => {
    const getCampaigns = async () => {
      const request = await api.get<Campaign[]>('/campaigns')

      setCampaigns(request.data)
      setIsLoading(curIsLoading => ({ ...curIsLoading, page: false }))
      setCallToAction(request.data.length === 0)
    }
    void getCampaigns()
  }, [])

  const handleSubmit = useCallback(
    async (data: CampaignFormData) => {
      setIsLoading(curIsLoading => ({ ...curIsLoading, request: true }))
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          link: Yup.string(),
          image: Yup.string()
            .required('Imagem obrigatória')
            .test('ImageSize', '', async function () {
              if (imageToTest) {
                const image = new Image()
                image.src = imageToTest
                const loadedImage = await new Promise<HTMLImageElement>(
                  (resolve, reject) => {
                    image.onload = () => resolve(image)
                    image.onerror = () =>
                      reject(new Error('Não foi possível carregar a imagem'))
                  },
                )
                if (platform === PlatformType.MOBILE) {
                  const mobileImageCheck =
                    loadedImage.width === 335 && loadedImage.height === 124
                  if (!mobileImageCheck)
                    return this.createError({
                      message: 'O tamanho da imagem mobile deve ser 335x124',
                    })
                  return mobileImageCheck
                }

                if (platform === PlatformType.WEB) {
                  const webImageCheck =
                    loadedImage.width === 972 && loadedImage.height === 187
                  if (!webImageCheck)
                    return this.createError({
                      message: 'O tamanho da imagem web deve ser 972x187',
                    })
                  return webImageCheck
                }

                return this.createError({
                  message: 'Selecione a plataforma para continuar.',
                })
              }
              return this.createError({
                message: 'A imagem da campanha é obritatória.',
              })
            }),
          platform: Yup.mixed().oneOf<PlatformType>(
            Object.values(PlatformType) as PlatformType[],
            'A plataforma é obrigatória.',
          ),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newData = new FormData()

        newData.append('image', data.image)
        newData.append('platform', data.platform)
        if (data.link) newData.append('link', data.link)

        const { data: newCampaign } = await api.post('/campaigns', newData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        const currentCampaigns = campaigns
        const updatedCampaigns = [...currentCampaigns, newCampaign]
        setCampaigns(updatedCampaigns)

        formRef.current?.reset({ platform: '0' })

        setTab(data.platform)
        addToast({
          type: 'success',
          title: 'Cadastrado com sucesso!',
          description: 'Campanha cadastrada com sucesso.',
        })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Oops!',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(curIsLoading => ({ ...curIsLoading, request: false }))
      }
    },
    [addToast, campaigns, imageToTest, platform],
  )

  const handleSetImageToTest = (e: ChangeEvent<HTMLInputElement>) => {
    const ALLOWED_EXTENSIONS = ['image/png', 'image/jpeg']
    const image = e.target.files?.[0]

    if (!image) {
      setImageToTest(null)
    }

    if (image) {
      if (!ALLOWED_EXTENSIONS.includes(image.type)) {
        formRef.current?.clearField('image')
        return addToast({
          title: 'Oops!',
          description: 'Apenas imagens em formato PNG ou JPEG são permitidos.',
          type: 'error',
        })
      }

      setImageToTest(URL.createObjectURL(image))
    }
  }

  const handleDelete = useCallback(
    async (campaign_id: string) => {
      setIsLoading(curIsLoading => ({ ...curIsLoading, request: true }))
      try {
        await api.delete(`/campaigns/${campaign_id}`)

        const currentCampaigns = campaigns
        const updatedCampaigns = currentCampaigns.filter(
          campaign => campaign.id !== campaign_id,
        )
        setCampaigns(updatedCampaigns)

        addToast({
          type: 'success',
          title: 'Êxito!',
          description: 'Campanha deletada com sucesso.',
        })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Oops!',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(curIsLoading => ({ ...curIsLoading, request: false }))
      }
    },
    [campaigns, addToast],
  )

  const handlePlatformChange = useCallback((newValue: any) => {
    setPlatform(newValue.value)
  }, [])

  if (isLoading.page) {
    return <p>Carregando...</p>
  }

  if (callToAction) {
    return (
      <CallToAction>
        <Lottie animationData={emptyCampaigns} />
        <div>
          <strong>Vamos criar a nossa primeira campanha?</strong>
          <p>Cadastre banners de campanhas para engajar seus consultores.</p>

          <Button onClick={() => setCallToAction(false)}>
            Criar nova campanha
          </Button>
        </div>
      </CallToAction>
    )
  }

  return (
    <Container>
      <HeadTitle title="Criar nova campanha" goBack />

      <ObsContainer>
        <div>
          <Icon name="triangle-warning" fontSize={20} />
          <h3>Observações:</h3>
        </div>
        <div>
          <p>
            • O tamanho de imagem permitido para campanha mobile é de 335x124.
          </p>
          <p>• O tamanho de imagem permitido para campanha web é de 972x187.</p>
          <p>
            • O link não é obrigatório, adicione se quiser que o usuário seja
            redirecionado à algum site quando clicar na campanha.
          </p>
          <p>
            • Para excluir a campanha basta apenas clicar em excluir e ela será
            deletada.
          </p>
          <p>
            • O máximo de campanhas ativas é 5 por plataforma (mobile/web), para
            adicionar uma nova precisará excluir uma das 5.
          </p>
        </div>
      </ObsContainer>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormContainer>
          <InputUpload
            name="image"
            label="Imagem da campanha"
            accept="image/png, image/jpeg"
            onInputChange={handleSetImageToTest}
            disabled={isLoading.request}
          />

          <Input
            name="link"
            label="Link da campanha (não obrigatório)"
            icon="link-alt"
            disabled={isLoading.request}
          />

          <Select
            name="platform"
            options={[
              { value: PlatformType.MOBILE, label: 'Mobile' },
              { value: PlatformType.WEB, label: 'Web' },
            ]}
            icon="data-transfer"
            label="Plataforma"
            onChange={handlePlatformChange}
            isDisabled={isLoading.request}
          />
        </FormContainer>
        <Button type="submit" disabled={isLoading.request}>
          Criar campanha
        </Button>
      </Form>

      <CampaignHeader>
        <HeadTitle title={`Campanhas ativas (${tab})`} />
        <div>
          <CampaignButton
            active={tab === PlatformType.MOBILE}
            onClick={() => setTab(PlatformType.MOBILE)}
          >
            Mobile
          </CampaignButton>
          <CampaignButton
            active={tab === PlatformType.WEB}
            onClick={() => setTab(PlatformType.WEB)}
          >
            Web
          </CampaignButton>
        </div>
      </CampaignHeader>

      <CampaignsContainer>
        {campaigns.filter(c => c.platform === tab).length > 0 ? (
          campaigns
            .filter(c => c.platform === tab)
            .map(campaign => (
              <Campaign key={campaign.id} platform={tab}>
                <div>
                  <img src={campaign.image.path} alt={campaign.image.path} />
                  <p>
                    Data da campanha:{' '}
                    {format(new Date(campaign.created_at), 'dd/MM/yyyy', {
                      locale: ptBR,
                    })}
                  </p>
                </div>
                <Button
                  onClick={() => handleDelete(campaign.id)}
                  disabled={isLoading.request}
                >
                  Excluir
                </Button>
              </Campaign>
            ))
        ) : (
          <h2>Nenhuma campanha para {tab} encontrada, crie uma nova!</h2>
        )}
      </CampaignsContainer>
    </Container>
  )
}

export default Campaigns
