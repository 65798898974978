import styled, { Keyframes, keyframes } from 'styled-components'

export const Container = styled.div`
  background: var(--element);
  border-radius: 4px;
  padding: 30px;
`

export const DataList = styled.ul`
  list-style: none;
  margin-top: 33px;

  li {
    display: grid;
    grid-template-columns: 1.5fr 2fr 1.5fr;
    align-items: baseline;
    font-size: 12px;
    margin-bottom: 1.3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    font-size: 11px;
    text-align: end;
    letter-spacing: 0.5px;
  }
`

interface PercentageProps {
  width: number
  totalAmount: number
}

const progressBarAnimation = ({
  width,
  totalAmount,
}: PercentageProps): Keyframes => keyframes`
  0% {
    width: 0;
  }
  100% {
    width: ${(width / totalAmount) * 100}%;
  }
`

export const ProgressContainer = styled.div<PercentageProps>`
  background: var(--background);
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin: 0 10px;
  width: 100%;

  div {
    animation: ${props => progressBarAnimation(props)} 4s forwards;
    box-shadow: 0 10px 40px -10px #fff;
    background: var(--app-gradient);
    height: 7px;
    width: ${props => props.width}%;
  }
`
