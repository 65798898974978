import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const PlanTypeContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
`

export const PlanTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--element);
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  position: relative;

  a {
    width: 50%;
  }

  i {
    position: absolute;
    right: 30px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  img {
    width: 40px;
    height: 40px;
  }
`
