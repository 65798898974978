import React, { useCallback, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { AxiosError } from 'axios'

import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import getValidationErrors from '../../utils/getValidationErrors'
import { demask, normalizerCNPJNumber } from '../../utils/normalizer'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { publicRoutesPath } from '../../routes/public'
import { privateRoutePaths } from '../../routes/private'
import { Icon } from '../../components/Icon'

import { Background, Container, Content, FormContainer } from './styles'

interface SignInFormData {
  cnpj: string
  password: string
}

const SignIn: React.FC = () => {
  const [isLogging, setIsLogging] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  const { signIn } = useAuth()
  const { addToast } = useToast()

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      setIsLogging(true)
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          cnpj: Yup.string()
            .min(18, 'Digite um CNPJ válido')
            .max(18, 'Digite um CNPJ válido')
            .required('CNPJ obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await signIn({
          login: demask(data.cnpj),
          password: data.password,
        })

        history.push(privateRoutePaths.home)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao fazer login, cheque as credenciais.',
        })
      } finally {
        setIsLogging(false)
      }
    },
    [addToast, history, signIn],
  )

  return (
    <Background>
      <Container>
        <Content>
          <Icon name="briefcase" fontSize={40} />
          <h1>Painel da Unidade</h1>
          <p>
            Olá. seja bem-vindo ao painel de administração do aplicativo{' '}
            <strong>Grupo Texas</strong>.
          </p>
          <p>Faça o login na sua unidade:</p>
        </Content>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormContainer>
            <Input
              name="cnpj"
              icon="briefcase"
              label="CNPJ"
              maxLength={18}
              onChange={() =>
                formRef.current?.setFieldValue(
                  'cnpj',
                  normalizerCNPJNumber(formRef.current.getFieldValue('cnpj')),
                )
              }
              disabled={isLogging}
            />
            <Input
              name="password"
              icon="lock"
              type="password"
              label="Senha"
              disabled={isLogging}
            />
            <Button type="submit" disabled={isLogging}>
              Entrar
            </Button>
          </FormContainer>
          <Link to={publicRoutesPath.recoverPassword}>Esqueci minha senha</Link>
        </Form>
      </Container>
    </Background>
  )
}

export default SignIn
