import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-height: 104px;
  position: relative;
  padding: 20px 30px;
  background: var(--element);
  border-radius: 1rem;
`

export const Data = styled.h1`
  width: fit-content;
  background: var(--app-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 25px;
`
