import React, { useEffect, useState } from 'react'
import { CustomTableContainer, Container } from './styles'
import { HeadTitle } from '../../components/HeadTitle'
import CustomTableCard from './components/CustomTableCard'
import api from '../../services/api'
import { privateRoutePaths } from '../../routes/private'
import { EmptyState } from '../../components/EmptyState'
import emptyCustomTables from '../../assets/empty-custom-tables.json'
import { useParams } from 'react-router-dom'
import { CustomPriceTable } from '../../@types/CustomPriceTable'

const PlanTables = () => {
  const [priceTables, setPriceTables] = useState<CustomPriceTable[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const params: { planId: string } = useParams()

  useEffect(() => {
    async function loadCustomTables(): Promise<void> {
      const { data } = await api.get(`/custom-price-tables/${params.planId}`)

      setPriceTables(data)
      setIsLoading(false)
    }

    loadCustomTables()
  }, [params.planId])

  const handleDeleteTable = async (tableId: string) => {
    if (!window.confirm('Tem certeza que deseja excluir essa tabela?')) return

    await api.delete(`/custom-price-tables/${tableId}`)

    setPriceTables(priceTables.filter(pt => pt.id !== tableId))
  }

  if (isLoading) return <h1>Carregando informações...</h1>

  if (priceTables.length === 0)
    return (
      <EmptyState
        image={emptyCustomTables}
        title="Cadastre uma nova tabela de valores personalizada!"
        style={{
          maxHeight: '500px',
        }}
        desc="Distribua os produtos em tabelas de valores diferentes."
        goToButton={{
          to: privateRoutePaths.createInsurancePlanTable.replace(
            ':planId',
            params.planId,
          ),
          title: 'Criar nova tabela',
        }}
      />
    )

  return (
    <Container>
      <HeadTitle
        title="Tabelas de Valores"
        description="Você pode encontrar todas as tabelas de valores cadastradas aqui."
        navigate={{
          to: privateRoutePaths.createInsurancePlanTable.replace(
            ':planId',
            params.planId,
          ),
          name: 'Criar nova tabela',
        }}
        goBack
      />

      <CustomTableContainer>
        {priceTables.map(pt => (
          <CustomTableCard
            key={pt.id}
            tableId={pt.id}
            planId={params.planId}
            name={pt.name}
            products={pt.products.map(p => p.name)}
            onDeleteTable={handleDeleteTable}
          />
        ))}
      </CustomTableContainer>
    </Container>
  )
}

export default PlanTables
