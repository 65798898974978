import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 3rem;
`

export const QuoteEntityContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;

  a {
    text-decoration: none;
    color: var(--text);
  }
`

export const NavigateCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--element);
  height: 190px;
  padding: 35px;
`

export const NavigateCardIconContainer = styled.div`
  width: fit-content;
  background: var(--background);
  padding: 20px;
  border-radius: 50%;
`

export const NavigateCardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
