import React, { createContext, useState, useContext } from 'react'

type ThemeContextType = {
  theme: boolean
  toggleTheme: () => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

const AppThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<boolean>(() => {
    const savedTheme = localStorage.getItem('texas@theme')

    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')

    return savedTheme ? JSON.parse(savedTheme) : systemTheme.matches
  })

  const toggleTheme = () => {
    setTheme(prevTheme => !prevTheme)
    localStorage.setItem('texas@theme', JSON.stringify(!theme))
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

const useTheme = () => {
  const context = useContext(ThemeContext)

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }

  return context
}

export { AppThemeProvider, useTheme }
