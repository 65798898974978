import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../../components/Button'
import Input from '../../components/Input'
import InputUpload from '../../components/InputUpload'
import { useToast } from '../../hooks/toast'
import {
  Container,
  ImagePreview,
  ImagePreviewContainer,
  TextPreview,
  DigitalPresenceContainer,
  DigitalPresenceFormContainer,
  DigitalPresenceFormFontContainer,
} from './style'
import * as Yup from 'yup'
import getValidationErrors from '../../utils/getValidationErrors'
import api from '../../services/api'
import { HeadTitle } from '../../components/HeadTitle'
import { AxiosError } from 'axios'
import { privateRoutePaths } from '../../routes/private'

type DigitalPresenceGroupProps = {
  id: string
}

interface DigitalPresenceFormData {
  name: string
  description: string
  fontSize: string
  image: string
  color: string
  x: string
  y: string
}

interface ImagePreviewProps {
  preview: string
  width: number
  height: number
}

const CreateDigitalPresence: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const [imageContainerWidth, setImageContainerWidth] =
    useState<number | undefined>()
  const [imagePreview, setImagePreview] =
    useState<ImagePreviewProps | null>(null)
  const [fontSize, setFontSize] = useState<string | null>('40')
  const [fontColor, setFontColor] = useState<string | null>('#FB734E')
  const [x, setX] = useState('0')
  const [y, setY] = useState('0')
  const history = useHistory()
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const { state } = useLocation<DigitalPresenceGroupProps>()
  const { addToast } = useToast()

  useEffect(() => {
    window.addEventListener('resize', () =>
      setImageContainerWidth(imageContainerRef.current?.offsetWidth),
    )
  }, [])

  const handleSubmit = useCallback(
    async (data: DigitalPresenceFormData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string(),
          fontSize: Yup.string().required('Tamanho da fonte obrigatório'),
          color: Yup.string().required('Cor obrigatória'),
          image: Yup.string().required('Imagem obrigatória'),
          x: Yup.string().required('Posição X obrigatória'),
          y: Yup.string().required('Posição Y obrigatória'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newData = new FormData()

        newData.append('name', data.name)
        data.description && newData.append('description', data.description)
        newData.append('fontSize', data.fontSize)
        newData.append('color', data.color)
        newData.append('x', data.x)
        newData.append('y', data.y)
        newData.append('image', data.image)

        await api.post(`/digital-presence/${state.id}`, newData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        addToast({
          type: 'success',
          title: 'Cadastrada com sucesso!',
          description: 'Presença Digital cadastrada com sucesso.',
        })

        history.push(privateRoutePaths.digitalPresenceGroup, state)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      }
    },
    [addToast, history, state],
  )

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (!file) {
      setImagePreview(null)
      return
    }

    const image = new Image()
    const previewURL = URL.createObjectURL(file)
    image.src = previewURL

    const loadedImage = await new Promise<HTMLImageElement>(
      (resolve, reject) => {
        image.onload = () => resolve(image)
        image.onerror = () =>
          reject(new Error('Não foi possível carregar a imagem'))
      },
    )

    setImagePreview({
      preview: previewURL,
      width: loadedImage.width,
      height: loadedImage.height,
    })
    setImageContainerWidth(imageContainerRef.current?.offsetWidth)
  }

  return (
    <Container>
      <HeadTitle title="Criar Arte de Presença Digital" goBack />

      <DigitalPresenceContainer>
        <DigitalPresenceFormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              x,
              y,
              fontSize,
              color: fontColor,
            }}
          >
            <Input name="name" label="Título" />
            <Input name="description" label="Descrição" />

            <InputUpload
              name="image"
              label="Imagem"
              accept="image/*"
              visible={true}
              onInputChange={handleImageChange}
            />

            <Input
              type="range"
              name="x"
              icon="exchange"
              label="Posição Horizontal"
              min="0"
              max={imagePreview ? imagePreview.width : '0'}
              onChange={e => setX(e.target.value)}
            />

            <Input
              type="range"
              name="y"
              icon="sort-alt"
              label="Posição Vertical"
              min="0"
              max={imagePreview ? imagePreview.height : '0'}
              onChange={e => setY(e.target.value)}
            />

            <DigitalPresenceFormFontContainer>
              <Input
                type="range"
                name="fontSize"
                min="0"
                icon="text-size"
                label="Fonte"
                max="100"
                onChange={e => setFontSize(e.target.value)}
              />

              <Input
                type="color"
                name="color"
                label="Cor"
                onChange={e => setFontColor(e.target.value)}
              />
            </DigitalPresenceFormFontContainer>
            <Button type="submit">Criar Presença Digital</Button>
          </Form>
        </DigitalPresenceFormContainer>
        {imagePreview ? (
          <ImagePreviewContainer ref={imageContainerRef}>
            <ImagePreview src={imagePreview.preview} />
            <TextPreview
              x={x}
              y={y}
              fontSize={fontSize}
              fontColor={fontColor}
              imageContainerWidth={imageContainerWidth}
              imageWidth={imagePreview.width}
            >
              Corretor Parceiro Texas
              <br />
              (DD) # ####-####
            </TextPreview>
          </ImagePreviewContainer>
        ) : (
          <strong>Selecione uma imagem para ver o preview dela aqui!</strong>
        )}
      </DigitalPresenceContainer>
    </Container>
  )
}

export default CreateDigitalPresence
