import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Button from '../../components/Button'
import { HeadTitle } from '../../components/HeadTitle'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import { privateRoutePaths } from '../../routes/private'
import { Icon } from '../../components/Icon'

import {
  Container,
  LabelContainer,
  PlanTypeContainer,
  PlanTypeContent,
} from './styles'
import { AxiosError } from 'axios'
import CreateInsurance from './Create'
import { PlanType } from '../../@types/PlanType'

type HandleDeleteTableAndMaterialProp = {
  plan_type_name: string
  plan_type_id: string
}

const Insurances: React.FC = () => {
  const [planTypes, setPlanTypes] = useState<PlanType[]>([])
  const { addToast } = useToast()

  useEffect(() => {
    const getPlanTypes = async (): Promise<void> => {
      const response = await api.get('/plan-types')

      setPlanTypes(response.data)
    }

    getPlanTypes()
  }, [])

  const handleDeleteTableAndMaterial = useCallback(
    async ({
      plan_type_id,
      plan_type_name,
    }: HandleDeleteTableAndMaterialProp) => {
      if (
        window.confirm(
          `Você tem certeza que deseja excluir o Seguro ${plan_type_name}?`,
        )
      ) {
        try {
          await api.delete(`/plan-types/${plan_type_id}`)

          addToast({
            type: 'success',
            title: 'Êxito',
            description: `Seguro ${plan_type_name} excluído com sucesso.`,
          })

          setPlanTypes(
            planTypes.filter(planType => planType.id !== plan_type_id),
          )
        } catch (err) {
          const error = err as AxiosError<{ message: string }>

          addToast({
            type: 'error',
            title:
              error.response?.data.message ??
              'Algo deu errado, tente novamente mais tarde.',
          })
        }
      }
    },
    [addToast, planTypes],
  )

  const handleActiveInsurance = (insurance: PlanType) => {
    setPlanTypes(current => [...current, insurance])
  }

  return (
    <Container>
      <HeadTitle title="Seguros" />

      <CreateInsurance
        planTypes={planTypes}
        onActiveInsurance={handleActiveInsurance}
      />

      <PlanTypeContent>
        {planTypes.map(planType => (
          <PlanTypeContainer key={planType.id}>
            <Icon
              name="trash"
              title={`Deletar ${planType.name}`}
              color="#ff0000"
              onClick={() =>
                handleDeleteTableAndMaterial({
                  plan_type_name: planType.name,
                  plan_type_id: planType.id,
                })
              }
            />
            <LabelContainer>
              <img src={planType.icon.path} alt={planType.icon.name} />
              <h3>{planType.name}</h3>
            </LabelContainer>

            <span>{planType.description}</span>
            <Link
              to={{
                pathname: privateRoutePaths.insurancePlans.replace(
                  ':insuranceId',
                  `${planType.id}`,
                ),
              }}
            >
              <Button>Acessar</Button>
            </Link>
          </PlanTypeContainer>
        ))}
      </PlanTypeContent>
    </Container>
  )
}

export default Insurances
