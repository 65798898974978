import styled from 'styled-components'

export const Container = styled.div`
  background: var(--element);
  border-radius: 4px;

  @media (max-width: 800px) {
    display: none;
  }
`

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
`

export const FilterContainer = styled.div`
  border: solid 3px var(--background);
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  font-size: 13px;

  input[type='radio'] {
    position: absolute;
    visibility: hidden;
    display: none;
  }

  label {
    color: lighten(var(--background), 40%);
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 20px;
  }

  input[type='radio']:checked + label {
    color: lighten(var(--background), 60%);
    background: var(--background);
  }

  label + input[type='radio'] + label {
    border-left: solid 3px var(--background);
  }
`

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 2.5rem 1.5rem 0;
`
