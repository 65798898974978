import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
`

export const DigitalPresenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

export const DigitalPresenceImageContainer = styled.div`
  width: 100%;
  padding: 10px;
  background: var(--element);
  border-radius: 5px;

  a {
    text-decoration: none;
    color: var(--text);
  }
`

export const DigitalPresenceEmpty = styled.div`
  display: grid;
  align-content: space-between;
  text-align: center;
  height: calc(282px - 20px);
`

export const DigitalPresenceHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DigitalPresenceTitle = styled.div``

export const DigitalPresenceActions = styled.div`
  display: flex;
  gap: 0.5rem;

  a {
    text-decoration: none;
  }

  i {
    cursor: pointer;
    position: relative;

    &:hover {
      opacity: 0.5;
    }
  }
`

export const DigitalPresenceImage = styled.img`
  width: 117px;
  height: 117px;
  object-fit: cover;
  margin: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`
