import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
  isVisible: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  min-height: 50px;
  background: var(--element);
  border-radius: 8px;
  border-bottom: 2px solid var(--element);
  padding: 7px 15px;
  gap: 10px;

  input {
    display: none;
  }

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`

export const IconContainer = styled.div`
  display: flex;
  width: 16px;
  height: 36px;
  align-items: flex-end;
`

export const InputInfoContainer = styled.div`
  display: grid;
  align-content: space-between;

  p:first-child {
    font-size: 0.7rem;
    color: #696969;
    margin-top: -3px;
  }

  p:last-child {
    font-size: 0.8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const InputUploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const InputButton = styled.label`
  font-size: 0.8rem;
  padding: 6.5px 20px;
  border: 1px solid var(--text);
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.4s ease;

  &:hover {
    border-color: var(--border);
  }
`

export const Error = styled(Tooltip)`
  height: 16px;
  i {
    color: var(--red-500);
  }

  span {
    background: var(--red-500);
    color: var(--white);
    z-index: 9999;

    &::before {
      border-color: var(--red-500) transparent;
    }
  }
`
