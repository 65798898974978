import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  justify-items: center;
  margin-top: 3rem;

  div {
    text-align: center;

    strong {
      font-size: 2rem;
    }

    button {
      margin-top: 1rem;
      width: fit-content;
    }
  }
`
