import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  row-gap: 3rem;

  form {
    display: grid;
    row-gap: 1rem;
  }
`

export const FirstFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
`

export const SecondFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ThirdFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
`

export const ExportProductDataContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    text-decoration: none;
    color: white;
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    background: var(--app-gradient);
    width: fit-content;
    border-radius: 15px;
    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const ExportRefNetDataContainer = styled(ExportProductDataContainer)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const LastFormContainer = styled(ThirdFormContainer)`
  margin-top: 50px;
  display: unset;

  > div {
    display: flex;
    gap: 1rem;

    button {
      max-width: 140px;
    }

    @media (max-width: 1000px) {
      flex-direction: column;

      button {
        max-width: unset;
      }
    }
  }
`

export const RefnetContainer = styled.div`
  margin: 30px 0;

  p {
    display: flex;
    white-space: nowrap;

    hr {
      width: -webkit-fill-available;
      margin: 10px;
    }

    i {
      margin-left: 15px;
      cursor: pointer;
    }
  }
`
