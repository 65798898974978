import React from 'react'
import { useLocation } from 'react-router-dom'

import notificationImage from '../../assets/notificationImage.png'

import { Container, InfoContainer } from './styles'
import { HeadTitle } from '../../components/HeadTitle'

interface Notification {
  id: string
  company_id: string
  title: string
  image: {
    id: string
    name: string
    path: string
  }
  youtube_url: string
  youtube_id: string | null
  description: string
  updated_at: string
  created_at: string
}

const Notification: React.FC = () => {
  const { state } = useLocation<Notification>()

  const notification = { ...state }

  return (
    <Container>
      <HeadTitle title={`${notification.title}`} goBack />
      <InfoContainer>
        <img src={notificationImage} alt="informativo" />
        <div
          dangerouslySetInnerHTML={{ __html: notification.description }}
        ></div>
      </InfoContainer>
    </Container>
  )
}

export default Notification
