import React, { useEffect, useState } from 'react'
import { AssociationsContainer, Container } from './styles'
import { HeadTitle } from '../../../components/HeadTitle'
import AssociationCard from './components/AssociationCard'
import api from '../../../services/api'
import Input from '../../../components/Input'
import { Form } from '@unform/web'
import { privateRoutePaths } from '../../../routes/private'
import { EmptyState } from '../../../components/EmptyState'
import emptyAssociations from '../../../assets/empty-associations.json'

interface ProductEntity {
  id: string
  active: boolean
  name: string
  updated_at: Date
  created_at: Date
}

interface Association extends ProductEntity {
  professions: ProductEntity[]
}

const Associations = () => {
  const [associations, setAssociations] = useState<Association[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [associationsThatMatchSearch, setAssociationsThatMatchSearch] =
    useState<Association[]>([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    const getAssociations = async () => {
      const associations = await api.get('/associations')
      setAssociations(associations.data)
      setIsLoading(false)
    }
    getAssociations()
  }, [])

  const handleAssociationSearch = () => {
    const currentAssociations = associations

    const associationsThatMatchSearch = currentAssociations.filter(ca =>
      ca.name.toLowerCase().includes(search),
    )
    const associationsProfessionsThatMatchSearch = currentAssociations.filter(
      ca =>
        ca.professions.some(prof => prof.name.toLowerCase().includes(search)),
    )

    const filteredRepeatedAssociations = associationsThatMatchSearch.filter(
      atms =>
        !associationsProfessionsThatMatchSearch.some(
          aptms => atms.name === aptms.name,
        ),
    )

    setAssociationsThatMatchSearch([
      ...filteredRepeatedAssociations,
      ...associationsProfessionsThatMatchSearch,
    ])
  }

  useEffect(handleAssociationSearch, [search])

  if (isLoading) return <h1>Carregando informações...</h1>

  if (associations.length === 0)
    return (
      <EmptyState
        image={emptyAssociations}
        title="Cadastre uma nova associação!"
        style={{
          maxHeight: '500px',
        }}
        desc="Associações são entidades vinculadas ao produto de cotação da pessoa física."
        goToButton={{
          to: privateRoutePaths.createQuoteAssociation,
          title: 'Criar nova associação',
        }}
      />
    )

  return (
    <Container>
      <HeadTitle
        title="Associações"
        description="Você pode encontrar todas as associações cadastradas aqui."
        navigate={{
          to: privateRoutePaths.createQuoteAssociation,
          name: 'Criar nova Associação',
        }}
        goBack
      />

      <Form
        onSubmit={() => {
          return
        }}
      >
        <Input
          name="search"
          label="Pesquise uma associação pelo nome ou pela sua profissão"
          onChange={e => setSearch(e.target.value.toLowerCase())}
          icon="search"
        />
      </Form>

      <AssociationsContainer>
        {search === ''
          ? associations.map(as => (
              <AssociationCard
                key={as.id}
                associationId={as.id}
                name={as.name}
                professions={as.professions.map(p => p.name)}
              />
            ))
          : associationsThatMatchSearch.map(atms => (
              <AssociationCard
                key={atms.id}
                associationId={atms.id}
                name={atms.name}
                professions={atms.professions.map(p => p.name)}
              />
            ))}
      </AssociationsContainer>
    </Container>
  )
}

export default Associations
