import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from '../../hooks/auth'
import api from '../../services/api'
import { HeadTitle } from '../../components/HeadTitle'
import { EmptyState } from '../../components/EmptyState'
import emptyImage from '../../assets/empty-notification.json'
import { privateRoutePaths } from '../../routes/private'
import { Icon } from '../../components/Icon'

import {
  Container,
  NoficationsContainer,
  Notification,
  NotificationActions,
  NotificationContent,
} from './styles'

interface NotificationProps {
  id: string
  company_id: string
  title: string
  image: {
    id: string
    name: string
    path: string
    updated_at: string
    created_at: string
  }
  description: string
  priority: boolean
  updated_at: string
  created_at: string
}

const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const { company } = useAuth()

  useEffect(() => {
    async function loadNotifications(): Promise<void> {
      const response = await api.get(`/notifications/${company.id}`)

      setNotifications(response.data)
      setIsLoading(false)
    }

    loadNotifications()
  }, [company.id])

  if (isLoading) return <p>Carregando...</p>

  if (notifications.length === 0 && !isLoading)
    return (
      <EmptyState
        image={emptyImage}
        style={{
          maxHeight: '400px',
          marginBottom: '-32px',
        }}
        title="Publique o seu primeiro informativo."
        desc="Deixe os corretores que trabalham com a gente sempre informados das últimas novidades!"
        goToButton={{
          title: 'Cria novo informativo',
          to: privateRoutePaths.createNotification,
        }}
      />
    )

  return (
    <Container>
      <HeadTitle
        title="Informativos"
        navigate={{
          name: 'Criar novo informativo',
          to: privateRoutePaths.createNotification,
        }}
      />

      <NoficationsContainer>
        {notifications.map(notification => (
          <Notification key={notification.id}>
            <NotificationContent priority={notification.priority}>
              <p>{notification.title}</p>
            </NotificationContent>
            <NotificationActions>
              <Icon
                name="trash"
                fontSize={16}
                color="#ff0000"
                onClick={async () => {
                  if (
                    window.confirm(
                      `Deseja realmente excluir o informativo: ${notification.title}?`,
                    )
                  ) {
                    await api.delete(`/notifications/${notification.id}`)
                    const response = await api.get(
                      `/notifications/${company.id}`,
                    )

                    setNotifications(response.data)
                  }
                }}
              />
              <Link
                to={{
                  pathname: privateRoutePaths.notification,
                  state: notification,
                }}
              >
                <Icon name="eye" />
              </Link>
            </NotificationActions>
          </Notification>
        ))}
      </NoficationsContainer>
    </Container>
  )
}

export default Notifications
