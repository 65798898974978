import React from 'react'
import {
  AssociationNameContainer,
  AssociationProfessionContainer,
  AssociationProfessionsContainer,
  Container,
  Divider,
  EditAssociationButton,
} from './styles'
import { Icon } from '../../../../../components/Icon'
import Button from '../../../../../components/Button'
import { privateRoutePaths } from '../../../../../routes/private'

interface AssociationCardProps {
  associationId: string
  name: string
  professions: string[]
}

const AssociationCard: React.FC<AssociationCardProps> = ({
  associationId,
  name,
  professions,
}) => {
  return (
    <Container>
      <EditAssociationButton
        to={privateRoutePaths.editQuoteAssociation.replace(
          ':associationId',
          associationId,
        )}
        title="Adicione profissões"
      >
        <Button>
          <Icon name="plus" color="#fff" fontSize={12} />
        </Button>
      </EditAssociationButton>
      <AssociationNameContainer>
        <Icon name="id-card-clip-alt" fontSize={20} />
        <span>{name}</span>
      </AssociationNameContainer>
      <Divider />
      <AssociationProfessionsContainer>
        {professions.map(pf => (
          <AssociationProfessionContainer key={pf}>
            {pf}
          </AssociationProfessionContainer>
        ))}
      </AssociationProfessionsContainer>
    </Container>
  )
}
export default AssociationCard
