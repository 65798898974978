import React, { HTMLAttributes } from 'react'
import { IconContent } from './styles'

interface IconProps extends HTMLAttributes<HTMLElement> {
  name: string
  type?: 'rr' | 'brands'
  fontSize?: number
  theme?: 'default' | 'colored'
  color?: string
}

export const Icon: React.FC<IconProps> = ({
  name,
  fontSize = 16,
  type = 'rr',
  theme = 'default',
  color,
  ...rest
}) => {
  const selectedTheme = theme === 'colored'

  return (
    <IconContent
      className={`fi fi-${type}-${name}`}
      fontSize={fontSize}
      colorTheme={selectedTheme}
      color={color}
      {...rest}
    />
  )
}
