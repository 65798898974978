import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`

export const Content = styled.div`
  display: grid;
  row-gap: 3rem;
`

export const PlanInfoContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: center;
  }
`

export const PlanInfoFormContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  width: 20rem;

  @media (max-width: 460px) {
    width: 100%;
  }
`

export const PlanImageContentContainer = styled.div`
  text-align: center;

  p {
    margin-bottom: 10px;
  }
`

export const PlanImageContainer = styled.div`
  width: fit-content;
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
    border-radius: 50%;
    padding: 11px;
    background: var(--red-500);
  }

  img {
    width: 170px;
    height: 170px;
    background: var(--white);
    border: 3px solid var(--purple);
    object-fit: scale-down;
    border-radius: 85px;
  }
`

export const LabelButton = styled.label`
  background: var(--app-gradient);
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 11px;
  border-radius: 50%;
  border: 0;
  color: #fff;
  font-weight: 500;
  transition: filter 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`

export const PlanForm = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  align-items: end;

  @media (max-width: 405px) {
    grid-template-columns: unset;
  }

  button {
    margin: 0;
  }
`
