import React, { useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import Button from '../../components/Button'
import Input from '../../components/Input'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import { Container } from './styles'
import { HeadTitle } from '../../components/HeadTitle'
import { AxiosError } from 'axios'
import { privateRoutePaths } from '../../routes/private'

interface DigitalPresenceGroupFormData {
  title: string
  description: string
}

const CreateDigitalPresenceGroups: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  const { addToast } = useToast()

  const handleSubmit = useCallback(
    async (data: DigitalPresenceGroupFormData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string().required('Título obrigatório'),
          description: Yup.string(),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await api.post('/digital-presence-group', data)

        addToast({
          type: 'success',
          title: 'Cadastrado com sucesso!',
          description: 'Grupo de presença digital cadastrado com sucesso.',
        })

        history.push(privateRoutePaths.digitalPresenceGroups)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      }
    },
    [addToast, history],
  )

  return (
    <Container>
      <HeadTitle title="Criar grupo de Presença Digital" goBack />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input name="name" label="Nome do Grupo de Presença Digital" />
        <Input name="description" label="Descrição" />
        <Button type="submit">Criar Grupo de Presença Digital</Button>
      </Form>
    </Container>
  )
}

export default CreateDigitalPresenceGroups
