import React, { ReactNode } from 'react'

import { Icon } from '../../../../components/Icon'
import { Button } from '../../../Insurances/Create/styles'
import { ModalType } from '../..'
import Modal from '../../../../components/Modal'
import { HeadTitle } from '../../../../components/HeadTitle'

import { Container, InputContainer, ModalContainer } from './styles'

interface StepModalProps {
  name: ModalType
  title: string
  currentModal: ModalType | null
  children: ReactNode
  isFilled?: boolean
  isInvalid?: boolean
  onModalOpen: (name: ModalType) => void
  onModalClose: () => void
}

const StepModal: React.FC<StepModalProps> = ({
  name,
  title,
  currentModal,
  isFilled,
  isInvalid,
  children,
  onModalOpen,
  onModalClose,
}) => {
  return (
    <>
      <Container isFilled={isFilled} isInvalid={isInvalid}>
        <div>
          <Icon
            name={
              isInvalid
                ? 'exclamation'
                : isFilled
                ? 'check'
                : 'triangle-warning'
            }
          />
          <span>{title}</span>
        </div>
        <div>
          <Button onClick={() => onModalOpen(name)}>
            {isFilled ? 'Editar' : 'Inserir'}
          </Button>
        </div>
      </Container>
      <Modal modalIsOpen={currentModal === name} onModalClose={onModalClose}>
        <ModalContainer>
          <HeadTitle title={title} />
          <InputContainer>{children}</InputContainer>
          <Button onClick={onModalClose}>Confirmar</Button>
        </ModalContainer>
      </Modal>
    </>
  )
}
export default StepModal
