import React from 'react'
import {
  Container,
  NavigateCard,
  NavigateCardIconContainer,
  NavigateCardTitleContainer,
  QuoteEntityContainer,
} from './styles'
import { HeadTitle } from '../../components/HeadTitle'
import { Link } from 'react-router-dom'
import { privateRoutePaths } from '../../routes/private'
import { Icon } from '../../components/Icon'

const Quote: React.FC = () => {
  return (
    <Container>
      <HeadTitle
        title="Cotação"
        description="Cadastre e edite entidades necessárias para os produtos da cotação!"
      />

      <QuoteEntityContainer>
        <Link to={privateRoutePaths.quoteAssociations}>
          <NavigateCard>
            <NavigateCardIconContainer>
              <Icon name="id-card-clip-alt" fontSize={24} />
            </NavigateCardIconContainer>

            <NavigateCardTitleContainer>
              <span>Associações</span>
              <Icon name="angle-right" />
            </NavigateCardTitleContainer>
          </NavigateCard>
        </Link>
      </QuoteEntityContainer>
    </Container>
  )
}

export default Quote
