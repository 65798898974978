import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const MaterialsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`

export const Material = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--element);
  border-radius: 8px;

  h2 {
    text-align: center;
    margin: 1.5rem 0;
    font-weight: 400;
  }

  button {
    margin: 0;
  }

  form div textarea {
    min-height: 96px;
    max-height: 96px;
  }

  form {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    div {
      background: var(--background);
      border-radius: 8px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 10px 10px 10px;
  }
`
export const MaterialItemContainer = styled.div`
  display: flex;
  background: var(--background);
  justify-content: space-between;
  min-height: 55px;
  align-items: center;
  text-align: start;
  word-wrap: break-word;
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;

  & > a {
    color: var(--text);
    width: fit-content;
    cursor: pointer;
    text-decoration: none;
    font-size: 12px;
  }
`

export const MaterialItemActions = styled.div`
  display: flex;
  gap: 0.38rem;

  a {
    text-decoration: none;
  }

  div {
    background: var(--element);
    border-radius: 50px;
    padding: 0.31rem;
  }

  i {
    cursor: pointer;
    max-height: 14px;
    max-width: 14px;
  }

  i:hover {
    opacity: 0.5;
    transition: 0.2s;
  }
`

export const HrLine = styled.div`
  margin: 22px 0;
  height: 4px;
  padding: 0 !important;
  background: var(--background);
`
