import React from 'react'
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom'

import SidebarAndNavbar from '../components/SidebarAndNavbar'

import { useAuth } from '../hooks/auth'
import { publicRoutesPath } from './public'
import { privateRoutePaths } from './private'

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean
  hasSideAndNavbar?: boolean
  component: React.ComponentType
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  hasSideAndNavbar = false,
  component: Component,
  ...rest
}) => {
  const { company } = useAuth()

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!company ? (
          hasSideAndNavbar ? (
            <SidebarAndNavbar>
              <Component />
            </SidebarAndNavbar>
          ) : (
            <Component />
          )
        ) : (
          <Redirect
            to={{
              pathname: isPrivate
                ? publicRoutesPath.login
                : privateRoutePaths.home,
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

export default Route
