import React, { useEffect, useState } from 'react'

import { useAuth } from '../../../hooks/auth'
import api from '../../../services/api'
import { Skeleton } from '../../Skeleton'

import { DataList, Container, ProgressContainer } from './styles'

interface SummaryProps {
  name: string
  total: number
}

export const PlansRanking: React.FC = () => {
  const [summaryData, setSummaryData] = useState<SummaryProps[]>([])
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const { company } = useAuth()

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const response = await api.get<SummaryProps[]>(
        `/summary/companies/${company.id}/plans`,
      )

      const summaryData = response.data

      const total = summaryData.reduce(
        (acc, summary) => (acc += summary.total),
        0,
      )

      setSummaryData(summaryData)
      setTotal(total)
      setIsLoading(false)
    }
    getData()
  }, [company.id])

  if (isLoading)
    return (
      <Container>
        <Skeleton width="100%" height="295">
          <rect rx="10" ry="10" width="60%" height="15" />
          <rect y="60" rx="5" ry="5" width="100%" height="7" />
          <rect y="95" rx="5" ry="5" width="100%" height="7" />
          <rect y="130" rx="5" ry="5" width="100%" height="7" />
          <rect y="165" rx="5" ry="5" width="100%" height="7" />
          <rect y="200" rx="5" ry="5" width="100%" height="7" />
          <rect y="235" rx="5" ry="5" width="100%" height="7" />
          <rect y="270" rx="5" ry="5" width="100%" height="7" />
        </Skeleton>
      </Container>
    )

  return (
    <Container>
      <div>Ranking de produtos no mês atual</div>
      <DataList>
        {summaryData.length > 0 ? (
          summaryData.map(data => (
            <li key={data.name}>
              <p>{data.name}</p>
              <ProgressContainer width={data.total} totalAmount={total}>
                <div></div>
              </ProgressContainer>
              <span>{data.total} Acessos</span>
            </li>
          ))
        ) : (
          <p>Nenhum plano encontrado.</p>
        )}
      </DataList>
    </Container>
  )
}
