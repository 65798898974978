import { RouterType } from '../../../@types/router.types'
import Quote from '../../../pages/Quotes'
import Associations from '../../../pages/Quotes/Associations'
import CreateAssociation from '../../../pages/Quotes/Associations/Create'
import EditAssociation from '../../../pages/Quotes/Associations/Edit'

export const quoteRoutesPath = {
  quote: '/cotacao',
  quoteAssociations: '/cotacao/associacoes',
  createQuoteAssociation: '/cotacao/associacoes/criar',
  editQuoteAssociation: '/cotacao/associacoes/:associationId/editar/',
}

export const quoteRoutes: RouterType[] = [
  {
    path: quoteRoutesPath.quote,
    component: Quote,
    title: 'quote',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: quoteRoutesPath.quoteAssociations,
    component: Associations,
    title: 'associations',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: quoteRoutesPath.createQuoteAssociation,
    component: CreateAssociation,
    title: 'create-association',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: quoteRoutesPath.editQuoteAssociation,
    component: EditAssociation,
    title: 'edit-association',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
]
