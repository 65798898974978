import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { AxiosError } from 'axios'
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Papa from 'papaparse'

import { Plan } from '../../../@types/Plan'
import { PlanTypeCustomer } from '../../../@types/PlanTypeCustomer'
import Button from '../../../components/Button'
import { HeadTitle } from '../../../components/HeadTitle'
import Input from '../../../components/Input'
import Select from '../../../components/Select'
import { useToast } from '../../../hooks/toast'
import { privateRoutePaths } from '../../../routes/private'
import api from '../../../services/api'
import getValidationErrors from '../../../utils/getValidationErrors'
import {
  demask,
  demaskCurrency,
  normalizerANSCode,
  normalizerCurrencyNumber,
} from '../../../utils/normalizer'

import {
  Container,
  FirstFormContainer,
  HasIOFContainer,
  HasIOFLabel,
  HeaderContainer,
  ImportProductDataContainer,
  ImportRefNetDataContainer,
  LastFormContainer,
  RefnetContainer,
  SecondFormContainer,
  ThirdFormContainer,
} from './styles'
import { Icon } from '../../../components/Icon'

interface PlanProps extends Plan {
  plan_type_customer: PlanTypeCustomer
}

interface ProductEntity {
  id: string
  active?: boolean
  name: string
  updated_at?: Date
  created_at?: Date
}

interface FormData {
  name: string
  min_lifes: number
  max_lifes: number
  ans: string
  locale: string
  accommodation: string
  coverage: string
  refunded: boolean
  coparticipation: boolean
  associations?: string[]
  cnpj_types?: string[]
  referenced_networks: string[]
  contract_type?: boolean
  age_group_0_to_18_price: number
  age_group_19_to_23_price: number
  age_group_24_to_28_price: number
  age_group_29_to_33_price: number
  age_group_34_to_38_price: number
  age_group_39_to_43_price: number
  age_group_44_to_48_price: number
  age_group_49_to_53_price: number
  age_group_54_to_58_price: number
  age_group_59_upper_price: number
}

const checkPlanOriginAndShowCorrectSelect = (
  plan: PlanProps,
  CNPJTypes: ProductEntity[],
  associations: ProductEntity[],
) => {
  const requiredInputByPlanTypeCustomer = {
    'Pessoa Jurídica': (
      <SecondFormContainer>
        <Select
          name="contract_type"
          label="Tipo de contratação"
          icon="diploma"
          options={[
            { value: '0', label: 'Compulsório' },
            { value: '1', label: 'Livre adesão' },
          ]}
        />
        <Select
          name="cnpj_types"
          label="Tipos de CNPJ aceitos"
          icon="briefcase"
          options={CNPJTypes.map(ct => ({ value: ct.id, label: ct.name }))}
          isMulti
        />
      </SecondFormContainer>
    ),
    'Produtos Adesão': (
      <SecondFormContainer>
        <Select
          name="associations"
          label="Associações aceitas"
          icon="users-medical"
          options={associations.map(as => ({ value: as.id, label: as.name }))}
          isMulti
        />
      </SecondFormContainer>
    ),
    'Pessoa Física': '',
  }

  return requiredInputByPlanTypeCustomer[
    plan.plan_type_customer.name as keyof typeof requiredInputByPlanTypeCustomer
  ]
}

const CreateProduct = () => {
  const [plan, setPlan] = useState<PlanProps>()
  const [locales, setLocales] = useState<ProductEntity[]>([])
  const [accommodations, setAccommodations] = useState<ProductEntity[]>([])
  const [coverages, setCoverages] = useState<ProductEntity[]>([])
  const [associations, setAssociations] = useState<ProductEntity[]>([])
  const [CNPJTypes, setCNPJTypes] = useState<ProductEntity[]>([])
  const [referencedNetworks, setReferencedNetworks] = useState<ProductEntity[]>(
    [],
  )
  const [referencedNetworkSpecialties, setReferencedNetworkSpecialties] =
    useState<ProductEntity[]>([])
  const [
    referencedNetworksWithSpecialties,
    setReferencedNetworksWithSpecialties,
  ] = useState<
    Array<{
      id: string
      name: string
      specialties: Array<{ id: string; name: string }>
    }>
  >([])

  const [hasIOF, setHasIOF] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [importedProduct, setImportedProduct] = useState<any>({} as any)
  const history = useHistory()
  const { planId } = useParams<{ planId: string }>()
  const { addToast } = useToast()
  const formRef = useRef<FormHandles>(null)

  useEffect(() => {
    const getProductEntities = async () => {
      const plan = await api.get<PlanProps>(`/plans/${planId}`)
      const locales = await api.get<ProductEntity[]>('/locales')
      const accommodations = await api.get<ProductEntity[]>('/accommodations')
      const coverages = await api.get<ProductEntity[]>('/coverages')
      const associations = await api.get<ProductEntity[]>('/associations')
      const cnpjTypes = await api.get<ProductEntity[]>('/cnpj-types')
      const referencedNetworks = await api.get<ProductEntity[]>(
        '/referenced-networks',
      )
      const specialties = await api.get<ProductEntity[]>(
        '/referenced-networks/specialties',
      )

      setPlan(plan.data)
      setLocales(locales.data)
      setAccommodations(accommodations.data)
      setCoverages(coverages.data)
      setAssociations(associations.data)
      setCNPJTypes(cnpjTypes.data)
      setReferencedNetworks(referencedNetworks.data)
      setReferencedNetworkSpecialties(specialties.data)
      setIsLoading(false)
    }

    getProductEntities()
  }, [planId])

  const handleSubmit = async (data: FormData) => {
    setIsLoading(true)
    try {
      formRef.current?.setErrors({})

      let priceSchema: { [key: string]: Yup.StringSchema } = {}
      priceInputs.forEach(pi => {
        priceSchema[pi.name] = Yup.string()
          .optional()
          .transform(v => demaskCurrency(v))
          .test(
            'has-more-than-eight-digits-before-dot',
            'O valor por faixa etária não pode ser maior que 12 digitos.',
            v => {
              if (!v) return true
              const digitsBeforeComma = v.split('.')[0]
              const digitsBeforeCommaLimit = 8
              return digitsBeforeComma.length > digitsBeforeCommaLimit
                ? false
                : true
            },
          )
          .test('is-float', 'O preço não está correto', v => {
            if (!v) return true
            const currency = parseFloat(v)
            return Number.isFinite(currency) && currency.toFixed(2) === v
          })
      })

      let planOriginSchema: {
        [key: string]: Yup.NotRequiredArraySchema<string | undefined>
      } = {}
      const planOriginCheck =
        plan?.plan_type_customer.name === 'Pessoa Jurídica'
      const planOriginKey = planOriginCheck ? 'cnpj_types' : 'associations'

      if (plan?.plan_type_customer.name === 'Pessoa Jurídica') {
        planOriginSchema[planOriginKey] = Yup.array(
          Yup.string().uuid().required(),
        ).required(`Tipos de CNPJ é obrigatório`)
      } else if (plan?.plan_type_customer.name === 'Produtos Adesão') {
        planOriginSchema[planOriginKey] = Yup.array(
          Yup.string().uuid().required(),
        ).required(`Associações são obrigatórias`)
      } else {
        planOriginSchema[planOriginKey] = Yup.array(
          Yup.string().uuid().optional(),
        ).optional()
      }

      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Nome é obrigatório')
          .min(3, 'No mínimo 3 digitos.'),
        min_lifes: Yup.number()
          .required('Número mínimo de vidas é obrigatório')
          .min(1, 'Deve ter no mínimo 1 vida.')
          .typeError('Um número é obrigatório'),
        max_lifes: Yup.number()
          .required('Número máximo de vidas é obrigatório')
          .min(1, 'Deve ter no mínimo 1 vida.')
          .typeError('Um número é obrigatório'),
        ans: Yup.string()
          .optional()
          .transform(v => demask(v)),
        locale: Yup.string()
          .required('Campo de Abrangência é obrigatório')
          .uuid('Campo de Abrangência é obrigatório'),
        accommodation: Yup.string()
          .required('Acomodação é obrigatório')
          .uuid('Acomodação é obrigatório'),
        coverage: Yup.string()
          .required('Campo de Cobertura é obrigatório')
          .uuid('Campo de Cobertura é obrigatório'),
        refunded: Yup.boolean()
          .required('Campo de reembolso é obrigatório')
          .typeError('Uma opção é obrigatória'),
        coparticipation: Yup.boolean()
          .required('Campo de coparticipação é obrigatório')
          .typeError('Uma opção é obrigatória'),
        contract_type: Yup.boolean().optional(),
        referenced_networks: Yup.array(
          Yup.object({
            id: Yup.string(),
            specialties: Yup.array(Yup.string()).optional(),
          }).required('Rede referenciada é obrigatória'),
        ),
        ...planOriginSchema,
        ...priceSchema,
      })

      const referencedNetworksIdWithTheirSpecialtiesId =
        referencedNetworksWithSpecialties.map(rns => ({
          id: rns.id,
          specialties: rns.specialties.map(s => s.id),
        }))

      await schema.validate(
        {
          ...data,
          referenced_networks: referencedNetworksIdWithTheirSpecialtiesId,
        },
        {
          abortEarly: false,
        },
      )

      const formattedData = schema.cast({
        ...data,
        referenced_networks: referencedNetworksIdWithTheirSpecialtiesId,
      })

      await api.post('/plan-products', {
        planId,
        ...formattedData,
        iof: hasIOF,
      })

      addToast({
        type: 'success',
        title: 'Êxito!',
        description: 'Produto cadastrado com sucesso!',
      })

      history.push(
        privateRoutePaths.insurancePlanProducts.replace(':planId', planId),
      )
    } catch (err) {
      console.error(err)
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current?.setErrors(errors)

        return
      }

      const error = err as AxiosError<{ message: string }>
      addToast({
        type: 'error',
        title: 'Oops!',
        description:
          error.response?.data.message ||
          'Ocorreu um erro interno no servidor.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleAddReferencedNetwork = () => {
    const refnetId: string = formRef.current?.getFieldValue(
      'referenced_networks',
    )
    const specialtiesId: string[] =
      formRef.current?.getFieldValue('specialties')

    const isAlreadyAdded = referencedNetworksWithSpecialties.find(
      rns => rns.id === refnetId,
    )
    if (isAlreadyAdded) {
      addToast({
        type: 'info',
        title: 'Oops!',
        description: 'Rede referenciada já adicionada.',
      })
      return
    }

    const refnet = referencedNetworks.find(rn => rn.id === refnetId)
    if (!refnet) {
      addToast({
        type: 'error',
        title: 'Oops!',
        description: 'Rede referenciada não encontrada.',
      })
      return
    }

    const refnetWithSpecialties = {
      id: refnet.id,
      name: refnet.name,
      specialties: specialtiesId.map(s => {
        const specialty = referencedNetworkSpecialties.find(
          spe => spe.id === s,
        )!
        return { id: specialty.id, name: specialty.name }
      }),
    }

    formRef.current?.setFieldValue('referenced_networks', '')
    formRef.current?.setFieldValue('specialties', [])

    setReferencedNetworksWithSpecialties([
      ...referencedNetworksWithSpecialties,
      refnetWithSpecialties,
    ])

    const newReferencedNetworks = referencedNetworks.filter(
      rn => rn.id !== refnetId,
    )
    setReferencedNetworks(newReferencedNetworks)
  }

  const handleDeleteReferencedNetwork = (id: string) => {
    const refnet = referencedNetworksWithSpecialties.find(rn => rn.id === id)!
    const newReferencedNetworks: ProductEntity[] = [
      ...referencedNetworks,
      { id: refnet.id, name: refnet.name },
    ].sort((a, b) => (a.name > b.name ? 1 : -1))
    setReferencedNetworks(newReferencedNetworks)

    const newReferencedNetworksWithSpecialties =
      referencedNetworksWithSpecialties.filter(rn => rn.id !== id)
    setReferencedNetworksWithSpecialties(newReferencedNetworksWithSpecialties)
  }

  const priceInputs = [
    { name: 'age_group_0_to_18_price', label: '0 a 18' },
    { name: 'age_group_19_to_23_price', label: '19 a 23' },
    { name: 'age_group_24_to_28_price', label: '24 a 28' },
    { name: 'age_group_29_to_33_price', label: '29 a 33' },
    { name: 'age_group_34_to_38_price', label: '34 a 38' },
    { name: 'age_group_39_to_43_price', label: '39 a 43' },
    { name: 'age_group_44_to_48_price', label: '44 a 48' },
    { name: 'age_group_49_to_53_price', label: '49 a 53' },
    { name: 'age_group_54_to_58_price', label: '54 a 58' },
    { name: 'age_group_59_upper_price', label: '59 ou mais' },
  ]

  if (isLoading) return <p>Carregando...</p>

  const handleImportData = (
    e: ChangeEvent<HTMLInputElement>,
    type: 'product' | 'refnet',
  ) => {
    const file = e.target.files![0]

    if (!file.name.endsWith('.csv')) {
      addToast({
        type: 'error',
        title: 'Oops!',
        description: 'O arquivo deve ser um CSV.',
      })
      return
    }

    Papa.parse<any>(file, {
      complete: result => {
        if (type === 'product') {
          setImportedProduct(result.data[0])
        } else {
          setReferencedNetworksWithSpecialties(
            result.data.map(rns => ({
              id: rns.id,
              name: referencedNetworks.find(rn => rn.id === rns.id)!.name,
              specialties: rns.specialties
                ? rns.specialties.split(',').map((s: string) => ({
                    id: s,
                    name: referencedNetworkSpecialties.find(
                      rns => rns.id === s,
                    )!.name,
                  }))
                : [],
            })),
          )
        }
      },
      header: true,
    })
  }

  const formData = {
    name: importedProduct.name,
    min_lifes: importedProduct.min_lifes,
    max_lifes: importedProduct.max_lifes,
    ans: importedProduct.ans && normalizerANSCode(importedProduct.ans),
    locale: importedProduct.locale && {
      value: importedProduct.locale,
      label: locales.find(l => l.id === importedProduct.locale)!.name,
    },
    accommodation: importedProduct.accommodation && {
      value: importedProduct.accommodation,
      label: accommodations.find(ac => ac.id === importedProduct.accommodation)!
        .name,
    },
    coverage: importedProduct.coverage && {
      value: importedProduct.coverage,
      label: coverages.find(cov => cov.id === importedProduct.coverage)!.name,
    },
    refunded: importedProduct.refunded !== undefined && {
      value: importedProduct.refunded,
      label: `${
        Boolean(importedProduct.refunded) ? 'Com reembolso' : 'Sem reembolso'
      }`,
    },
    coparticipation: importedProduct.coparticipation !== undefined && {
      value: importedProduct.coparticipation,
      label: `${
        Boolean(importedProduct.coparticipation)
          ? 'Com coparticipação'
          : 'Sem comparticipação'
      }`,
    },
    contract_type: importedProduct.contract_type !== undefined && {
      value: importedProduct.contract_type ? '1' : '0',
      label: `${
        Boolean(importedProduct.contract_type) ? 'Livre adesão' : 'Compulsório'
      }`,
    },
    associations:
      importedProduct.associations &&
      importedProduct.associations.split(',').map((as: string) => ({
        value: as,
        label: associations.find(association => association.id === as)!.name,
      })),
    cnpj_types:
      importedProduct.cnpj_types &&
      importedProduct.cnpj_types.split(',').map((ct: string) => ({
        value: ct,
        label: CNPJTypes.find(cnpj => cnpj.id === ct)!.name,
      })),
  }

  return (
    <Container>
      <HeaderContainer>
        <HeadTitle
          title="Cadastro de um novo produto."
          description="Preencha o formulário com as informações necessárias."
          goBack
        />

        <HasIOFContainer>
          <span>Possui IOF?</span>
          <HasIOFLabel>
            <input
              type="checkbox"
              checked={hasIOF}
              onChange={() => setHasIOF(!hasIOF)}
            />
            <span></span>
          </HasIOFLabel>
        </HasIOFContainer>
      </HeaderContainer>

      <Form
        key={formData.name}
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={formData}
      >
        <ImportProductDataContainer>
          <input
            id="product-csv"
            type="file"
            onChange={e => handleImportData(e, 'product')}
            accept=".csv"
          />

          <label htmlFor="product-csv">
            <Icon name="file-import" />
            <p>Importar produto existente</p>
          </label>
        </ImportProductDataContainer>
        <FirstFormContainer>
          <Input name="name" label="Nome do produto" icon="id-badge" />
          <Input
            name="min_lifes"
            type="number"
            label="Quantidade de vidas mínima"
            icon="dial-min"
            min={0}
          />
          <Input
            name="max_lifes"
            type="number"
            label="Quantidade de vidas maxima"
            icon="dial-max"
            min={0}
          />
          <Input
            name="ans"
            label="Código ANS"
            icon="barcode-read"
            onChange={() => {
              formRef.current?.setFieldValue(
                'ans',
                normalizerANSCode(formRef.current.getFieldValue('ans')),
              )
            }}
          />

          <Select
            name="locale"
            label="Abrangência"
            icon="search-location"
            options={locales.map(lc => ({ value: lc.id, label: lc.name }))}
          />

          <Select
            name="accommodation"
            label="Acomodação"
            icon="house-building"
            options={accommodations.map(ac => ({
              value: ac.id,
              label: ac.name,
            }))}
          />

          <Select
            name="coverage"
            label="Coberturas"
            icon="pharmacy"
            options={coverages.map(cv => ({ value: cv.id, label: cv.name }))}
          />

          <Select
            name="refunded"
            label="Reembolso"
            icon="money-from-bracket"
            options={[
              { value: '0', label: 'Sem reembolso' },
              { value: '1', label: 'Com reembolso' },
            ]}
          />

          <Select
            name="coparticipation"
            label="Coparticipação"
            icon="object-group"
            options={[
              { value: '0', label: 'Sem coparticipação' },
              { value: '1', label: 'Com coparticipação' },
            ]}
          />
        </FirstFormContainer>

        {plan && (
          <>
            {checkPlanOriginAndShowCorrectSelect(plan, CNPJTypes, associations)}
          </>
        )}

        <ThirdFormContainer key={formData.name}>
          {priceInputs.map(pi => (
            <Input
              key={pi.name}
              name={pi.name}
              icon="coins"
              label={pi.label}
              onChange={() => {
                formRef.current?.setFieldValue(
                  pi.name,
                  normalizerCurrencyNumber(
                    formRef.current.getFieldValue(pi.name),
                  ),
                )
              }}
            />
          ))}
        </ThirdFormContainer>

        <LastFormContainer>
          <ImportRefNetDataContainer>
            <HeadTitle title="" description="Cadastro de Redes" />
            <input
              id="refnet-csv"
              type="file"
              onChange={e => handleImportData(e, 'refnet')}
              accept=".csv"
            />

            <label htmlFor="refnet-csv">
              <Icon name="file-import" />
              <p>Importar redes existentes</p>
            </label>
          </ImportRefNetDataContainer>

          <div>
            <Select
              name="referenced_networks"
              label="Redes referenciadas"
              icon="hospital"
              options={referencedNetworks.map(rn => ({
                value: rn.id,
                label: rn.name,
              }))}
              menuPlacement="top"
            />

            <Select
              name="specialties"
              label="Especialidades"
              icon="light-emergency"
              options={referencedNetworkSpecialties.map(s => ({
                value: s.id,
                label: s.name,
              }))}
              isMulti
              menuPlacement="top"
            />

            <Button type="button" onClick={handleAddReferencedNetwork}>
              Cadastrar Rede
            </Button>
          </div>
        </LastFormContainer>

        <RefnetContainer>
          {referencedNetworksWithSpecialties &&
            referencedNetworksWithSpecialties.map(rns => (
              <p key={rns.id}>
                {rns.name} <hr /> {rns.specialties.map(s => s.name).join(' | ')}
                <Icon
                  name="circle-xmark"
                  color="#ff0000"
                  onClick={() => handleDeleteReferencedNetwork(rns.id)}
                />
              </p>
            ))}
        </RefnetContainer>

        <Button type="submit" disabled={isLoading}>
          Cadastrar
        </Button>
      </Form>
    </Container>
  )
}
export default CreateProduct
