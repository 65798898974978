import styled from 'styled-components'

export const Container = styled.button`
  background: var(--app-gradient);
  height: 50px;
  border-radius: 8px;
  border: 0;
  padding: 0 16px;
  color: #fff;
  width: 100%;
  font-weight: 500;
  transition: filter 0.3s;

  &:hover {
    filter: brightness(0.8);
  }
`
