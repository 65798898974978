import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: var(--black-blue);
  padding: 16px;
  width: 100%;

  color: var(--gray-500);
  border-bottom: 1px solid var(--text);
  border-radius: 8px;

  display: flex;
  align-items: center;
  transition: color 0.4s ease, border-color 0.4s ease;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--red-500);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--purple);
      border-color: var(--purple);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--purple);
    `}

  textarea {
    flex: 1;
    background: transparent;
    outline: none;
    border: none;
    color: var(--gray-200);

    &::placeholder {
      color: var(--gray-500);
    }
  }

  svg {
    margin-right: 16px;
  }
`

export const Error = styled(Tooltip)`
  position: absolute;
  right: 20px;
  height: 20px;

  svg {
    margin: 0;
  }

  span {
    background: var(--red-500);
    color: var(--white);

    &::before {
      border-color: var(--red-500) transparent;
    }
  }
`
