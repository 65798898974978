import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  row-gap: 3rem;
`

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1rem;
  align-items: end;
`
