import React, { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { AxiosError } from 'axios'

import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import InputUpload from '../../components/InputUpload'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { useToast } from '../../hooks/toast'
import { HeadTitle } from '../../components/HeadTitle'
import { ModalType } from '../CreatePlan'
import { StateFormData } from '../CreatePlan'
import { Icon } from '../../components/Icon'
import StepModal from '../CreatePlan/components/StepModal'
import RichTextArea from '../../components/RichTextArea'
import appLogo from '../../assets/logo.png'
import { PlanTypeCustomer } from '../../@types/PlanTypeCustomer'

import {
  Container,
  Content,
  LabelButton,
  PlanForm,
  PlanImageContainer,
  PlanImageContentContainer,
  PlanInfoContainer,
  PlanInfoFormContainer,
} from './styles'

interface Plan {
  id: string
  active: true
  name: string
  description: string
  image: {
    id: string
    name: string
    path: string
    updated_at: string
    created_at: string
  }
  admin_image?: {
    id: string
    name: string
    path: string
    updated_at: string
    created_at: string
  }
  image_path: string
  admin_image_path: string
  plan_type_customer_id: string
  validity: string | null
  important_notes: string | null
  differentials: string | null
  sales_and_service_areas: string | null
  grace_period: string | null
  needed_docs: string | null
  updated_at: Date
  created_at: Date
}

interface PlanFormData {
  name: string
  description: string
  image: string
  admin_image: string
}

const EditPlan: React.FC = () => {
  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [adminImagePreview, setAdminImagePreview] =
    useState<string | null>(null)
  const [currentModal, setCurrentModal] = useState<ModalType | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { state }: { state: { ptc: PlanTypeCustomer; plan: Plan } } =
    useLocation()
  const { ptc, plan } = { ...state }
  const [formData, setFormData] = useState({
    validity: plan.validity !== 'undefined' ? plan.validity : '',
    important_notes:
      plan.important_notes !== 'undefined' ? plan.important_notes : '',
    differentials: plan.differentials !== 'undefined' ? plan.differentials : '',
    sales_and_service_areas:
      plan.sales_and_service_areas !== 'undefined'
        ? plan.sales_and_service_areas
        : '',
    grace_period: plan.grace_period !== 'undefined' ? plan.grace_period : '',
    needed_docs: plan.needed_docs !== 'undefined' ? plan.needed_docs : '',
  } as StateFormData)
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  const { addToast } = useToast()

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (imagePreview) URL.revokeObjectURL(imagePreview)
    const selectedFile = e.target.files![0]
    setImagePreview(URL.createObjectURL(selectedFile))
  }

  const handleAdminImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (adminImagePreview) URL.revokeObjectURL(adminImagePreview)
    const selectedFile = e.target.files![0]
    setAdminImagePreview(URL.createObjectURL(selectedFile))
  }

  const handleRemoveImagePreview = (): void => {
    if (imagePreview) URL.revokeObjectURL(imagePreview)
    setImagePreview(null)
    formRef.current?.clearField('image')
  }

  const handleRemoveAdminImagePreview = (): void => {
    if (adminImagePreview) URL.revokeObjectURL(adminImagePreview)
    setAdminImagePreview(null)
    formRef.current?.clearField('admin_image')
  }

  const handleSubmit = useCallback(
    async ({ name, description, image, admin_image }: PlanFormData) => {
      setIsLoading(true)
      try {
        formRef.current?.setErrors({})

        const data = {
          ...formData,
          name,
          description,
          image,
          admin_image,
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('Título obrigatório'),
          description: Yup.string().optional(),
          image: Yup.mixed(),
          admin_image: Yup.mixed().optional(),
          validity: Yup.string().optional(),
          important_notes: Yup.string().optional(),
          grace_period: Yup.string().optional(),
          needed_docs: Yup.string().optional(),
          differentials: Yup.string().optional(),
          sales_and_service_areas: Yup.string().optional(),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newData = new FormData()

        if (data.name !== plan.name) newData.append('name', data.name)
        if (data.description !== plan.description)
          newData.append('description', data.description)
        if (data.validity !== plan.validity)
          newData.append('validity', data.validity)
        if (data.important_notes !== plan.important_notes)
          newData.append('important_notes', data.important_notes)
        if (data.differentials !== plan.differentials)
          newData.append('differentials', data.differentials)
        if (data.sales_and_service_areas !== plan.sales_and_service_areas)
          newData.append(
            'sales_and_service_areas',
            data.sales_and_service_areas,
          )
        if (data.grace_period !== plan.grace_period)
          newData.append('grace_period', data.grace_period)
        if (data.needed_docs !== plan.needed_docs)
          newData.append('needed_docs', data.needed_docs)
        if (data.image) newData.append('image', data.image)
        if (data.admin_image) newData.append('admin_image', data.admin_image)

        const {
          id,
          active,
          image: planImage,
          admin_image: adminImage,
          image_path,
          admin_image_path,
          plan_type_customer_id,
          created_at,
          updated_at,
          ...comparativePlan
        } = plan

        if (comparativePlan !== data) {
          await api.put(`/plans/${plan.id}`, newData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        }

        addToast({
          type: 'success',
          title: 'Atualizado com sucesso!',
          description: 'Plano atualizado com sucesso.',
        })

        history.goBack()
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast, history, plan, formData],
  )

  const handleModalOpen = (modalToOpen: ModalType) => {
    setCurrentModal(modalToOpen)
  }

  const handleModalClose = () => {
    setCurrentModal(null)
  }

  const handleInputChange = (input: ModalType, value: string) => {
    setFormData(currentData => ({ ...currentData, [input]: value }))
  }

  return (
    <Container>
      <HeadTitle
        title={`Editando plano: ${plan.name}`}
        description="Preencha todas as informações corretamente, confira tudo antes de continuar."
        goBack
      />
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          name: plan.name,
          description: plan.description,
        }}
      >
        <Content>
          <PlanInfoContainer>
            <PlanImageContentContainer>
              <p>Operadora</p>
              <PlanImageContainer>
                {imagePreview && (
                  <Button onClick={handleRemoveImagePreview}>
                    <Icon name="cross" fontSize={24} />
                  </Button>
                )}
                <img src={imagePreview ?? plan.image.path} alt={plan.name} />
                <LabelButton htmlFor="image">
                  <Icon name="pencil" fontSize={24} color="#fff" />
                </LabelButton>
              </PlanImageContainer>
            </PlanImageContentContainer>
            {ptc.name.includes('Adesão') && (
              <PlanImageContentContainer>
                <p>Administradora</p>
                <PlanImageContainer>
                  {adminImagePreview && (
                    <Button onClick={handleRemoveAdminImagePreview}>
                      <Icon name="cross" fontSize={24} />
                    </Button>
                  )}
                  <img
                    src={adminImagePreview ?? plan.admin_image?.path ?? appLogo}
                    alt={ptc.name}
                  />
                  <LabelButton htmlFor="admin_image">
                    <Icon name="pencil" fontSize={24} color="#fff" />
                  </LabelButton>
                </PlanImageContainer>
              </PlanImageContentContainer>
            )}

            <PlanInfoFormContainer>
              <strong>Atenção enquanto edita!</strong>
              <Input
                name="name"
                label="Nome da Operadora"
                disabled={isLoading}
              />
              <Input
                name="description"
                label="Descrição"
                disabled={isLoading}
              />
              <InputUpload
                name="image"
                onInputChange={handleImageChange}
                label="Imagem"
                accept="image/*"
                visible={false}
                disabled={isLoading}
              />
              <InputUpload
                name="admin_image"
                onInputChange={handleAdminImageChange}
                placeholder="Imagem"
                accept="image/*"
                visible={false}
                disabled={isLoading}
              />
            </PlanInfoFormContainer>
          </PlanInfoContainer>

          <PlanForm>
            <StepModal
              name={ModalType.validity}
              title="Vigência da Tabela"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.validity]}
              isInvalid={!!formRef.current?.getFieldError(ModalType.validity)}
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <Input
                name={ModalType.validity}
                type="month"
                label="Vigência"
                value={formData[ModalType.validity]}
                disabled={isLoading}
                keepDirty
                onChange={e =>
                  handleInputChange(ModalType.validity, e.target.value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.importantNotes}
              title="Observações Importantes"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.importantNotes]}
              isInvalid={
                !!formRef.current?.getFieldError(ModalType.importantNotes)
              }
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.importantNotes}
                value={formData[ModalType.importantNotes] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.importantNotes, value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.gracePeriod}
              title="Carência"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.gracePeriod]}
              isInvalid={
                !!formRef.current?.getFieldError(ModalType.gracePeriod)
              }
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.gracePeriod}
                value={formData[ModalType.gracePeriod] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.gracePeriod, value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.neededDocs}
              title="Documentação necessária"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.neededDocs]}
              isInvalid={!!formRef.current?.getFieldError(ModalType.neededDocs)}
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.neededDocs}
                value={formData[ModalType.neededDocs] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.neededDocs, value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.differentials}
              title="Diferenciais"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.differentials]}
              isInvalid={
                !!formRef.current?.getFieldError(ModalType.differentials)
              }
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.differentials}
                value={formData[ModalType.differentials] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.differentials, value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.salesAndServiceAreas}
              title="Área de comercialização e atendimento"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.salesAndServiceAreas]}
              isInvalid={
                !!formRef.current?.getFieldError(ModalType.salesAndServiceAreas)
              }
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.salesAndServiceAreas}
                value={formData[ModalType.salesAndServiceAreas] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.salesAndServiceAreas, value)
                }
              />
            </StepModal>
          </PlanForm>

          <Button disabled={isLoading} type="submit">
            Atualizar Informações
          </Button>
        </Content>
      </Form>
    </Container>
  )
}

export default EditPlan
