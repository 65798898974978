import { createGlobalStyle, keyframes } from 'styled-components'

export default createGlobalStyle`
  :root {
    --background: ${({ theme }) => theme.background};
    --title: ${({ theme }) => theme.titles};
    --text: ${({ theme }) => theme.text};
    --element: ${({ theme }) => theme.element};

    --variant-1: ${({ theme }) => theme.variant1};
    --variant-2: ${({ theme }) => theme.variant2};

    --variant-3: ${({ theme }) => theme.variant3};
    --variant-4: ${({ theme }) => theme.variant4};

    --card-icon-background: linear-gradient(90deg, var(--variant-1) 0%, var(--variant-2) 114.63%);
    --card-icon-color: linear-gradient(90deg, var(--variant-3) 0%, var(--variant-4) 114.63%);
    --card-title-color: ${({ theme }) => theme.cardTitleColor};

    --app-gradient: linear-gradient(90deg, #7032FF 0%, #2266AA 114.63%);
    --border: #afafaf;
    --blue: #4875E6;
    --purple: #8C55E9;
    --white: #FFFFFF;

    --gray-100: #DFDFDF;
    --gray-200: #D6D6D6;
    --gray-500: #757988;

    --green-200: #00DD66;

    --red-100: #f8baba;
    --red-500: #ff125b;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%
    }

    @media (max-width: 720px) {
      font-size: 87.5%
    }
  }

  body {
    background: var(--background);
    color: var(--text);
    -webkit-font-smoothing: antialiased;
    transition: color 0.4s ease, background 0.4s ease, background-color 0.4s ease;
  }

  body, input, textarea, button {
    font-family: 'Montserrat', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  hr {
    margin: 15px 0;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
    background: var(--element);
    display: grid;
    row-gap: 2.375rem;
    padding: 1.375rem;
    position: relative;
    outline: none;
    border-radius: 2px;
    max-width: 1280px;
    max-height: 720px;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .tox {
    position: relative !important;
    z-index: 10000 !important;
  }
`

export const growDown = keyframes`
  0% {
      transform: scaleY(0);
  }
  80% {
      transform: scaleY(1.1);
  }
  100% {
      transform: scaleY(1);
  }
`

export const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`
