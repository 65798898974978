export function capitalizeWordsExcept(
  input: string,
  exceptions: string[] | undefined = [
    'da',
    'de',
    'do',
    'das',
    'dos',
    'para',
    'e',
    'em',
    'por',
    'com',
    'um',
    'uma',
    'uns',
    'umas',
    'ao',
    'à',
    'aos',
    'às',
  ],
): string {
  const words = input.toLowerCase().split(' ')

  const capitalizeWords = words.map(word => {
    if (exceptions.includes(word)) {
      return word
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
  })

  return capitalizeWords.join(' ')
}
