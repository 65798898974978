import React, { useEffect, useState } from 'react'

import { normalizerCNPJNumber } from '../../../utils/normalizer'
import { useAuth } from '../../../hooks/auth'
import profileLogo from '../../../assets/profile-logo.svg'

import { Container } from './styles'
import axios from 'axios'
import { useToast } from '../../../hooks/toast'

export interface State {
  id: number
  sigla: string
  nome: string
  regiao: {
    id: number
    sigla: string
    nome: string
  }
}

export const ProfileCard: React.FC = () => {
  const { company } = useAuth()
  const [state, setState] = useState('')
  const { addToast } = useToast()

  useEffect(() => {
    const getStateName = async () => {
      try {
        const response = await axios.get<State[]>(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome',
        )

        const companyCurrentState = response.data.filter(
          data => data.sigla === company.state,
        )

        setState(
          `${companyCurrentState[0].nome} (${companyCurrentState[0].sigla})`,
        )
      } catch {
        addToast({
          type: 'error',
          title: 'Oops!',
          description: 'Algo deu errado ao tentar obter o nome do estado.',
        })
      }
    }
    void getStateName()
  }, [company.state, addToast])

  return (
    <Container>
      <img src={profileLogo} alt={company.name} />
      <div>
        <h1>{company.name}</h1>
        <p>CNPJ: {normalizerCNPJNumber(company.cnpj)}</p>
        <p>Região: {state ?? company.state}</p>
      </div>
    </Container>
  )
}
