import React from 'react'
import { IContentLoaderProps } from 'react-content-loader'

import { CustomContentLoader } from './styles'
import { useTheme } from '../../hooks/theme'

interface SkeletonProps
  extends Omit<IContentLoaderProps, 'backgroundColor' | 'foregroundColor'> {
  children: React.ReactNode
}

export const Skeleton: React.FC<SkeletonProps> = ({ children, ...rest }) => {
  const { theme } = useTheme()

  return (
    <CustomContentLoader
      backgroundColor={theme ? '#0F0F0F' : '#F1F1F1'}
      foregroundColor={theme ? '#191919' : '#FFFFFF'}
      {...rest}
    >
      {children}
    </CustomContentLoader>
  )
}
