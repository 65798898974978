import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'

import { HeadTitle } from '../../components/HeadTitle'
import api from '../../services/api'
import { Plan } from '../../@types/Plan'
import { Product } from '../../@types/Product'
import { Icon } from '../../components/Icon'
import { EmptyState } from '../../components/EmptyState'
import emptyProducts from '../../assets/empty-products.json'
import { privateRoutePaths } from '../../routes/private'
import { useToast } from '../../hooks/toast'
import { normalizerANSCode } from '../../utils/normalizer'

import {
  Container,
  ProductsTable,
  ProductsTableHeaderContainer,
  ProductsTableItem,
  EditActionButton,
  DeleteActionButton,
  PowerButton,
  ProductsTableBodyContainer,
} from './styles'

interface PlanProps extends Plan {
  products: Product[]
}

const PlanProducts = () => {
  const [plan, setPlan] = useState<PlanProps>({} as PlanProps)
  const [isLoading, setIsLoading] = useState(true)
  const [doingAction, setDoingAction] = useState(false)
  const { addToast } = useToast()
  const { planId } = useParams<{ planId: string }>()

  async function fetchPlan(): Promise<void> {
    const plan = await api.get<PlanProps>(`/plans/${planId}`)
    const products = await api.get<Product[]>(`/plan-products/${planId}`)

    setPlan({ ...plan.data, products: products.data })
    setIsLoading(false)
  }

  useEffect(() => {
    fetchPlan()
  }, [planId])

  const handleProductDelete = async (product: Product) => {
    if (
      window.confirm(
        `Tem certeza que deseja deletar o produto ${product.name}?`,
      )
    ) {
      try {
        setDoingAction(true)
        await api.delete(`/plan-products/${product.id}`)

        addToast({
          type: 'success',
          title: 'Êxito!',
          description: `O produto ${product.name} foi deletado com sucesso!`,
        })

        await fetchPlan()
      } catch (err) {
        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Oops!',
          description:
            error.response?.data.message ||
            'Ocorreu um problema interno no servidor.',
        })
      } finally {
        setDoingAction(false)
      }
    }
  }

  const handleProductPower = async (product: Product) => {
    if (
      window.confirm(
        `Tem certeza que deseja desativar o produto ${product.name}?`,
      )
    ) {
      try {
        setDoingAction(true)
        await api.put(`/plan-products/${product.id}`, {
          active: !product.active,
        })

        addToast({
          type: 'success',
          title: 'Êxito!',
          description: `O produto ${product.name} foi ${
            product.active ? 'desativado' : 'ativado'
          } com sucesso!`,
        })

        await fetchPlan()
      } catch (err) {
        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Oops!',
          description:
            error.response?.data.message ||
            'Ocorreu um problema interno no servidor.',
        })
      } finally {
        setDoingAction(false)
      }
    }
  }

  const handleProductChangeOrder = async (
    result: DropResult,
  ): Promise<void> => {
    if (!result.destination) return
    setDoingAction(true)
    const reorderedProducts = Array.from(plan.products)
    const [reorderedProduct] = reorderedProducts.splice(result.source.index, 1)
    reorderedProducts.splice(result.destination.index, 0, reorderedProduct)
    setPlan({ ...plan, products: reorderedProducts })

    const newPositions = reorderedProducts.map((product, index) => ({
      id: product.id,
      position: index + 1,
    }))

    try {
      await api.post('/plan-products/positions', { newPositions })
      addToast({
        title: 'Sucesso!',
        description: 'Os produtos foram ordenados com sucesso!',
        type: 'success',
      })
    } catch (error) {
      addToast({
        title: 'Oops!',
        description: 'Algo deu errado ao reposicionar os produtos',
        type: 'error',
      })
    } finally {
      setDoingAction(false)
    }
  }

  if (isLoading) return <h1>Carregando...</h1>

  if (plan.products.length === 0)
    return (
      <EmptyState
        image={emptyProducts}
        style={{
          maxHeight: '400px',
        }}
        title={`Crie um novo produto para ${plan.name}.`}
        desc="Criando produtos você possibilita que o plano seja incluso no nosso cotador! 😄"
        goToButton={{
          title: 'Criar novo produto',
          to: privateRoutePaths.createInsurancePlanProduct.replace(
            ':planId',
            planId,
          ),
        }}
      />
    )

  return (
    <Container>
      <HeadTitle
        title={`Produtos do plano: ${plan?.name}`}
        navigate={{
          to: privateRoutePaths.createInsurancePlanProduct.replace(
            ':planId',
            planId,
          ),
          name: 'Criar novo produto',
        }}
        goBack
      />

      <ProductsTable>
        <ProductsTableHeaderContainer>
          <div></div>
          <div>Nome do produto</div>
          <div>Coparticipação</div>
          <div>ANS</div>
          <div></div>
        </ProductsTableHeaderContainer>
        <DragDropContext onDragEnd={handleProductChangeOrder}>
          <Droppable droppableId="list">
            {provided => (
              <ProductsTableBodyContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {plan &&
                  plan.products.length > 0 &&
                  plan.products.map((product, i) => (
                    <Draggable
                      key={product.id}
                      draggableId={product.id}
                      index={i}
                      isDragDisabled={doingAction}
                    >
                      {provided => (
                        <ProductsTableItem
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div>
                            <Icon name="stethoscope" fontSize={20} />
                          </div>
                          <div data-label="Nome do produto:">
                            {product.name}
                          </div>
                          <div data-label="Coparticipação:">
                            {product.hasCoparticipation
                              ? 'Com coparticipação'
                              : 'Sem coparticipação'}
                          </div>
                          <div data-label="ANS:">
                            {product.ans
                              ? normalizerANSCode(product.ans)
                              : 'Não informado'}
                          </div>
                          <div>
                            <DeleteActionButton
                              disabled={doingAction}
                              onClick={() => handleProductDelete(product)}
                            >
                              <Icon
                                name="circle-xmark"
                                fontSize={20}
                                color="#ff0000"
                              />
                            </DeleteActionButton>
                            <Link
                              to={privateRoutePaths.editInsurancePlanProduct
                                .replace(':planId', planId)
                                .replace(':productId', product.id)}
                            >
                              <EditActionButton>Editar</EditActionButton>
                            </Link>
                            <PowerButton
                              onClick={() => handleProductPower(product)}
                              disabled={doingAction}
                            >
                              {product.active ? 'Desativar' : 'Ativar'}
                            </PowerButton>
                          </div>
                        </ProductsTableItem>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </ProductsTableBodyContainer>
            )}
          </Droppable>
        </DragDropContext>
      </ProductsTable>
    </Container>
  )
}
export default PlanProducts
