import styled, { css } from 'styled-components'

interface ContainerProps {
  isInvalid?: boolean
  isFilled?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.8rem 1.275rem;
  border-radius: 2px;
  border: 1px solid #ff9966;

  i {
    color: #ff9966;
  }

  div:first-child {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  button {
    width: 100px;
    height: 30px;
    border-radius: 2px;
    background: #ff9966;
    font-weight: 700;
  }

  ${({ isFilled }) =>
    isFilled &&
    css`
      border-color: var(--green-200);

      button {
        background: var(--green-200);
      }

      i {
        color: var(--green-200);
      }
    `}

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border-color: var(--red-500);

      button {
        background: var(--red-500);
      }

      i {
        color: var(--red-500);
      }
    `}
`

export const ModalContainer = styled.div`
  display: grid;
  row-gap: 2rem;

  i {
    cursor: pointer;
    color: var(--red-500);

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const InputContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
`
