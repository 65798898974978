import React, { useRef, useState } from 'react'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'

import logoSulamerica from '../../../assets/temp/logo-sulamerica.png'
import logoCeparh from '../../../assets/temp/logo-ceparh.png'
import logoCMT from '../../../assets/temp/logo-cmt.png'
import Select from '../../../components/Select'

import {
  Body,
  Header,
  HeaderContent,
  Container,
  Title,
  FormContainer,
  ValuesContainer,
  TotalValueContainer,
  DependentsValueContainer,
  ValuesDescription,
  OwnerPrice,
  Footer,
  Info,
  SimulatorButton,
} from './styles'

enum PlanPricesPerAge {
  A = '0 a 18',
  B = '19 a 23',
  C = '24 a 28',
  D = '29 a 33',
  E = '34 a 38',
  F = '39 a 43',
  G = '44 a 48',
  H = '49 a 53',
  I = '54 a 58',
  J = '59+',
}

const Simulator: React.FC = () => {
  document.title = 'Simulador Texas | Outlet Corretora'
  document.body.style.background = '#f3f3f3'
  const formRef = useRef<FormHandles>(null)
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null)
  const [ownerValue, setOwnerValue] = useState(0)
  const [dependents, setDependents] = useState<number | null>(null)
  const [dependentsValues, setDependentsValue] = useState({
    1: 0.0,
    2: 0.0,
    3: 0.0,
    4: 0.0,
  })

  const totalDependentsValue = Object.values(dependentsValues)
    .reduce((acc, curr) => acc + curr, 0)
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

  const ownerValueWithDiscount = ownerValue * 0.35
  const totalValue =
    ownerValueWithDiscount +
    Object.values(dependentsValues).reduce((acc, curr) => acc + curr, 0)

  const totalValueFormatted = totalValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  const availablePlans = [
    {
      id: 1,
      name: 'Enfermaria',
      prices: {
        [PlanPricesPerAge.A]: 148.12,
        [PlanPricesPerAge.B]: 185.15,
        [PlanPricesPerAge.C]: 229.59,
        [PlanPricesPerAge.D]: 254.84,
        [PlanPricesPerAge.E]: 272.68,
        [PlanPricesPerAge.F]: 316.31,
        [PlanPricesPerAge.G]: 378.12,
        [PlanPricesPerAge.H]: 443.15,
        [PlanPricesPerAge.I]: 527.57,
        [PlanPricesPerAge.J]: 888.71,
      },
    },
  ]

  const dependentsAgeInput = []

  if (dependents) {
    for (let index = 0; index < dependents; index++) {
      dependentsAgeInput.push(index + 1)
    }
  }

  const handleOwnerValueChange = (newValue: any) => {
    const selected: { value: number; label: string } = newValue
    setOwnerValue(Number(selected.value))
  }

  const handleDependentValueChange = (newValue: any, dependentId: number) => {
    const { value }: { value: number; label: string } = newValue

    const newDependentsValues = { ...dependentsValues }
    newDependentsValues[dependentId as 1 | 2 | 3 | 4] = value

    setDependentsValue({ ...newDependentsValues })
  }

  const handleSetSelectedPlan = (newValue: any) => {
    const selected: { value: number; label: string } = newValue
    formRef.current?.setData({ ownerAge: '', dependents: '' })
    setSelectedPlan(selected.value)
    setOwnerValue(0)
    setDependents(null)
    setDependentsValue({
      1: 0.0,
      2: 0.0,
      3: 0.0,
      4: 0.0,
    })
  }

  const handleSetDependents = (newValue: any) => {
    const selected: { value: number; label: string } = newValue
    if (selected.value === 0) {
      setDependents(null)
      setDependentsValue({
        1: 0.0,
        2: 0.0,
        3: 0.0,
        4: 0.0,
      })
      return
    }

    const dependents = selected.value

    setDependentsValue({
      1: dependents >= 1 ? dependentsValues[1] : 0.0,
      2: dependents >= 2 ? dependentsValues[2] : 0.0,
      3: dependents >= 3 ? dependentsValues[3] : 0.0,
      4: dependents >= 4 ? dependentsValues[4] : 0.0,
    })

    setDependents(dependents)
  }

  return (
    <Body>
      <Header>
        <HeaderContent>
          <div>
            <h1>Seu plano Sulamerica</h1>
            <p>Simule o valor do plano no seu grupo familiar.</p>
          </div>
          <div>
            <img src={logoSulamerica} alt="Sulamerica" />
            <img src={logoCeparh} alt="CPart" />
          </div>
        </HeaderContent>
      </Header>
      <Container>
        <Title>
          Preencha as informações abaixo para simular o seu plano de saúde
        </Title>
        <Form
          onSubmit={() => {
            return
          }}
          ref={formRef}
        >
          <FormContainer>
            <Select
              name="plan"
              onChange={handleSetSelectedPlan}
              label="Plano"
              options={availablePlans.map((plan, i) => ({
                value: i,
                label: plan.name,
              }))}
            />

            <Select
              name="ownerAge"
              onChange={handleOwnerValueChange}
              label="Faixa Etária - Titular"
              noOptionsMessage={() => 'Selecione um plano para continuar...'}
              options={
                selectedPlan !== null
                  ? Object.entries(availablePlans[selectedPlan].prices).map(
                      ([key, value]) => ({ value: value, label: key }),
                    )
                  : []
              }
            />

            <Select
              name="dependents"
              onChange={handleSetDependents}
              label="Dependentes"
              noOptionsMessage={() => 'Selecione um plano para continuar...'}
              options={
                selectedPlan !== null
                  ? [
                      { value: 0, label: 'Não.' },
                      { value: 1, label: 'Sim, 1.' },
                      { value: 2, label: 'Sim, 2.' },
                      { value: 3, label: 'Sim, 3.' },
                      { value: 4, label: 'Sim, 4.' },
                    ]
                  : []
              }
            />
          </FormContainer>

          {dependentsAgeInput?.length > 0 && (
            <>
              <div>
                <Title>Informe a idade do(s) dependente(s) abaixo:</Title>
                <Info>
                  <span>ATENÇÃO:</span> Cônjuge ou filhos solteiros até 24 anos.
                </Info>
              </div>
              <FormContainer>
                {dependentsAgeInput.map(element => (
                  <Select
                    key={element}
                    options={Object.entries(
                      availablePlans[selectedPlan!].prices,
                    ).map(([key, value]) => ({ value: value, label: key }))}
                    name={`dependentAge${element}`}
                    onChange={newValue =>
                      handleDependentValueChange(newValue, element)
                    }
                    label={`Faixa Etária - Dependente ${element}`}
                  />
                ))}
              </FormContainer>
            </>
          )}
        </Form>
        <ValuesContainer>
          <TotalValueContainer>
            <p>Valor total com desconto</p>
            <h1>{totalValueFormatted}</h1>
          </TotalValueContainer>

          <DependentsValueContainer>
            <p>Valor apenas dos dependentes</p>
            <h1>{totalDependentsValue}</h1>
          </DependentsValueContainer>
        </ValuesContainer>

        <ValuesDescription>
          <h1>Descrição completa:</h1>
          <div>
            {ownerValue !== 0 && (
              <OwnerPrice>
                <strong>Títular:</strong> De{' '}
                <span>
                  {ownerValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </span>{' '}
                para{' '}
                <span>
                  {ownerValueWithDiscount.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </span>{' '}
                (65% de desconto)
              </OwnerPrice>
            )}
            {Object.entries(dependentsValues)
              .filter(([, value]) => value !== 0)
              .map(([key, value]) => (
                <p key={key}>
                  <strong>Dependente {key}:</strong>{' '}
                  {value.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </p>
              ))}
          </div>
        </ValuesDescription>
        <Footer>
          <p>Corretor:</p>
          <img src={logoCMT} alt="Grupo Texas" />
          <a
            href="https://wa.me/71991442541"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SimulatorButton>Fale com o corretor</SimulatorButton>
          </a>
        </Footer>
      </Container>
    </Body>
  )
}

export default Simulator
