import React, { useCallback, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { AxiosError } from 'axios'

import getValidationErrors from '../../utils/getValidationErrors'
import api from '../../services/api'
import { useToast } from '../../hooks/toast'
import logoImg from '../../assets/logo.png'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { demask, normalizerCNPJNumber } from '../../utils/normalizer'

import { Container, Content, AnimationContainer, Background } from './styles'

interface ForgotPasswordFormData {
  cnpj: string
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const { addToast } = useToast()
  const history = useHistory()

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          cnpj: Yup.string()
            .min(18, 'Digite um CNPJ válido')
            .max(18, 'Digite um CNPJ válido')
            .required('CNPJ obrigatório'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await api.post(`sessions/passwordRecovery/${demask(data.cnpj)}`)

        addToast({
          type: 'success',
          title: 'Solicitação realizada!',
          description:
            'Enviamos um link para recuperação de senha pro seu e-mail!',
        })

        history.push('/')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao fazer a solicitação, tente novamente.',
        })
      }
    },
    [addToast, history],
  )

  return (
    <Container>
      <Background />

      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Texas" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Recuperação de senha</h1>

            <Input
              name="cnpj"
              icon="briefcase"
              label="CNPJ"
              maxLength={18}
              onChange={() =>
                formRef.current?.setFieldValue(
                  'cnpj',
                  normalizerCNPJNumber(formRef.current.getFieldValue('cnpj')),
                )
              }
            />

            <Button type="submit">Recuperar</Button>
          </Form>

          <Link to="/">
            <FiArrowLeft /> Voltar para logon
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default ForgotPassword
