import React, { useEffect, useState, useRef, useCallback } from 'react'
import * as Yup from 'yup'
import { AxiosError } from 'axios'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Link, useParams } from 'react-router-dom'

import Button from '../../components/Button'
import Input from '../../components/Input'
import InputUpload from '../../components/InputUpload'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import TextArea from '../../components/TextArea'
import { HeadTitle } from '../../components/HeadTitle'
import { capitalizeWordsExcept } from '../../utils/capitalizeWordsExcept'
import { Icon } from '../../components/Icon'
import { privateRoutePaths } from '../../routes/private'
import { Plan } from '../../@types/Plan'
import {
  PlanEntityWithFileProps,
  PlanEntityWithLinkProps,
} from '../../@types/PlanEntity'

import {
  Container,
  Material,
  MaterialsContainer,
  MaterialItemContainer,
  MaterialItemActions,
  HrLine,
} from './styles'

interface PlanProps extends Plan {
  associative_entity: PlanEntityWithFileProps[]
  support_material: PlanEntityWithFileProps[]
  price_table: PlanEntityWithFileProps[]
  link: PlanEntityWithLinkProps[]
}

interface TabelaPrecoFormData {
  plan_id: string
  name: string
  price_table_attachment: string
}

interface FichaAssociativaFormData {
  plan_id: string
  name: string
  associative_entity_attachment: string
}

interface MaterialApoioFormData {
  plan_id: string
  name: string
  support_material_attachment: string
}

interface LinkFormData {
  plan_id: string
  name: string
  url: string
}

const PlanMaterials: React.FC = () => {
  const tabelaPrecoFormRef = useRef<FormHandles>(null)
  const fichaAssociativaFormRef = useRef<FormHandles>(null)
  const materialApoioFormRef = useRef<FormHandles>(null)
  const linkFormRef = useRef<FormHandles>(null)

  const [plan, setPlan] = useState<PlanProps>()
  const [isLoading, setIsLoading] = useState(false)

  const { planId }: { planId: string } = useParams()
  const { addToast } = useToast()

  useEffect(() => {
    async function loadPlan(): Promise<void> {
      const response = await api.get<PlanProps>(`/plans/${planId}`)

      setPlan(response.data)
    }

    loadPlan()
  }, [planId])

  const tabelaPrecoHandleSubmit = useCallback(
    async (data: TabelaPrecoFormData) => {
      setIsLoading(true)
      try {
        tabelaPrecoFormRef.current?.setErrors({})

        data.plan_id = planId

        const schema = Yup.object().shape({
          plan_id: Yup.string().required('ID do plano obrigatório'),
          name: Yup.string().required('Título obrigatório'),
          price_table_attachment: Yup.string().required('Anexo obrigatório'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newData = new FormData()

        newData.append('plan_id', data.plan_id)
        newData.append('name', data.name)
        newData.append('attachment_path', data.price_table_attachment)

        await api.post(`price-table/${planId}`, newData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        addToast({
          type: 'success',
          title: 'Cadastrado com sucesso',
          description: 'Tabela de preço cadastrada com sucesso.',
        })

        const response = await api.get(`/plans/${planId}`)

        setPlan(response.data)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          tabelaPrecoFormRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(false)
        tabelaPrecoFormRef.current?.reset()
      }
    },
    [addToast, planId],
  )

  const fichaAssociativaHandleSubmit = useCallback(
    async (data: FichaAssociativaFormData) => {
      setIsLoading(true)
      try {
        fichaAssociativaFormRef.current?.setErrors({})

        data.plan_id = planId

        const schema = Yup.object().shape({
          plan_id: Yup.string().required('ID do plano obrigatório'),
          name: Yup.string().required('Título obrigatório'),
          associative_entity_attachment:
            Yup.string().required('Anexo obrigatório'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newData = new FormData()

        newData.append('plan_id', data.plan_id)
        newData.append('name', data.name)
        newData.append('attachment_path', data.associative_entity_attachment)

        await api.post(`associative-entity/${planId}`, newData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        addToast({
          type: 'success',
          title: 'Cadastrado com sucesso',
          description: 'Ficha associativa cadastrada com sucesso.',
        })

        const response = await api.get(`/plans/${planId}`)

        setPlan(response.data)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          fichaAssociativaFormRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(false)
        fichaAssociativaFormRef.current?.reset()
      }
    },
    [addToast, planId],
  )

  const materialApoioHandleSubmit = useCallback(
    async (data: MaterialApoioFormData) => {
      setIsLoading(true)
      try {
        materialApoioFormRef.current?.setErrors({})

        data.plan_id = planId

        const schema = Yup.object().shape({
          plan_id: Yup.string().required('ID do plano obrigatório'),
          name: Yup.string().required('Título obrigatório'),
          support_material_attachment:
            Yup.string().required('Anexo obrigatório'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newData = new FormData()

        newData.append('plan_id', data.plan_id)
        newData.append('name', data.name)
        newData.append('attachment_path', data.support_material_attachment)

        await api.post(`support-material/${planId}`, newData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        addToast({
          type: 'success',
          title: 'Cadastrado com sucesso',
          description: 'Material de apoio cadastrado com sucesso.',
        })

        const response = await api.get(`/plans/${planId}`)

        setPlan(response.data)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          materialApoioFormRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(false)
        materialApoioFormRef.current?.reset()
      }
    },
    [addToast, planId],
  )

  const linkHandleSubmit = useCallback(
    async (data: LinkFormData) => {
      setIsLoading(true)
      try {
        linkFormRef.current?.setErrors({})

        data.plan_id = planId

        const schema = Yup.object().shape({
          plan_id: Yup.string().required('ID do plano obrigatório'),
          name: Yup.string().required('Título obrigatório'),
          url: Yup.string().required('Link obrigatório'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await api.post(`link/${planId}`, data)

        addToast({
          type: 'success',
          title: 'Cadastrado com sucesso',
          description: 'Link cadastrado com sucesso.',
        })

        const response = await api.get(`/plans/${planId}`)

        setPlan(response.data)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          linkFormRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(false)
        linkFormRef.current?.reset()
      }
    },
    [addToast, planId],
  )

  if (!plan) {
    return <h1>Carregando...</h1>
  }

  const priceTable: PlanEntityWithFileProps[] = []
  const associativeEntity: PlanEntityWithFileProps[] = []
  const supportMaterial: PlanEntityWithFileProps[] = []
  const link: PlanEntityWithLinkProps[] = []

  return (
    <Container>
      <HeadTitle
        title={`Tabelas e materiais do plano: ${plan.name}`}
        description={plan.description}
        goBack
      />

      <MaterialsContainer>
        <Material>
          <h2>Tabela de Preço</h2>
          <Form ref={tabelaPrecoFormRef} onSubmit={tabelaPrecoHandleSubmit}>
            <Input name="name" label="Nome da tabela" disabled={isLoading} />
            <InputUpload
              name="price_table_attachment"
              label="Anexo"
              disabled={isLoading}
            />
            <Button type="submit" disabled={isLoading}>
              Cadastrar Tabela
            </Button>
          </Form>
          <HrLine />
          <div>
            {priceTable
              .concat(plan?.price_table)
              .reverse()
              .map(item => (
                <MaterialItemContainer key={item.id}>
                  <a
                    href={item.attachments.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {capitalizeWordsExcept(item.name)}
                  </a>
                  <MaterialItemActions>
                    <div title="Excluir">
                      <Icon
                        name="trash"
                        color="#ff0000"
                        fontSize={16}
                        onClick={async () => {
                          if (
                            window.confirm(
                              `Deseja realmente excluir a Tabela de Preço: ${capitalizeWordsExcept(
                                item.name,
                              )}?`,
                            )
                          ) {
                            await api.delete(`/price-table/${item.id}`)
                            window.location.reload()
                          }
                        }}
                      />
                    </div>
                    <div title="Editar">
                      <Link
                        to={{
                          pathname: privateRoutePaths.editInsurancePlanFile,
                          state: {
                            itemName: 'Tabela de Preço',
                            ...item,
                            updateRoute: 'price-table',
                          },
                        }}
                      >
                        <Icon name="edit" fontSize={16} />
                      </Link>
                    </div>
                    <div title="Visualizar">
                      <a
                        href={item.attachments.path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="eye" fontSize={16} />
                      </a>
                    </div>
                  </MaterialItemActions>
                </MaterialItemContainer>
              ))}
          </div>
        </Material>
        <Material>
          <h2>Fichas Associativas</h2>
          <Form
            ref={fichaAssociativaFormRef}
            onSubmit={fichaAssociativaHandleSubmit}
          >
            <Input name="name" label="Nome da ficha" disabled={isLoading} />
            <InputUpload
              name="associative_entity_attachment"
              label="Anexo"
              disabled={isLoading}
            />
            <Button type="submit" disabled={isLoading}>
              Cadastrar Ficha
            </Button>
          </Form>
          <HrLine />
          <div>
            {associativeEntity
              .concat(plan?.associative_entity)
              .reverse()
              .map(item => (
                <MaterialItemContainer key={item.id}>
                  <a
                    href={item.attachments.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {capitalizeWordsExcept(item.name)}
                  </a>
                  <MaterialItemActions>
                    <div>
                      <Icon
                        name="trash"
                        color="#ff0000"
                        fontSize={16}
                        onClick={async () => {
                          if (
                            window.confirm(
                              `Deseja realmente excluir a Ficha Associativa: ${capitalizeWordsExcept(
                                item.name,
                              )}?`,
                            )
                          ) {
                            await api.delete(`/associative-entity/${item.id}`)
                            window.location.reload()
                          }
                        }}
                      />
                    </div>
                    <div>
                      <Link
                        to={{
                          pathname: privateRoutePaths.editInsurancePlanFile,
                          state: {
                            itemName: 'Ficha Associativa',
                            ...item,
                            updateRoute: 'associative-entity',
                          },
                        }}
                      >
                        <Icon name="edit" fontSize={16} />
                      </Link>
                    </div>
                    <div>
                      <a
                        href={item.attachments.path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="eye" fontSize={16} />
                      </a>
                    </div>
                  </MaterialItemActions>
                </MaterialItemContainer>
              ))}
          </div>
        </Material>
        <Material>
          <h2>Material de Apoio</h2>
          <Form ref={materialApoioFormRef} onSubmit={materialApoioHandleSubmit}>
            <Input name="name" label="Nome do material" disabled={isLoading} />
            <InputUpload
              name="support_material_attachment"
              label="Anexo"
              disabled={isLoading}
            />
            <Button type="submit" disabled={isLoading}>
              Cadastrar Material
            </Button>
          </Form>
          <HrLine />
          <div>
            {supportMaterial
              .concat(plan?.support_material)
              .reverse()
              .map(item => (
                <MaterialItemContainer key={item.id}>
                  <a
                    href={item.attachments.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {capitalizeWordsExcept(item.name)}
                  </a>
                  <MaterialItemActions>
                    <div>
                      <Icon
                        name="trash"
                        color="#ff0000"
                        fontSize={16}
                        onClick={async () => {
                          if (
                            window.confirm(
                              `Deseja realmente excluir o Material de Apoio: ${capitalizeWordsExcept(
                                item.name,
                              )}?`,
                            )
                          ) {
                            await api.delete(`/support-material/${item.id}`)
                            window.location.reload()
                          }
                        }}
                      />
                    </div>
                    <div>
                      <Link
                        to={{
                          pathname: privateRoutePaths.editInsurancePlanFile,
                          state: {
                            itemName: 'Material de Apoio',
                            ...item,
                            updateRoute: 'support-material',
                          },
                        }}
                      >
                        <Icon name="edit" fontSize={16} />
                      </Link>
                    </div>
                    <div>
                      <a
                        href={item.attachments.path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="eye" fontSize={16} />
                      </a>
                    </div>
                  </MaterialItemActions>
                </MaterialItemContainer>
              ))}
          </div>
        </Material>
        <Material>
          <h2>Links</h2>
          <Form ref={linkFormRef} onSubmit={linkHandleSubmit}>
            <Input name="name" label="Nome do link" disabled={isLoading} />
            <TextArea name="url" placeholder="Link" disabled={isLoading} />
            <Button type="submit" disabled={isLoading}>
              Cadastrar Link
            </Button>
          </Form>
          <HrLine />
          <div>
            {link
              .concat(plan?.link)
              .reverse()
              .map((item: PlanEntityWithLinkProps) => (
                <MaterialItemContainer key={item.id}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {capitalizeWordsExcept(item.name)}
                  </a>
                  <MaterialItemActions>
                    <div>
                      <Icon
                        name="trash"
                        color="#ff0000"
                        fontSize={16}
                        onClick={async () => {
                          if (
                            window.confirm(
                              `Deseja realmente excluir o Link: ${capitalizeWordsExcept(
                                item.name,
                              )}?`,
                            )
                          ) {
                            await api.delete(`/link/${item.id}`)
                            window.location.reload()
                          }
                        }}
                      />
                    </div>
                    <div>
                      <Link
                        to={{
                          pathname: privateRoutePaths.editInsurancePlanLink,
                          state: item,
                        }}
                      >
                        <Icon name="edit" fontSize={16} />
                      </Link>
                    </div>
                    <div>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="eye" fontSize={16} />
                      </a>
                    </div>
                  </MaterialItemActions>
                </MaterialItemContainer>
              ))}
          </div>
        </Material>
      </MaterialsContainer>
    </Container>
  )
}

export default PlanMaterials
