import React, { useCallback, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { AxiosError } from 'axios'

import getValidationErrors from '../../utils/getValidationErrors'
import api from '../../services/api'
import { useToast } from '../../hooks/toast'
import logoImg from '../../assets/logo.png'
import Input from '../../components/Input'
import Button from '../../components/Button'

import { Container, Content, AnimationContainer, Background } from './styles'

interface ForgotPasswordFormData {
  password: string
  confirmPassword: string
}

interface RouteParams {
  token: string
}

const ChangePassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { token } = useParams<RouteParams>()
  const formRef = useRef<FormHandles>(null)
  const { addToast } = useToast()

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      setIsLoading(true)
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
          confirmPassword: Yup.string()
            .min(6, 'No mínimo 6 dígitos')
            .oneOf(
              [Yup.ref('password'), undefined],
              'Confirmação de senha incorreta',
            ),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await api.put(`sessions/passwordRecovery/${token}`, {
          password: data.password,
        })

        addToast({
          type: 'success',
          title: 'Solicitação realizada!',
          description: 'Senha alterada com sucesso! Logue-se.',
        })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao fazer a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast, token],
  )

  return (
    <Container>
      <Background />

      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Texas" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Troca de senha</h1>

            <Input
              name="password"
              type="password"
              icon="lock"
              label="Senha"
              disabled={isLoading}
            />
            <Input
              name="confirmPassword"
              type="password"
              icon="lock"
              label="Confirme sua senha"
              disabled={isLoading}
            />

            <Button type="submit" disabled={isLoading}>
              Trocar Senha
            </Button>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  )
}

export default ChangePassword
