import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from './styles'
import Button from '../Button'
import Lottie, { LottieComponentProps, LottieOptions } from 'lottie-react'

interface EmptyStateProps extends Omit<LottieComponentProps, 'animationData'> {
  image: unknown
  title: string
  desc: string
  goToButton: {
    title: string
    to: string
  }
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  desc,
  goToButton,
  image,
  ...rest
}) => {
  const defaultLottieOptions: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: image,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Container>
      <Lottie {...defaultLottieOptions} {...rest} />

      <div>
        <strong>{title}</strong>
        <p>{desc}</p>
        <Link to={goToButton.to}>
          <Button>{goToButton.title}</Button>
        </Link>
      </div>
    </Container>
  )
}
