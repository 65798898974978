import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 3rem;

  form {
    display: grid;
    row-gap: 1rem;
  }
`

export const AssociationFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1rem;

  div:first-child {
    height: fit-content;
  }
`
