import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-top: 0;
  }
`

export const DigitalPresenceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
`

export const DigitalPresenceItem = styled.div`
  position: relative;

  svg {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    padding: 3px;
    color: var(--white);
    cursor: pointer;
    border: 2px var(--red-500) solid;
    background: var(--red-500);
    border-radius: 16px;
    z-index: 1;

    transition: 0.2s;

    &:hover {
      color: white;
      background: var(--background);
      border-color: var(--red-500);
    }
  }

  button {
    margin-top: 0;
  }
`

export const DigitalPresence = styled.div`
  img {
    width: 100%;
    height: 250px;
    border-radius: 4px;
    object-fit: cover;
  }
`
