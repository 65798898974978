import React, { ReactNode } from 'react'
import ReactModal from 'react-modal'

interface ModalProps {
  children: ReactNode
  modalIsOpen: boolean
  onModalClose: () => void
}

const Modal: React.FC<ModalProps> = ({
  children,
  modalIsOpen,
  onModalClose,
}) => {
  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={onModalClose}
      closeTimeoutMS={300}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc
    >
      {children}
    </ReactModal>
  )
}

export default Modal
