import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Icon } from '../Icon'

import { Container, GoBackContainer, Head, Main } from './styles'

interface HeaderProps {
  image?: string
  title: string
  goBack?: boolean
  description?: string
  navigate?: {
    to: string
    name: string
    state?: any
  }
}

export const HeadTitle: React.FC<HeaderProps> = ({
  image,
  title,
  goBack,
  navigate,
  description,
}) => {
  const history = useHistory()

  return (
    <Container>
      {goBack && (
        <GoBackContainer>
          <button onClick={history.goBack}>
            <Icon name="angle-left" />
            <span>Voltar</span>
          </button>
        </GoBackContainer>
      )}
      <Main>
        <Head>
          {image && <img src={image} alt={title} />}
          <div>
            <h1>{title}</h1>
            {description != null && <p>{description}</p>}
          </div>
        </Head>
        {navigate !== undefined && (
          <Link to={{ pathname: navigate.to, state: navigate.state }}>
            <button>{navigate.name}</button>
          </Link>
        )}
      </Main>
    </Container>
  )
}
