export function normalizerCNPJNumber(cnpj: string): string {
  return cnpj
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function normalizerPhoneNumber(phone: string): string {
  return phone
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2')
}

export function normalizerCEPNumber(cep: string): string {
  return cep
    .replace(/\D+/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

export function normalizerCurrencyNumber(currency: string): string {
  let clearedCurrency = currency.replace(/[^\d]/g, '').replace(/\D/g, '')

  while (clearedCurrency.length < 3) {
    clearedCurrency = '0' + clearedCurrency
  }

  let integerPart = clearedCurrency.slice(0, -2)
  const decimalPart = clearedCurrency.slice(-2)

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  integerPart = integerPart.replace(/^0+/, '')

  const formattedCurrency = `R$ ${integerPart || '0'},${decimalPart}`
  return formattedCurrency
}

export function normalizerANSCode(ans: string): string {
  ans = ans
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d+)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')

  if (ans.length > 10) {
    ans = ans.replace(/(\d{2})(\d+)$/, '$1-$2')
  }

  return ans
}

export function demask(field: string): string {
  return field.replace(/[^0-9]/g, '')
}

export function demaskCurrency(field: string): string {
  return field.replace(/[^\d,]/g, '').replace(',', '.')
}
