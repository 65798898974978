import styled from 'styled-components'
import Button from '../../components/Button'

export const Container = styled.div`
  display: grid;
  row-gap: 3rem;
`

export const ProductsTable = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5625rem;
  list-style: none;
`

const ProductsTableBase = styled.li`
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0 30px;
  gap: 10px;

  div:first-child {
    flex-basis: 10%;
  }

  div:nth-child(2) {
    flex-basis: 25%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div:nth-child(3) {
    flex-basis: 25%;
  }

  div:nth-child(4) {
    flex-basis: 20%;
  }

  div:last-child {
    flex-basis: 20%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    display: block;

    div {
      flex-basis: 100%;
      padding: 10px 0;

      &:before {
        color: #6c7a89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
`

export const ProductsTableHeaderContainer = styled(ProductsTableBase)`
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  background: transparent;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;

  @media (max-width: 767px) {
    display: none;
  }
`

export const ProductsTableBodyContainer = styled.div`
  display: grid;
  gap: 5px;
`

export const ProductsTableItem = styled(ProductsTableBase)`
  background: var(--element);
  height: 60px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 767px) {
    height: unset;
  }
`

export const DeleteActionButton = styled(Button)`
  width: fit-content;
  height: fit-content;
  background: transparent;
  padding: 0;
`

export const EditActionButton = styled(Button)`
  width: 100%;
  color: black;
  height: 32px;
  border-radius: 2px;
  background: var(--green-200);
`

export const PowerButton = styled(Button)`
  width: 100%;
  color: black;
  height: 32px;
  border-radius: 2px;
  background: var(--gray-500);
`
