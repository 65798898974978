import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  font-family: 'montserrat', sans-serif;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
  }
`
