import React, { useEffect, useState } from 'react'
import {
  Container,
  Content,
  NavigateCard,
  NavigateCardIconContainer,
  NavigateCardTitleContainer,
} from './styles'
import { HeadTitle } from '../../components/HeadTitle'
import api from '../../services/api'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Icon } from '../../components/Icon'
import { privateRoutePaths } from '../../routes/private'
import { useToast } from '../../hooks/toast'
import { insuranceRoutePaths } from '../../routes/private/insurances'
import { Plan as PlanProps } from '../../@types/Plan'

const Plan: React.FC = () => {
  const [plan, setPlan] = useState<PlanProps>()
  const [isLoading, setIsLoading] = useState(true)
  const { insuranceId, planId }: { insuranceId: string; planId: string } =
    useParams()
  const { addToast } = useToast()
  const history = useHistory()

  useEffect(() => {
    async function loadPlan(): Promise<void> {
      const response = await api.get(`/plans/${planId}`)

      setPlan(response.data)
      setIsLoading(false)
    }

    loadPlan()
  }, [planId])

  if (isLoading) return <h1>Carregando...</h1>

  if (!isLoading && !plan) {
    addToast({
      type: 'error',
      title: 'Oops!',
      description: 'Plano não encontrado. Tente selecioná-lo novamente.',
    })

    history.push(
      insuranceRoutePaths.insurancePlans.replace(':insuranceId', insuranceId),
    )
  }

  return (
    <Container>
      <HeadTitle
        title={`Plano: ${plan?.name}`}
        description={`Acesse os produtos ou os materiais do plano: ${
          plan!.name
        }`}
        goBack
      />

      <Content>
        <Link
          to={privateRoutePaths.insurancePlanProducts.replace(
            ':planId',
            plan!.id,
          )}
        >
          <NavigateCard>
            <NavigateCardIconContainer>
              <Icon name="boxes" fontSize={24} />
            </NavigateCardIconContainer>

            <NavigateCardTitleContainer>
              <span>Produtos</span>
              <Icon name="angle-right" />
            </NavigateCardTitleContainer>
          </NavigateCard>
        </Link>

        <Link
          to={privateRoutePaths.insurancePlanMaterials.replace(
            ':planId',
            plan!.id,
          )}
        >
          <NavigateCard>
            <NavigateCardIconContainer>
              <Icon name="folder-tree" fontSize={24} />
            </NavigateCardIconContainer>

            <NavigateCardTitleContainer>
              <span>Tabelas e Materiais</span>
              <Icon name="angle-right" />
            </NavigateCardTitleContainer>
          </NavigateCard>
        </Link>

        <Link
          to={privateRoutePaths.insurancePlanTables.replace(
            ':planId',
            plan!.id,
          )}
        >
          <NavigateCard>
            <NavigateCardIconContainer>
              <Icon name="memo-circle-check" fontSize={24} />
            </NavigateCardIconContainer>

            <NavigateCardTitleContainer>
              <span>Tabelas de Valores Personalizadas</span>
              <Icon name="angle-right" />
            </NavigateCardTitleContainer>
          </NavigateCard>
        </Link>
      </Content>
    </Container>
  )
}

export default Plan
