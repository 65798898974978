import { RouterType } from '../../@types/router.types'
import ChangePassword from '../../pages/ChangePassword'
import ForgotPassword from '../../pages/ForgotPassword'
import SignIn from '../../pages/SignIn'
import Simulator from '../../pages/TempPage/Simulator'

export const publicRoutesPath = {
  login: '/',
  recoverPassword: '/recuperar-senha',
  recoverCompletion: '/alterar-senha/:token',
}

export const publicRoutes: RouterType[] = [
  //PUBLIC PAGES
  {
    path: publicRoutesPath.login,
    component: SignIn,
    title: 'login',
  },
  {
    path: publicRoutesPath.recoverPassword,
    component: ForgotPassword,
    title: 'forgot-password',
  },
  {
    path: publicRoutesPath.recoverCompletion,
    component: ChangePassword,
    title: 'recover-password',
  },
  //TEMP PAGES
  {
    path: '/simulador-cmt',
    component: Simulator,
    title: 'simulator',
  },
]
