import React, { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'

import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import { useToast } from '../../hooks/toast'
import appLogo from '../../assets/logo.png'

import InputUpload from '../../components/InputUpload'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { HeadTitle } from '../../components/HeadTitle'
import { Icon } from '../../components/Icon'
import StepModal from './components/StepModal'
import RichTextArea from '../../components/RichTextArea'

import {
  Container,
  Content,
  LabelButton,
  PlanForm,
  PlanImageContainer,
  PlanImageContentContainer,
  PlanInfoContainer,
  PlanInfoFormContainer,
} from './styles'

interface PlanTypesCustomer {
  id: string
  active: boolean
  plan_type_id: string
  name: string
  description: string
  updated_at: Date
  created_at: Date
}

export enum ModalType {
  validity = 'validity',
  importantNotes = 'important_notes',
  differentials = 'differentials',
  salesAndServiceAreas = 'sales_and_service_areas',
  gracePeriod = 'grace_period',
  neededDocs = 'needed_docs',
}

interface PlanFormData {
  plan_type_customer_id: string
  name: string
  description: string
  image: string
  admin_image: string
  [ModalType.validity]: string
  [ModalType.importantNotes]: string
  [ModalType.differentials]: string
  [ModalType.salesAndServiceAreas]: string
  [ModalType.gracePeriod]: string
  [ModalType.neededDocs]: string
}

export interface StateFormData
  extends Omit<
    PlanFormData,
    'plan_type_customer_id' | 'name' | 'description' | 'image'
  > {}

const CreatePlan: React.FC = () => {
  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [adminImagePreview, setAdminImagePreview] = useState<string | null>()
  const [currentModal, setCurrentModal] = useState<ModalType | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({} as StateFormData)
  const { state }: { state: PlanTypesCustomer } = useLocation()
  const planTypeCustomer = { ...state }
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { addToast } = useToast()

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (imagePreview) URL.revokeObjectURL(imagePreview)
    const selectedFile = e.target.files![0]
    setImagePreview(URL.createObjectURL(selectedFile))
  }

  const handleAdminImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (adminImagePreview) URL.revokeObjectURL(adminImagePreview)
    const selectedFile = e.target.files![0]
    setAdminImagePreview(URL.createObjectURL(selectedFile))
  }

  const handleRemoveImagePreview = (): void => {
    if (imagePreview) URL.revokeObjectURL(imagePreview)
    setImagePreview(null)
    formRef.current?.clearField('image')
  }

  const handleRemoveAdminImagePreview = (): void => {
    if (adminImagePreview) URL.revokeObjectURL(adminImagePreview)
    setAdminImagePreview(null)
    formRef.current?.clearField('admin_image')
  }

  const handleSubmit = useCallback(
    async ({
      plan_type_customer_id,
      name,
      description,
      image,
      admin_image,
    }: PlanFormData) => {
      setIsLoading(true)
      try {
        formRef.current?.setErrors({})

        const data = {
          ...formData,
          plan_type_customer_id,
          name,
          description,
          image,
          admin_image,
        }

        if (!imagePreview) {
          addToast({
            type: 'error',
            title: 'Oops!',
            description: 'A imagem do plano é obrigatória.',
          })
        }

        const schema = Yup.object().shape({
          plan_type_customer_id: Yup.string().required(''),
          name: Yup.string().required('Título obrigatório'),
          description: Yup.string().optional(),
          image: Yup.string().required('Anexe a logo do plano'),
          admin_image: Yup.string().optional(),
          validity: Yup.string().optional(),
          important_notes: Yup.string().optional(),
          grace_period: Yup.string().optional(),
          needed_docs: Yup.string().optional(),
          differentials: Yup.string().optional(),
          sales_and_service_areas: Yup.string().optional(),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newData = new FormData()

        newData.append('plan_type_customer_id', data.plan_type_customer_id)
        newData.append('name', data.name)
        newData.append('description', data.description)
        newData.append('validity', data.validity)
        newData.append('important_notes', data.important_notes)
        newData.append('differentials', data.differentials)
        newData.append('sales_and_service_areas', data.sales_and_service_areas)
        newData.append('grace_period', data.grace_period)
        newData.append('needed_docs', data.needed_docs)
        newData.append('image', data.image)
        newData.append('admin_image', data.admin_image)

        await api.post('/plans', newData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        addToast({
          type: 'success',
          title: 'Cadastrado com sucesso!',
          description: 'Plano cadastrado com sucesso.',
        })

        history.goBack()
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast, history, imagePreview, formData],
  )

  const handleModalOpen = (modalToOpen: ModalType) => {
    setCurrentModal(modalToOpen)
  }

  const handleModalClose = () => {
    setCurrentModal(null)
  }

  const handleInputChange = (input: ModalType, value: string) => {
    setFormData(currentData => ({ ...currentData, [input]: value }))
  }

  return (
    <Container>
      <HeadTitle
        title="Cadastrando novo plano"
        description="Preencha todas as informações corretamente, confira tudo antes de continuar."
        goBack
      />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          plan_type_customer_id: planTypeCustomer.id,
        }}
      >
        <Content>
          <PlanInfoContainer>
            <PlanImageContentContainer>
              <p>Operadora</p>
              <PlanImageContainer>
                {imagePreview && (
                  <Button onClick={handleRemoveImagePreview}>
                    <Icon name="cross" fontSize={24} />
                  </Button>
                )}
                <img
                  src={imagePreview ?? appLogo}
                  alt={planTypeCustomer.name}
                />
                <LabelButton htmlFor="image">
                  <Icon name="pencil" fontSize={24} color="#fff" />
                </LabelButton>
              </PlanImageContainer>
            </PlanImageContentContainer>
            {planTypeCustomer.name.includes('Adesão') && (
              <PlanImageContentContainer>
                <p>Administradora</p>
                <PlanImageContainer>
                  {adminImagePreview && (
                    <Button onClick={handleRemoveAdminImagePreview}>
                      <Icon name="cross" fontSize={24} />
                    </Button>
                  )}
                  <img
                    src={adminImagePreview ?? appLogo}
                    alt={planTypeCustomer.name}
                  />
                  <LabelButton htmlFor="admin_image">
                    <Icon name="pencil" fontSize={24} color="#fff" />
                  </LabelButton>
                </PlanImageContainer>
              </PlanImageContentContainer>
            )}

            <PlanInfoFormContainer>
              <span>
                Tipo: <strong>{planTypeCustomer.name}</strong>
              </span>

              <Input
                name="plan_type_customer_id"
                label="Nome"
                visible={false}
                disabled
              />
              <Input
                name="name"
                label="Nome da Operadora"
                disabled={isLoading}
              />
              <Input
                name="description"
                label="Descrição"
                disabled={isLoading}
              />
              <InputUpload
                name="image"
                onInputChange={handleImageChange}
                placeholder="Imagem"
                accept="image/*"
                visible={false}
                disabled={isLoading}
              />
              <InputUpload
                name="admin_image"
                onInputChange={handleAdminImageChange}
                placeholder="Imagem"
                accept="image/*"
                visible={false}
                disabled={isLoading}
              />
            </PlanInfoFormContainer>
          </PlanInfoContainer>
          <PlanForm>
            <StepModal
              name={ModalType.validity}
              title="Vigência da Tabela"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.validity]}
              isInvalid={!!formRef.current?.getFieldError(ModalType.validity)}
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <Input
                name={ModalType.validity}
                type="month"
                label="Vigência"
                value={formData[ModalType.validity] ?? ''}
                disabled={isLoading}
                keepDirty
                onChange={e =>
                  handleInputChange(ModalType.validity, e.target.value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.importantNotes}
              title="Observações Importantes"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.importantNotes]}
              isInvalid={
                !!formRef.current?.getFieldError(ModalType.importantNotes)
              }
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.importantNotes}
                value={formData[ModalType.importantNotes] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.importantNotes, value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.gracePeriod}
              title="Carência"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.gracePeriod]}
              isInvalid={
                !!formRef.current?.getFieldError(ModalType.gracePeriod)
              }
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.gracePeriod}
                value={formData[ModalType.gracePeriod] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.gracePeriod, value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.neededDocs}
              title="Documentação necessária"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.neededDocs]}
              isInvalid={!!formRef.current?.getFieldError(ModalType.neededDocs)}
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.neededDocs}
                value={formData[ModalType.neededDocs] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.neededDocs, value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.differentials}
              title="Diferenciais"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.differentials]}
              isInvalid={
                !!formRef.current?.getFieldError(ModalType.differentials)
              }
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.differentials}
                value={formData[ModalType.differentials] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.differentials, value)
                }
              />
            </StepModal>

            <StepModal
              name={ModalType.salesAndServiceAreas}
              title="Área de comercialização e atendimento"
              currentModal={currentModal}
              isFilled={!!formData[ModalType.salesAndServiceAreas]}
              isInvalid={
                !!formRef.current?.getFieldError(ModalType.salesAndServiceAreas)
              }
              onModalOpen={handleModalOpen}
              onModalClose={handleModalClose}
            >
              <RichTextArea
                name={ModalType.salesAndServiceAreas}
                value={formData[ModalType.salesAndServiceAreas] ?? ''}
                disabled={isLoading}
                onEditorChange={value =>
                  handleInputChange(ModalType.salesAndServiceAreas, value)
                }
              />
            </StepModal>
          </PlanForm>
          <Button type="submit" disabled={isLoading}>
            Cadastrar
          </Button>
        </Content>
      </Form>
    </Container>
  )
}

export default CreatePlan
