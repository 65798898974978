import React from 'react'
import { Switch, Route } from 'react-router-dom'

import CustomRoute from './Route'
import { publicRoutes } from './public'
import { privateRoutes } from './private'
import { RouterType } from '../@types/router.types'
import { EmptyState } from '../components/EmptyState'
import error404 from '../assets/404.json'

const pageRoutes = [...publicRoutes, ...privateRoutes].map(
  ({ path, component, isPrivate, hasSideAndNavbar }: RouterType) => (
    <CustomRoute
      exact
      key="route"
      path={path}
      component={component}
      isPrivate={isPrivate}
      hasSideAndNavbar={hasSideAndNavbar}
    />
  ),
)

const Routes: React.FC = () => {
  return (
    <Switch>
      {pageRoutes}
      <Route path="*">
        <EmptyState
          image={error404}
          title="Página não encontrada."
          desc="Não fique por ai, perdido! Volte para o início clicando no botão abaixo."
          style={{ maxHeight: '500px', margin: '32px 0' }}
          goToButton={{
            title: 'Voltar para o início',
            to: '/',
          }}
        />
      </Route>
    </Switch>
  )
}

export default Routes
