import React from 'react'
import { Container, Data } from './styles'
import { Skeleton } from '../../Skeleton'

interface NCardProps {
  title: string
  data: number
  dataLoading: boolean
}

export const NCard: React.FC<NCardProps> = ({ title, data, dataLoading }) => {
  if (dataLoading) {
    return (
      <Container>
        <Skeleton width="100%" height="55">
          <rect y="0" rx="10" ry="10" width="100%" height="20" />
          <rect y="30" rx="5" ry="5" width="50%" height="25" />
        </Skeleton>
      </Container>
    )
  }

  return (
    <Container>
      <p>{title}</p>
      <Data>{data}</Data>
    </Container>
  )
}
