import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useField } from '@unform/core'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'

import { Icon } from '../Icon'

import { Container, SelectContainer, Error } from './styles'

interface SelectProps extends ReactSelectProps<OptionProps, boolean, any> {
  name: string
  label: string
  icon?: string
}

interface OptionProps {
  value: string | number
  label: string
}

const Select: React.FC<SelectProps> = ({ name, label, icon, ...rest }) => {
  const inputRef = useRef<any>(null)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const { fieldName, error, defaultValue, registerField, clearError } =
    useField(name)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)

    clearError()
  }, [clearError])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    setIsFilled(inputRef.current?.state.selectValue.length !== 0)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (ref.state.selectValue.length === 0) {
            return []
          }

          return ref.state.selectValue.map((opt: OptionProps) => opt.value)
        } else {
          if (ref.state.selectValue.length === 0) {
            return ''
          }

          return ref.state.selectValue[0].value
        }
      },
    })
  }, [fieldName, registerField, rest.isMulti])

  useEffect(() => {
    if (inputRef.current.state.selectValue) {
      setIsFilled(inputRef.current.state.selectValue.length !== 0)
    }
  }, [])

  return (
    <Container
      isFocused={isFocused}
      isInvalid={!!error}
      onClick={() => inputRef.current?.focus()}
    >
      <SelectContainer
        hasIcon={!!icon}
        hasLabel={!!label}
        isDirty={isFilled}
        isFocused={isFocused}
      >
        {icon && <Icon name={icon} fontSize={16} />}
        <ReactSelect
          id={fieldName}
          ref={inputRef}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder=""
          openMenuOnFocus
          closeMenuOnSelect
          defaultValue={defaultValue}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
        />
        <label htmlFor={fieldName}>{label}</label>

        {error && (
          <Error title={error}>
            <Icon name="exclamation" fontSize={16} />
          </Error>
        )}
      </SelectContainer>
    </Container>
  )
}

export default Select
