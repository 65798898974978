import { RouterType } from '../../../@types/router.types'
import CreatePlan from '../../../pages/CreatePlan'
import EditPlan from '../../../pages/EditPlan'
import EditPlanItem from '../../../pages/EditPlanItem'
import EditPlanLink from '../../../pages/EditPlanLink'

import Insurances from '../../../pages/Insurances'
import PlanType from '../../../pages/PlanType'
import Plan from '../../../pages/Plan'
import PlanMaterials from '../../../pages/PlanMaterials'
import PlanProducts from '../../../pages/PlanProducts'
import CreateProduct from '../../../pages/PlanProducts/Create'
import EditProduct from '../../../pages/PlanProducts/Edit'
import PlanTables from '../../../pages/PlanTables'
import { CreatePlanTable } from '../../../pages/PlanTables/Create'
import { EditPlanTable } from '../../../pages/PlanTables/Edit'

export const insuranceRoutePaths = {
  insurances: '/seguros',
  insurancePlans: '/seguros/:insuranceId/planos',
  createInsurancePlan: '/seguros/planos/criar',
  editInsurancePlan: '/seguros/planos/editar',
  insurancePlan: '/seguros/:insuranceId/planos/:planId',
  insurancePlanProducts: '/seguros/planos/:planId/produtos',
  createInsurancePlanProduct: '/seguros/planos/:planId/produtos/criar',
  editInsurancePlanProduct:
    '/seguros/planos/:planId/produtos/editar/:productId',
  insurancePlanMaterials: '/seguros/planos/:planId/materiais',
  editInsurancePlanFile: '/seguros/planos/editar/arquivo',
  editInsurancePlanLink: '/seguros/planos/editar/link',
  insurancePlanTables: '/seguros/planos/:planId/tabelas',
  createInsurancePlanTable: '/seguros/planos/:planId/tabelas/criar',
  editInsurancePlanTable: '/seguros/planos/:planId/tabelas/editar/:tableId',
}

export const insuranceRoutes: RouterType[] = [
  {
    path: insuranceRoutePaths.insurances,
    component: Insurances,
    title: 'insurances',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.insurancePlans,
    component: PlanType,
    title: 'insurance-plans',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.createInsurancePlan,
    component: CreatePlan,
    title: 'create-insurance-plan',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.editInsurancePlan,
    component: EditPlan,
    title: 'edit-insurance-plan',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.insurancePlan,
    component: Plan,
    title: 'insurance-plan',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.insurancePlanProducts,
    component: PlanProducts,
    title: 'insurance-plan-products',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.createInsurancePlanProduct,
    component: CreateProduct,
    title: 'create-insurance-plan-product',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.editInsurancePlanProduct,
    component: EditProduct,
    title: 'edit-insurance-plan-product',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.insurancePlanMaterials,
    component: PlanMaterials,
    title: 'insurance-plan-materials',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.editInsurancePlanFile,
    component: EditPlanItem,
    title: 'edit-insurance-plan-file',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.editInsurancePlanLink,
    component: EditPlanLink,
    title: 'edit-insurance-plan-link',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.insurancePlanTables,
    component: PlanTables,
    title: 'insurance-plan-tables',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.createInsurancePlanTable,
    component: CreatePlanTable,
    title: 'create-insurance-plan-table',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: insuranceRoutePaths.editInsurancePlanTable,
    component: EditPlanTable,
    title: 'edit-insurance-plan-table',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
]
