import React from 'react'
import {
  TableNameContainer,
  TableProductContainer,
  TableProductsContainer,
  Container,
  Divider,
  EditTableButton,
  DeleteTableButton,
} from './styles'
import { Icon } from '../../../../components/Icon'
import Button from '../../../../components/Button'
import { privateRoutePaths } from '../../../../routes/private'

interface CustomTableCardProps {
  tableId: string
  planId: string
  name: string
  products: string[]
  onDeleteTable: (tableId: string) => void
}

const CustomTableCard: React.FC<CustomTableCardProps> = ({
  tableId,
  planId,
  name,
  products,
  onDeleteTable,
}) => {
  return (
    <Container>
      <EditTableButton
        to={privateRoutePaths.editInsurancePlanTable
          .replace(':planId', planId)
          .replace(':tableId', tableId)}
        title="Adicione produtos a essa tabela"
      >
        <Button>
          <Icon name="plus" color="#fff" fontSize={12} />
        </Button>
      </EditTableButton>
      <DeleteTableButton onClick={() => onDeleteTable(tableId)}>
        <Icon name="trash" color="#fff" fontSize={12} />
      </DeleteTableButton>
      <TableNameContainer>
        <Icon name="id-card-clip-alt" fontSize={20} />
        <span>{name}</span>
      </TableNameContainer>
      <Divider />
      <TableProductsContainer>
        {products.map(pf => (
          <TableProductContainer key={pf}>{pf}</TableProductContainer>
        ))}
      </TableProductsContainer>
    </Container>
  )
}
export default CustomTableCard
