import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react'

import { HeadTitle } from '../../components/HeadTitle'
import api from '../../services/api'
import emptyMarketingGroupsImage from '../../assets/empty-marketing-groups.json'
import emptyMarketingGroupImage from '../../assets/empty-marketing-group.json'
import { EmptyState } from '../../components/EmptyState'
import { privateRoutePaths } from '../../routes/private'
import { AxiosError } from 'axios'
import { useToast } from '../../hooks/toast'
import { Icon } from '../../components/Icon'

import {
  Container,
  Content,
  DigitalPresenceActions,
  DigitalPresenceContainer,
  DigitalPresenceEmpty,
  DigitalPresenceHead,
  DigitalPresenceImage,
  DigitalPresenceImageContainer,
  DigitalPresenceTitle,
} from './style'

type DigitalPresenceProps = {
  id: string
  name: string
  description: string
  digital_presence: {
    id: string
    image: {
      path: string
    }
  }[]
}

const DigitalPresenceGroups: React.FC = () => {
  const [isLoading, setIsLoading] = useState({
    request: false,
    page: true,
  })
  const [digitalPresenceGroup, setDigitalPresenteGroup] = useState<
    DigitalPresenceProps[]
  >([])
  const { addToast } = useToast()

  useEffect(() => {
    async function loadDigitalPresenceGroup(): Promise<void> {
      const response = await api.get('/digital-presence-group')

      setDigitalPresenteGroup(response.data)
      setIsLoading(curIsLoading => ({ ...curIsLoading, page: false }))
    }

    loadDigitalPresenceGroup()
  }, [])

  const deleteDigitalPresenceGroup = useCallback(
    async (id: string) => {
      if (
        window.confirm(
          'Deseja realmente excluir? Todas os cards desse grupo também serão excluídos.',
        )
      ) {
        setIsLoading(curIsLoading => ({ ...curIsLoading, request: true }))
        try {
          await api.delete(`/digital-presence-group/${id}`)

          setDigitalPresenteGroup(
            digitalPresenceGroup?.filter(dpg => dpg.id !== id),
          )

          addToast({
            type: 'success',
            title: 'Êxito!',
            description: 'Grupo excluido com sucesso.',
          })
        } catch (err) {
          const error = err as AxiosError<{ message: string }>
          addToast({
            type: 'error',
            title: 'Oops!',
            description:
              error.response?.data.message ??
              'Algo deu errado, tente novamente.',
          })
        } finally {
          setIsLoading(curIsLoading => ({ ...curIsLoading, request: false }))
        }
      }
    },
    [digitalPresenceGroup, addToast],
  )

  if (isLoading.page) return <p>Carregando...</p>

  if (digitalPresenceGroup.length === 0) {
    return (
      <EmptyState
        image={emptyMarketingGroupsImage}
        style={{
          maxHeight: '500px',
          margin: '-48px 0 -70px',
        }}
        title="Crie grupos de presença digital."
        desc="Ajude seus corretores à alcançar possíveis clientes digulgando com as presenças digitais!"
        goToButton={{
          title: 'Crie um novo grupo de presença digital',
          to: privateRoutePaths.createDigitalPresenceGroup,
        }}
      />
    )
  }

  return (
    <Container>
      <HeadTitle
        title="Presença Digital"
        navigate={{
          name: 'Criar novo grupo',
          to: privateRoutePaths.createDigitalPresenceGroup,
        }}
      />

      <Content>
        {digitalPresenceGroup.map(dpg => (
          <DigitalPresenceContainer key={dpg.id}>
            <DigitalPresenceHead>
              <DigitalPresenceTitle>
                <span>{dpg.name}</span>
              </DigitalPresenceTitle>
              <DigitalPresenceActions>
                <Icon
                  name="trash"
                  color="#ff0000"
                  title={`Deletar ${dpg.name}`}
                  onClick={() => deleteDigitalPresenceGroup(dpg.id)}
                />
                {dpg.digital_presence.length > 0 && (
                  <Link
                    to={{
                      pathname: privateRoutePaths.digitalPresenceGroup,
                      state: { ...dpg },
                    }}
                  >
                    <Icon
                      name="eye"
                      color="gray"
                      title={`Visualizar ${dpg.name}`}
                    />
                  </Link>
                )}
              </DigitalPresenceActions>
            </DigitalPresenceHead>
            <DigitalPresenceImageContainer>
              {dpg.digital_presence.length > 0 ? (
                dpg.digital_presence.slice(0, 4).map(dp => {
                  return (
                    <DigitalPresenceImage key={dp.id} src={dp.image.path} />
                  )
                })
              ) : (
                <Link
                  key={dpg.id}
                  to={{
                    pathname: privateRoutePaths.createDigitalPresenceImage,
                    state: { ...dpg },
                  }}
                >
                  <DigitalPresenceEmpty>
                    <p>Nenhum card cadastrado.</p>
                    <Lottie
                      animationData={emptyMarketingGroupImage}
                      style={{ maxHeight: '200px', marginLeft: '30px' }}
                    />
                    <p>
                      Clique aqui para cadastrar o primeiro card de {dpg.name}.
                    </p>
                  </DigitalPresenceEmpty>
                </Link>
              )}
            </DigitalPresenceImageContainer>
          </DigitalPresenceContainer>
        ))}
      </Content>
    </Container>
  )
}

export default DigitalPresenceGroups
