import styled from 'styled-components'
import Button from '../../../components/Button'

export const Body = styled.div`
  color: #52475c;
`

export const Header = styled.div`
  background: #022949;
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    color: #fa8101;
    font-size: 48px;
    font-weight: 800;
    width: 10ch;
  }

  p {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    width: 20ch;
  }

  div:last-child {
    display: flex;
    align-items: center;
    gap: 3rem;

    img {
      height: 100%;
    }
  }

  @media (max-width: 850px) {
    gap: 1.5625rem;
    height: 120px;
    padding: 2rem;
    justify-content: space-around;

    div:last-child {
      gap: 1.5rem;

      img {
        width: 200px;
      }

      img:first-child {
        display: none;
      }
    }

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 12px;
    }
  }

  @media (max-width: 360px) {
    div:last-child {
      img {
        width: 100px;
      }
    }
  }
`

export const Container = styled.div`
  display: grid;
  row-gap: 3rem;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 2rem;
  max-width: 1200px;

  form {
    display: grid;
    row-gap: 3rem;

    div {
      border-color: #00c6ff;
    }
  }

  @media (max-width: 400px) {
    padding: 0.5rem;
  }
`

export const Title = styled.p`
  font-size: 25px;
  font-weight: 500;
  max-width: 37ch;
`

export const Info = styled.p`
  span {
    color: red;
    font-weight: 700;
  }
`

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;

  @media (max-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`

export const ValuesContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

const BaseValueContainer = styled.div`
  display: grid;
  row-gap: 0.1rem;
  padding: 1.125rem 1.4375rem;

  p {
    max-width: 15ch;
  }

  h1 {
    font-size: 32px;
    font-weight: 800;
  }
`

export const TotalValueContainer = styled(BaseValueContainer)`
  background: #022949;
  color: white;

  h1 {
    color: #00c6ff;
  }
`

export const DependentsValueContainer = styled(BaseValueContainer)`
  background: #d9d9d9;

  h1 {
    color: #52475c;
  }
`

export const ValuesDescription = styled.div`
  display: grid;
  row-gap: 1.4375rem;

  div {
    display: grid;
    row-gap: 0.3rem;
  }
`

export const OwnerPrice = styled.p`
  span {
    text-decoration: line-through;
    color: red;

    &:last-child {
      text-decoration: none;
      color: green;
    }
  }
`

export const Footer = styled.div`
  display: grid;
  justify-content: flex-end;
  row-gap: 0.6875rem;

  img {
    zoom: 0.06;
  }

  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const SimulatorButton = styled(Button)`
  background: linear-gradient(90deg, #022949 0%, #00c6ff 114.63%);
`
