import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import { useField } from '@unform/core'

import { Icon } from '../Icon'

import {
  Container,
  InputContainer,
  Error,
  InputLabel,
  SpanColor,
  LabelColor,
  InputColorContainer,
} from './styles'
import { useTheme } from '../../hooks/theme'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  icon?: string
  visible?: boolean
  keepDirty?: boolean
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  icon,
  visible = true,
  type,
  keepDirty = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { theme } = useTheme()

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(keepDirty)

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name)

  const handleInputFocus = useCallback(() => {
    clearError()
    setIsFocused(true)
  }, [clearError])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    setIsFilled(!!inputRef.current?.value)
  }, [inputRef.current?.value])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  useEffect(() => {
    if (inputRef.current?.value !== '') setIsFilled(true)
  }, [inputRef])

  return (
    <Container
      isFocused={isFocused}
      isInvalid={!!error}
      isVisible={visible}
      onClick={() => inputRef.current?.focus()}
    >
      <InputContainer
        isDirty={isFilled}
        isFocused={isFocused}
        currentTheme={theme}
      >
        {label && (
          <InputLabel
            isDirty={isFilled}
            isFocused={isFocused}
            isRange={type === 'range'}
            hasIcon={!!icon}
          >
            <span>{label}</span>
            {type === 'range' && <span>{inputRef.current?.value}px</span>}
          </InputLabel>
        )}
        {icon && <Icon name={icon} fontSize={16} />}

        <input
          defaultValue={defaultValue}
          ref={inputRef}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          type={type}
          id={fieldName}
          {...rest}
        />

        {type === 'color' && (
          <InputColorContainer>
            <SpanColor>{inputRef.current?.value}</SpanColor>
            <LabelColor htmlFor={fieldName}>
              <Icon name="fill" fontSize={14} />
            </LabelColor>
          </InputColorContainer>
        )}

        {error && (
          <Error title={error}>
            <Icon name="exclamation" fontSize={16} />
          </Error>
        )}
      </InputContainer>
    </Container>
  )
}

export default Input
