import { RouterType } from '../../../@types/router.types'
import CreateNotification from '../../../pages/CreateNotification'
import Notification from '../../../pages/Notification'
import Notifications from '../../../pages/Notifications'

export const notificationRoutePaths = {
  notifications: '/informativos',
  notification: '/informativos/visualizar',
  createNotification: '/informativos/criar',
}

export const notificationRoutes: RouterType[] = [
  {
    path: notificationRoutePaths.notifications,
    component: Notifications,
    title: 'notifications',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: notificationRoutePaths.notification,
    component: Notification,
    title: 'notification',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: notificationRoutePaths.createNotification,
    component: CreateNotification,
    title: 'create-notification',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
]
