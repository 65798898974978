import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { AxiosError } from 'axios'

import api from '../../../services/api'
import Select from '../../../components/Select'
import { useToast } from '../../../hooks/toast'
import getValidationErrors from '../../../utils/getValidationErrors'
import { File } from '../../../@types/File'

import { Container, TableForm, Button } from './styles'
import { PlanType } from '../../../@types/PlanType'

interface SelectProps {
  value: string
  label: string
}

interface InsuranceToActive {
  id: string
  active: boolean
  image_path: string
  name: string
  image: File
  updated_at: Date
  created_at: Date
}

type FormDataProps = {
  insurance: string
}

interface CreateInsuranceProps {
  planTypes: PlanType[]
  onActiveInsurance: (insurance: PlanType) => void
}

const CreateInsurance: React.FC<CreateInsuranceProps> = ({
  planTypes,
  onActiveInsurance,
}) => {
  const [insurances, setInsurances] = useState<SelectProps[]>([])
  const [selectedInsurance, setSelectedInsurance] =
    useState<SelectProps | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const formRef = useRef<FormHandles>(null)
  const { addToast } = useToast()

  useEffect(() => {
    const getInsurances = async () => {
      const availableInsurancesToActive = await api.get<InsuranceToActive[]>(
        '/plans-types-list',
      )

      setInsurances(
        availableInsurancesToActive.data
          .filter(aita => !planTypes.some(iaa => iaa.name === aita.name))
          .map(data => ({
            value: data.id,
            label: data.name,
          })),
      )
      setIsLoading(false)
    }

    getInsurances()
  }, [planTypes])

  const handleSubmit = useCallback(
    async (data: FormDataProps) => {
      setIsLoading(true)
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          insurance: Yup.string()
            .length(36)
            .required('Selecione um seguro para ativar.'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newInsurance = await api.post<PlanType>('/plan-types', data)

        addToast({
          type: 'success',
          title: 'Cadastrado com sucesso!',
          description: 'Seguro cadastrado com sucesso.',
        })

        onActiveInsurance(newInsurance.data)
        setSelectedInsurance(null)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast, onActiveInsurance],
  )

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <TableForm>
          <Select
            name="insurance"
            label="Selecione o seguro que deseja ativar"
            onChange={e => setSelectedInsurance(e as SelectProps)}
            value={selectedInsurance}
            options={insurances}
          />

          <Button type="submit" disabled={isLoading}>
            Ativar seguro
          </Button>
        </TableForm>
      </Form>
    </Container>
  )
}

export default CreateInsurance
