import styled, { css } from 'styled-components'

interface IconProps {
  colorTheme: boolean
  fontSize?: number
  color?: string
}

export const IconContent = styled.i<IconProps>`
  color: var(--text);
  transition: all 0.4s ease;
  display: block;

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px !important;
      max-width: ${fontSize}px !important;
      max-height: ${fontSize}px !important;
    `}

  ${({ colorTheme }) =>
    colorTheme &&
    css`
      background: var(--card-icon-color);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`
