import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 3rem;
`

export const CustomTableContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: 500px) {
    grid-template-columns: unset;
  }
`
