import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const DigitalPresenceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`

export const DigitalPresenceFormContainer = styled.div`
  min-width: 22rem;
  position: relative;

  form {
    display: grid;
    row-gap: 1rem;
    position: sticky;
    top: 100px;
  }
`

export const DigitalPresenceFormFontContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`

export const ImagePreviewContainer = styled.div`
  position: relative;
  overflow: hidden;
`

export const ImagePreview = styled.img`
  width: 100%;
`

interface TextPreviewProps {
  x: string | null
  y: string | null
  fontSize: string | null
  fontColor: string | null
  imageContainerWidth?: number
  imageWidth?: number
}

export const TextPreview = styled.span<TextPreviewProps>`
  position: absolute;
  min-width: max-content;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  font-size: ${props => props.fontSize}px;
  font-weight: 700;
  color: ${props => props.fontColor};

  ${({ imageContainerWidth, imageWidth }) =>
    imageContainerWidth &&
    css`
      zoom: calc(${imageContainerWidth} / ${imageWidth});
    `}
`
