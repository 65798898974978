import styled from 'styled-components'

interface NotificationProps {
  priority: boolean
}

export const Container = styled.div`
  display: grid;
  row-gap: 2rem;
`

export const NoficationsContainer = styled.div`
  display: grid;
  row-gap: 1rem;
`

export const Notification = styled.div`
  display: flex;
  padding: 0 20px;
  width: 100%;
  height: 42px;
  border-radius: 8px;
  background: var(--element);
  align-items: center;
  justify-content: space-between;
  transition: 0.2s;
`

export const NotificationContent = styled.div<NotificationProps>`
  display: flex;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 15px;
    background-color: ${props => (props.priority ? '#FF6B00' : '#54CC84')};
  }
`

export const NotificationActions = styled.div`
  display: flex;
  gap: 1rem;

  a {
    text-decoration: none;
  }

  i {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
`
