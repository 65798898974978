export const defaultTheme = {
  background: '#F1F1F1',
  element: '#FFFFFF',
  titles: '#52475C',
  cardTitleColor: '#7032FF',
  text: '#52475C',
  icons: '#52475C',
  variant1: '#F1F1F1',
  variant2: '#F1F1F1',
  variant3: '#7032FF',
  variant4: '#2266AA'
}
