import { RouterType } from '../../@types/router.types'
import Campaigns from '../../pages/Campaigns'
import Dashboard from '../../pages/Dashboard'
import Profile from '../../pages/Profile'
import {
  digitalPresenceRoutePaths,
  digitalPresenceRoutes,
} from './digital-presence'
import { insuranceRoutePaths, insuranceRoutes } from './insurances'
import { notificationRoutePaths, notificationRoutes } from './notifications'
import { quoteRoutes, quoteRoutesPath } from './quote'

export const privateRoutePaths = {
  home: '/dashboard',
  profile: '/perfil',
  campaigns: '/admin/campanhas',
  ...notificationRoutePaths,
  ...insuranceRoutePaths,
  ...digitalPresenceRoutePaths,
  ...quoteRoutesPath,
}

export const privateRoutes: RouterType[] = [
  {
    path: privateRoutePaths.home,
    component: Dashboard,
    title: 'home',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: privateRoutePaths.profile,
    component: Profile,
    title: 'profile',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: privateRoutePaths.campaigns,
    title: 'campaigns',
    component: Campaigns,
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  ...notificationRoutes,
  ...insuranceRoutes,
  ...digitalPresenceRoutes,
  ...quoteRoutes,
]
