import React, { useEffect, useState } from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'

import api from '../../../services/api'
import { useAuth } from '../../../hooks/auth'
import { useTheme } from '../../../hooks/theme'
import { Skeleton } from '../../Skeleton'

import { FilterContainer, Container, Main, ChartContainer } from './styles'

interface Response {
  name: string
  total: number
}

export const AccessesChart: React.FC = () => {
  const [summaryData, setSummaryData] = useState<Response[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [summaryFilter, setSummaryFilter] =
    useState<'monthly' | 'daily'>('daily')
  const { theme } = useTheme()

  const { company } = useAuth()

  useEffect(() => {
    const getSummary = async (): Promise<void> => {
      const response = await api.get<Response[]>(
        `/summary/${summaryFilter}/${company.id}`,
      )
      response.data.sort(
        (a, b) => Number(a.name.split(', ')[1]) - Number(b.name.split(', ')[1]),
      )
      setSummaryData(response.data)
      setIsLoading(false)
    }
    getSummary()
  }, [summaryFilter, company.id])

  function setFilter(filter: 'monthly' | 'daily'): void {
    setIsLoading(true)
    setSummaryFilter(filter)
  }

  if (isLoading) {
    return (
      <Container>
        <Main>
          <p>Total de acessos</p>
          <FilterContainer>
            <input
              type="radio"
              id="daily"
              name="filter"
              onClick={() => setFilter('daily')}
              defaultChecked={true}
            />
            <label htmlFor="daily">Diário</label>
            <input
              type="radio"
              id="monthly"
              name="filter"
              onClick={() => setFilter('monthly')}
            />
            <label htmlFor="monthly">Mensal</label>
          </FilterContainer>
        </Main>
        <div style={{ textAlign: 'center' }}>
          <Skeleton width="95%" height="246">
            <rect rx="5" ry="5" width="100%" height="240" />
          </Skeleton>
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <Main>
        <p>Total de acessos</p>
        <FilterContainer>
          <input
            type="radio"
            id="daily"
            name="filter"
            onClick={() => setFilter('daily')}
            defaultChecked={true}
          />
          <label htmlFor="daily">Diário</label>
          <input
            type="radio"
            id="monthly"
            name="filter"
            onClick={() => setFilter('monthly')}
          />
          <label htmlFor="monthly">Mensal</label>
        </FilterContainer>
      </Main>
      <ChartContainer>
        <LineChart width={700} height={250} data={summaryData}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" stroke={theme ? '#fff' : '#52475c'} />
          <YAxis stroke={theme ? '#fff' : '#52475c'} />
          <Tooltip wrapperStyle={{ color: 'black' }} />
          <Line
            isAnimationActive={false}
            dot={{ stroke: '#00FF94', strokeWidth: 4 }}
            type="monotone"
            dataKey="total"
            stroke="#8884d8"
          />
        </LineChart>
      </ChartContainer>
    </Container>
  )
}
