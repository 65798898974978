import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import api from '../../services/api'
import { HeadTitle } from '../../components/HeadTitle'
import { Icon } from '../../components/Icon'
import { Button } from '../Insurances/Create/styles'
import { privateRoutePaths } from '../../routes/private'

import {
  Container,
  HeaderContainer,
  ContentContainer,
  PlanContainer,
  TabButton,
  NewPlanButton,
  TabButtonContainer,
  PlansContainer,
  PlanName,
  PlanActionsContainer,
  PlanInfoContainer,
  PlanImage,
  TabButtonContent,
  NewPlanButtonContainer,
} from './styles'
import { AxiosError } from 'axios'
import { useToast } from '../../hooks/toast'

interface Plan {
  id: string
  active: true
  name: string
  description: string
  image: {
    id: string
    name: string
    path: string
    updated_at: string
    created_at: string
  }
  image_path: string
  plan_type_customer_id: string
  updated_at: string
  created_at: string
}

interface PlansTypesCustomers {
  id: string
  active: boolean
  plan_type_id: string
  name: string
  plans: Plan[]
  description: string
  created_at: Date
  updated_at: Date
}

type Icon = {
  id: string
  name: string
  path: string
}

interface PlanType {
  id: string
  company_id: string
  active: boolean
  name: string
  description: string
  plans_types_customers: PlansTypesCustomers[]
  icon: Icon
  updated_at: Date
  created_at: Date
}

enum PlanTypeCustomers {
  ADESAO = 'Produtos Adesão',
  FISICA = 'Pessoa Física',
  JURIDICA = 'Pessoa Jurídica',
}

const PlanType: React.FC = () => {
  const [plans, setPlans] = useState<PlanType>()
  const [tab, setTab] = useState<PlanTypeCustomers>(PlanTypeCustomers.FISICA)
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()
  const { insuranceId } = useParams<{ insuranceId: string }>()
  const { addToast } = useToast()

  useEffect(() => {
    async function loadPlans(): Promise<void> {
      const response = await api.get<PlanType>(`/plan-types/${insuranceId}`)

      if (Object.keys(response.data).length === 0) {
        addToast({
          type: 'error',
          title: 'Oops!',
          description: 'Seguro não encontrado ou não pertence a essa unidade!',
        })
        history.push(privateRoutePaths.insurances)
        return
      }
      setPlans(response.data)
    }

    loadPlans()
  }, [insuranceId, addToast, history])

  const handlePlanDeletion = async (planName: string, planId: string) => {
    if (window.confirm(`Deseja realmente excluir o plano: ${planName}?`)) {
      setIsLoading(true)
      try {
        await api.delete(`/plans/${planId}`)
        const response = await api.get(`/plan-types/${insuranceId}`)

        setPlans(response.data)
        addToast({
          type: 'success',
          title: 'Êxito.',
          description: `O plano ${planName} foi excluído com sucesso!`,
        })
      } catch (err) {
        const error = err as AxiosError<{ message: string }>

        addToast({
          type: 'error',
          title: 'Oops!',
          description:
            error.response?.data.message ??
            'Algo deu errado, tente novamente mais tarde.',
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleOnPlanViewClickCheckOrigin = (planId: string) => {
    switch (plans?.name) {
      case 'Saúde':
        history.push(
          privateRoutePaths.insurancePlan
            .replace(':insuranceId', insuranceId)
            .replace(':planId', planId),
        )
        break
      default:
        history.push(
          privateRoutePaths.insurancePlanMaterials.replace(':planId', planId),
        )
        break
    }
  }

  return (
    <Container>
      <HeaderContainer>
        <HeadTitle
          title={plans?.name ?? 'Planos'}
          goBack
          image={plans?.icon.path}
          description={plans?.description}
        />
      </HeaderContainer>
      <ContentContainer>
        <TabButtonContainer>
          <TabButtonContent>
            <TabButton
              onClick={() => setTab(PlanTypeCustomers.FISICA)}
              isActive={tab === PlanTypeCustomers.FISICA}
            >
              <Icon name="user" fontSize={23} />
              <span>Pessoa Física</span>
            </TabButton>
            <Link
              to={{
                pathname: privateRoutePaths.createInsurancePlan,
                state: plans?.plans_types_customers.find(
                  ptc => ptc.name === PlanTypeCustomers.FISICA,
                ),
              }}
            >
              <NewPlanButtonContainer>
                <NewPlanButton>
                  <Icon name="plus" fontSize={12} />
                </NewPlanButton>
              </NewPlanButtonContainer>
            </Link>
          </TabButtonContent>

          <TabButtonContent>
            <TabButton
              onClick={() => setTab(PlanTypeCustomers.ADESAO)}
              isActive={tab === PlanTypeCustomers.ADESAO}
            >
              <Icon name="users-alt" fontSize={23} />
              <span>Adesão</span>
            </TabButton>
            <Link
              to={{
                pathname: privateRoutePaths.createInsurancePlan,
                state: plans?.plans_types_customers.find(
                  ptc => ptc.name === PlanTypeCustomers.ADESAO,
                ),
              }}
            >
              <NewPlanButtonContainer>
                <NewPlanButton>
                  <Icon name="plus" fontSize={12} />
                </NewPlanButton>
              </NewPlanButtonContainer>
            </Link>
          </TabButtonContent>

          <TabButtonContent>
            <TabButton
              onClick={() => setTab(PlanTypeCustomers.JURIDICA)}
              isActive={tab === PlanTypeCustomers.JURIDICA}
            >
              <Icon name="briefcase" fontSize={23} />
              <span>Pessoa Jurídica</span>
            </TabButton>
            <Link
              to={{
                pathname: privateRoutePaths.createInsurancePlan,
                state: plans?.plans_types_customers.find(
                  ptc => ptc.name === PlanTypeCustomers.JURIDICA,
                ),
              }}
            >
              <NewPlanButtonContainer>
                <NewPlanButton>
                  <Icon name="plus" fontSize={12} />
                </NewPlanButton>
              </NewPlanButtonContainer>
            </Link>
          </TabButtonContent>
        </TabButtonContainer>

        <PlansContainer>
          {plans?.plans_types_customers
            .filter(ptc => ptc.name === tab)
            .map(ptc =>
              ptc.plans.map(plan => (
                <PlanContainer key={plan.id}>
                  <PlanInfoContainer>
                    <PlanImage src={plan.image.path} alt={plan.name} />
                    <PlanName>{plan.name}</PlanName>
                  </PlanInfoContainer>
                  <PlanActionsContainer>
                    <Button
                      onClick={() => handlePlanDeletion(plan.name, plan.id)}
                      disabled={isLoading}
                    >
                      Excluir
                    </Button>
                    <Link
                      to={{
                        pathname: privateRoutePaths.editInsurancePlan,
                        state: { ptc, plan },
                      }}
                    >
                      <Button>Editar</Button>
                    </Link>
                    <Button
                      onClick={() => handleOnPlanViewClickCheckOrigin(plan.id)}
                    >
                      Visualizar
                    </Button>
                  </PlanActionsContainer>
                </PlanContainer>
              )),
            )}
        </PlansContainer>
      </ContentContainer>
    </Container>
  )
}

export default PlanType
