import React, { createContext, useCallback, useContext, useState } from 'react'
import api from '../services/api'

interface Company {
  id: string
  image: {
    id: string
    name: string
    path: string
  }
  cnpj: string
  name: string
  alias: string
  email: string
  phone: string
  whatsapp: string
  instagram_url: string
  youtube_url: string
  terms_url: string
  about_url: string
  cep: string
  street: string
  street_number: string
  complement: string
  district: string
  city: string
  state: string
  broker_panel_url: string
}

interface AuthState {
  token: string
  company: Company
}

interface SignInCredentials {
  login: string
  password: string
}

interface AuthContextData {
  company: Company
  setCompany(company: Company): void
  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Texas:token')
    const company = localStorage.getItem('@Texas:company')

    if (token && company) {
      return { token, company: JSON.parse(company) }
    }

    return {} as AuthState
  })

  const signIn = useCallback(async ({ login, password }) => {
    const response = await api.post('sessions/company', {
      login,
      password,
    })

    const { token, company } = response.data

    localStorage.setItem('@Texas:token', token)
    localStorage.setItem('@Texas:company', JSON.stringify(company))

    setData({ token, company })
  }, [])

  const setCompany = useCallback(
    (company: Company) => {
      localStorage.setItem('@Texas:company', JSON.stringify(company))

      setData({ ...data, company })
    },
    [data],
  )

  const signOut = useCallback(() => {
    localStorage.removeItem('@Texas:token')
    localStorage.removeItem('@Texas:company')

    setData({} as AuthState)
  }, [])

  return (
    <AuthContext.Provider
      value={{ company: data.company, setCompany, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must bne used within an AuthProvider')
  }

  return context
}

export { AuthContext, AuthProvider, useAuth }
