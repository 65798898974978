import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isInvalid: boolean
  isVisible: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  background: var(--element);
  border-bottom: 1px solid var(--border);
  border-radius: 8px;
  transition: all 0.4s ease;
  position: relative;

  ${({ isFocused }) =>
    isFocused &&
    css`
      border-color: var(--purple);
    `}

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}

    ${({ isInvalid }) =>
    isInvalid &&
    css`
      border-color: var(--red-500);
    `}
`

interface InputContainerProps {
  isDirty: boolean
  isFocused: boolean
  currentTheme: boolean
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.6rem;
  transition: all 0.4s ease;
  padding: 5px 15px;

  input {
    width: 100%;
    font-size: 0.8rem;
    margin-top: 20px;
    background: transparent;
    border: 0;
    color: var(--text);
    text-overflow: ellipsis;
    outline: none;
  }

  input[type='range'] {
    background: var(--text);
    height: 2px;
    -webkit-appearance: none;
    appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: var(--app-gradient);
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 11px;
      height: 11px;
      background: var(--app-gradient);
      border-radius: 50%;
      cursor: pointer;
    }
  }

  input[type='color'] {
    width: 30px;
  }

  ${({ currentTheme }) => css`
    input[type='date'],
    input[type='month'] {
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        color-scheme: ${currentTheme ? 'dark' : 'light'};
      }
    }
  `}

  ${({ isDirty }) =>
    isDirty &&
    css`
      i {
        margin-top: 19px;
      }
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      i {
        margin-top: 19px;
      }
    `}
`

export const InputColorContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 5px;
`

export const SpanColor = styled.span`
  font-size: 0.8rem;
  color: var(--gray-500);
  margin-top: 20px;
`

export const LabelColor = styled.label`
  background: var(--background);
  height: fit-content;
  padding: 10px;
  border-radius: 21px;
  cursor: pointer;

  i {
    margin: 0;
  }
`

interface InputLabelProps {
  isDirty: boolean
  isFocused: boolean
  isRange: boolean
  hasIcon: boolean
}

export const InputLabel = styled.label<InputLabelProps>`
  display: flex;
  width: calc(100% - 29px);
  font-size: 14px;
  color: #696969;
  position: absolute;
  top: 15px;
  left: ${({ hasIcon }) => (hasIcon ? '40px' : '14px')};
  transition: all 0.4s ease;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      width: calc(100% - 55px);
    `}

  ${({ isRange }) =>
    isRange &&
    css`
      justify-content: space-between;
    `}

  ${({ isDirty }) =>
    isDirty &&
    css`
      top: 5px;
      font-size: 0.7rem;
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      top: 5px;
      font-size: 0.7rem;
    `}
`

export const Error = styled(Tooltip)`
  margin-top: 2px;
  max-height: 16px !important;
  z-index: 9999;

  i {
    margin: 0;
    color: var(--red-500);
  }

  span {
    word-wrap: break-word;
    background: var(--red-500);
    color: var(--white);

    &::before {
      border-color: var(--red-500) transparent;
    }
  }
`
