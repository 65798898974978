import { RouterType } from '../../../@types/router.types'
import CreateDigitalPresence from '../../../pages/CreateDigitalPresence'
import CreateDigitalPresenceGroups from '../../../pages/CreateDigitalPresenceGroups'
import DigitalPresenceGroup from '../../../pages/DigitalPresenceGroup'
import DigitalPresenceGroups from '../../../pages/DigitalPresenceGroups'

export const digitalPresenceRoutePaths = {
  digitalPresenceGroups: '/marketing',
  createDigitalPresenceGroup: '/marketing/criar',
  digitalPresenceGroup: '/marketing/imagens',
  createDigitalPresenceImage: '/marketing/imagens/criar',
}

export const digitalPresenceRoutes: RouterType[] = [
  {
    path: digitalPresenceRoutePaths.digitalPresenceGroups,
    component: DigitalPresenceGroups,
    title: 'marketing-groups',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: digitalPresenceRoutePaths.createDigitalPresenceGroup,
    component: CreateDigitalPresenceGroups,
    title: 'create-marketing',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: digitalPresenceRoutePaths.digitalPresenceGroup,
    component: DigitalPresenceGroup,
    title: 'marketing-images',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
  {
    path: digitalPresenceRoutePaths.createDigitalPresenceImage,
    component: CreateDigitalPresence,
    title: 'create-marketing-image',
    isPrivate: true,
    hasSideAndNavbar: true,
  },
]
