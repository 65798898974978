import styled from 'styled-components'
import ButtonComponent from '../../../components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`

export const TableImagePreviewContainer = styled.div`
  display: flex;
  gap: 2rem;

  div:first-child {
    display: grid;
    row-gap: 1rem;
    max-width: 14ch;
  }

  div:last-child {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    justify-content: center;

    h3 {
      color: var(--red-500);
    }

    span {
      color: var(--green-200);
      font-weight: 700;
    }
  }

  img {
    max-width: 128px;
    max-height: 128px;
  }
`

export const TableForm = styled.div`
  display: flex;
  gap: 1rem;

  div:first-child {
    width: 100%;
  }
`

export const Button = styled(ButtonComponent)`
  width: 200px;
`
