import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import Modal from 'react-modal'

import GlobalStyle from './styles/global'
import AppProvider from './hooks'
import Routes from './routes'
import { defaultTheme } from './styles/themes/default'
import { darkTheme } from './styles/themes/dark'
import { useTheme } from './hooks/theme'

Modal.setAppElement('#root')

const App: React.FC = () => {
  const { theme } = useTheme()

  return (
    <ThemeProvider theme={theme ? darkTheme : defaultTheme}>
      <Router>
        <AppProvider>
          <Routes />
        </AppProvider>

        <GlobalStyle />
      </Router>
    </ThemeProvider>
  )
}

export default App
