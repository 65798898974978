import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isInvalid: boolean
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  background: var(--element);
  border-bottom: 1px solid var(--border);
  border-radius: 8px;
  transition: all 0.4s ease;
  position: relative;
  min-height: 50px;
  min-width: 150px;
  width: 100%;
  height: fit-content;

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border-color: var(--red-500);
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      border-color: var(--blue);
    `}
`

interface SelectContainerProps {
  hasIcon: boolean
  hasLabel: boolean
  isDirty: boolean
  isFocused: boolean
}

export const SelectContainer = styled.div<SelectContainerProps>`
  display: flex;
  padding: 0 10px;
  width: 100%;
  align-items: center;
  gap: 0.6rem;
  transition: all 0.4s ease;

  label {
    font-size: 14px;
    color: #696969;
    position: absolute;
    top: 15px;
    left: ${({ hasIcon }) => (hasIcon ? '40px' : '16px')};
    transition: all 0.4s ease;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ${({ hasLabel }) =>
    hasLabel &&
    css`
      padding: 5px 15px 5px 15px;
    `}

  ${({ isDirty }) =>
    isDirty &&
    css`
      label {
        top: 5px;
        font-size: 0.7rem;
      }

      i {
        margin-top: 16px;
      }
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      label {
        top: 5px;
        font-size: 0.7rem;
      }

      i {
        margin-top: 16px;
      }
    `}

  [class^='react-select__'] {
    transition: all 0.4s ease;
  }

  .react-select-container {
    width: 100%;
    padding: 1rem 0 0 0;
    position: unset !important;
    font-size: 0.8rem !important;
  }

  .react-select__control {
    border: 0;
    min-height: unset;
    box-shadow: none;
    background: var(--element);
  }

  .react-select__dropdown-indicator {
    padding-right: 0;
  }

  .react-select__loading-indicator,
  .react-select__clear-indicator {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__input-container {
    margin: 0;
    color: var(--text);
  }

  .react-select__single-value {
    color: var(--text);
  }

  .react-select__loading-indicator {
    font-size: 0.5rem;
  }

  .react-select__indicators {
    margin-top: -15.5px;
  }

  .react-select__menu {
    left: 0;
    background: var(--element);
    color: var(--text);
    z-index: 9999;
  }

  .react-select__option--is-focused {
    background: var(--app-gradient);
    color: #fff;
  }

  .react-select__multi-value {
    background: var(--background);
    padding: 0.4rem;
    border-radius: 8px;
  }

  .react-select__multi-value__label {
    color: var(--text);
  }
`

export const Error = styled(Tooltip)`
  height: 16px;
  i {
    color: var(--red-500);
  }

  svg {
    margin: 0;
  }

  span {
    background: var(--red-500);
    color: var(--white);

    &::before {
      border-color: var(--red-500) transparent;
    }
  }
`
