import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 1rem;
  background: var(--element);
  position: relative;
`

export const AssociationNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;

  span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background: gray;
`

export const AssociationProfessionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

export const AssociationProfessionContainer = styled.span`
  width: fit-content;
  background: var(--background);
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.6rem;
`

export const EditAssociationButton = styled(Link)`
  text-decoration: none;
  position: absolute;
  top: -6px;
  right: -6px;

  button {
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    padding: 0.5rem;
  }
`
