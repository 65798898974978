import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 3rem;
`

export const InfoContainer = styled.div`
  display: grid;
  align-items: center;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`

export const ChartsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`
