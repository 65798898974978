import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  ChangeEvent,
} from 'react'
import * as Yup from 'yup'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import axios, { AxiosError } from 'axios'

import Button from '../../components/Button'
import Input from '../../components/Input'
import { useToast } from '../../hooks/toast'
import { useAuth } from '../../hooks/auth'
import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import InputUpload from '../../components/InputUpload'
import { HeadTitle } from '../../components/HeadTitle'
import { ProfileCard } from '../../components/Cards/Profile'
import {
  demask,
  normalizerCEPNumber,
  normalizerCNPJNumber,
  normalizerPhoneNumber,
} from '../../utils/normalizer'
import Select from '../../components/Select'

import {
  Container,
  LabelButton,
  LogoContainer,
  LogoPreview,
  ProfileForm,
  ProfileHeader,
} from './styles'

interface CompanyFormData {
  image: string
  cnpj: string
  name: string
  alias: string
  email: string
  phone: string
  whatsapp: string
  cep: string
  street: string
  street_number: string
  complement: string
  state: string
  city: string
  district: string
  instagram_url: string
  youtube_url: string
  terms_url: string
  about_url: string
  broker_panel_url: string
}

export interface State {
  id: number
  sigla: string
  nome: string
  regiao: {
    id: number
    sigla: string
    nome: string
  }
}

interface SelectProps {
  value: string
  label: string
}

interface CompanyDataProps
  extends Omit<CompanyFormData, 'state' | 'city' | 'image'> {
  state?: SelectProps
  city?: SelectProps
  image: string | null
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const [companyData, setCompanyData] = useState({} as CompanyDataProps)
  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [states, setStates] = useState<SelectProps[]>([])
  const [selectedState, setSelectedState] = useState<string | null>(null)
  const [selectedCity, setSelectedCity] = useState<string | null>(null)
  const [districts, setDistricts] = useState<SelectProps[]>([])

  const { addToast } = useToast()
  const { company, setCompany } = useAuth()

  useEffect(() => {
    setCompanyData({
      ...company,
      cnpj: company.cnpj && normalizerCNPJNumber(company.cnpj),
      phone: company.phone && normalizerPhoneNumber(company.phone),
      whatsapp: company.whatsapp && normalizerPhoneNumber(company.whatsapp),
      cep: company.cep && normalizerCEPNumber(company.cep),
      state: states.find(state => state.value === company.state),
      city: districts.find(district => district.value === company.city),
      image: null,
    })
  }, [company, states, districts])

  useEffect(() => {
    const getStates = async (): Promise<void> => {
      const response = await axios.get<State[]>(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome',
      )

      setStates(
        response.data.map(state => ({
          value: state.sigla,
          label: `${state.nome} (${state.sigla})`,
        })),
      )
    }

    void getStates()
  }, [])

  useEffect(() => {
    const getStateDistricts = async (): Promise<void> => {
      const response = await axios.get<Array<{ id: number; nome: string }>>(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${
          selectedState ?? company.state
        }/municipios?orderBy=nome`,
      )

      setDistricts(
        response.data.map(district => ({
          value: district.nome,
          label: district.nome,
        })),
      )
    }
    void getStateDistricts()
  }, [selectedState, company.state])

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files![0]
    setImagePreview(URL.createObjectURL(selectedFile))
  }

  const handleRemoveImagePreview = (): void => {
    if (imagePreview) URL.revokeObjectURL(imagePreview)
    setImagePreview(null)
    formRef.current?.clearField('image')
  }

  const handleUpdate = useCallback(
    async (data: CompanyFormData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          image: Yup.string(),
          name: Yup.string().required('Nome Obrigatório'),
          alias: Yup.string().required('Apelido obrigatório'),
          email: Yup.string().required('E-mail obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
          whatsapp: Yup.string(),
          cep: Yup.string().required('CEP obrigatório'),
          street: Yup.string().required('Rua obrigatória'),
          street_number: Yup.string().required('Número da rua obrigatório'),
          complement: Yup.string().required('Complement obrigatório'),
          state: Yup.string().required('Estado obrigatório'),
          city: Yup.string().required('Cidade obrigatório'),
          district: Yup.string().required('Bairro obrigatório'),
          instagram_url: Yup.string(),
          youtube_url: Yup.string(),
          terms_url: Yup.string(),
          about_url: Yup.string(),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const newData = new FormData()
        if (data.image) newData.append('image', data.image)
        if (data.name) newData.append('name', data.name)
        if (data.alias) newData.append('alias', data.alias)
        if (data.email) newData.append('email', data.email)
        if (data.phone) newData.append('phone', demask(data.phone))
        if (data.whatsapp) newData.append('whatsapp', demask(data.whatsapp))
        if (data.cep) newData.append('cep', demask(data.cep))
        if (data.street) newData.append('street', data.street)
        if (data.street_number)
          newData.append('street_number', data.street_number)
        if (data.complement) newData.append('complement', data.complement)
        if (data.state) newData.append('state', data.state)
        if (data.city) newData.append('city', data.city)
        if (data.district) newData.append('district', data.district)
        if (data.instagram_url)
          newData.append('instagram_url', data.instagram_url)
        if (data.youtube_url) newData.append('youtube_url', data.youtube_url)
        if (data.terms_url) newData.append('terms_url', data.terms_url)
        if (data.about_url) newData.append('about_url', data.about_url)
        if (data.broker_panel_url)
          newData.append('broker_panel_url', data.broker_panel_url)

        const response = await api.put('/companies', newData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        setCompany(response.data)

        addToast({
          type: 'success',
          title: 'Atualizado com sucesso!',
          description: 'Perfil atualizado com sucesso.',
        })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      }
    },
    [addToast, setCompany],
  )

  const handleChangeState = (newValue: any) => {
    formRef.current?.setFieldValue('state', newValue)
    const state: SelectProps = newValue
    setSelectedState(state.value)
  }

  const handleChangeCity = (newValue: any) => {
    formRef.current?.setFieldValue('city', newValue)
    const city: SelectProps = newValue
    setSelectedCity(city.value)
  }

  return (
    <Container>
      <HeadTitle title="Perfil" />
      <ProfileHeader>
        <ProfileCard />
        <LogoContainer>
          <div>
            {imagePreview && (
              <Button onClick={handleRemoveImagePreview}>
                Remover Preview
              </Button>
            )}
            <LabelButton htmlFor="image">
              <p>Alterar Logo</p>
            </LabelButton>
          </div>
          <div>
            <LogoPreview
              src={imagePreview ?? company.image.path}
              alt={company.alias}
            />
          </div>
        </LogoContainer>
      </ProfileHeader>
      <Form ref={formRef} onSubmit={handleUpdate} initialData={companyData}>
        <ProfileForm>
          <InputUpload
            name="image"
            label="Logo da Unidade"
            accept="image/*"
            onInputChange={handleImageChange}
            visible={false}
          />
          <Input name="cnpj" label="CNPJ" disabled={true} />
          <Input name="name" label="Nome" />
          <Input name="alias" label="Apelido" />
          <Input name="email" label="E-mail" />
          <Input
            name="phone"
            label="Telefone"
            maxLength={15}
            onChange={() =>
              formRef.current?.setFieldValue(
                'phone',
                normalizerPhoneNumber(formRef.current.getFieldValue('phone')),
              )
            }
          />
          <Input
            name="whatsapp"
            label="Whatsapp"
            maxLength={15}
            onChange={() =>
              formRef.current?.setFieldValue(
                'whatsapp',
                normalizerPhoneNumber(
                  formRef.current.getFieldValue('whatsapp'),
                ),
              )
            }
          />
          <Input
            name="cep"
            label="CEP"
            maxLength={9}
            onChange={() =>
              formRef.current?.setFieldValue(
                'cep',
                normalizerCEPNumber(formRef.current.getFieldValue('cep')),
              )
            }
          />
          <Input name="street" label="Rua" />
          <Input name="street_number" label="Número da rua" />
          <Input name="complement" label="Complemento" />
          <Select
            name="state"
            label="Estado"
            options={states}
            onChange={handleChangeState}
            value={
              states.find(state => state.value === selectedState) ??
              companyData.state
            }
            menuPlacement="top"
          />
          <Select
            name="city"
            label="Cidade"
            options={districts}
            onChange={handleChangeCity}
            value={
              districts.find(district => district.value === selectedCity) ??
              companyData.city
            }
            menuPlacement="top"
          />
          <Input name="district" label="Bairro" />
          <Input name="instagram_url" label="Link do Instagram" />
          <Input name="youtube_url" label="Link do Youtube" />
          <Input name="terms_url" label="Link do Termo de compromisso" />
          <Input name="about_url" label="Link do Sobre a Empresa" />
          <Input name="broker_panel_url" label="Link do Painel do Corretor" />
          <Button type="submit">Atualizar Perfil</Button>
        </ProfileForm>
      </Form>
    </Container>
  )
}

export default Profile
