import React, { useEffect, useState } from 'react'
import { NCard } from '../../components/Cards/Normal'
import { ProfileCard } from '../../components/Cards/Profile'
import { AccessesChart } from '../../components/Charts/Accesses'
import { PlansRanking } from '../../components/Charts/Ranking'

import { HeadTitle } from '../../components/HeadTitle'
import { useAuth } from '../../hooks/auth'
import api from '../../services/api'

import { ChartsContainer, Container, InfoContainer } from './styles'

const Dashboard: React.FC = () => {
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [plansCount, setPlansCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const { company } = useAuth()

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const notificationsResponse = await api.get<[]>(
        `/notifications/${company.id}`,
      )
      const plansCountResponse = await api.get<number>('/plans')

      setNotificationsCount(notificationsResponse.data.length)
      setPlansCount(plansCountResponse.data)
      setIsLoading(false)
    }
    getData()
  }, [company.id])

  return (
    <Container>
      <HeadTitle title="Painel Administrativo" />

      <InfoContainer>
        <ProfileCard />

        <NCard
          title="Informativos cadastrados"
          data={notificationsCount}
          dataLoading={isLoading}
        />

        <NCard
          title="Planos Cadastrados"
          data={plansCount}
          dataLoading={isLoading}
        />
      </InfoContainer>
      <ChartsContainer>
        <PlansRanking />
        <AccessesChart />
      </ChartsContainer>
    </Container>
  )
}

export default Dashboard
