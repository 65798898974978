import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: var(--contrast-blue);
  width: 100%;

  color: var(--white);
  border: 2px solid var(--purple);
  border-radius: 5px;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  div[role='application'] {
    width: 100% !important;
    border-radius: 4px;
    background: var(--contrast-blue);
  }

  svg {
    margin-right: 0 !important;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--red-500);
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: var(--blue);
    `}

  ${props =>
    props.isFilled &&
    css`
      border-color: var(--blue);
    `}


  svg {
    margin-right: 16px;
  }
`

export const Error = styled(Tooltip)`
  z-index: 2;
  position: absolute;
  right: 20px;

  svg {
    margin: 0;
  }

  span {
    background: var(--red-500);
    color: var(--white);

    &::before {
      border-color: var(--red-500) transparent;
    }
  }
`
