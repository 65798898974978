import React, { useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { AxiosError } from 'axios'

import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { useToast } from '../../hooks/toast'
import { HeadTitle } from '../../components/HeadTitle'

import { Container, FormContainer } from './styles'

interface PlanLink {
  id: string
  plan_id: string
  active: string
  name: string
  url: string
  created_at: string
  updated_at: string
}

interface PlanLinkFormData {
  id: string
  name: string
  url: string
}

const EditPlanLink: React.FC = () => {
  const { state }: { state: PlanLink } = useLocation()
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const { addToast } = useToast()

  const planLink = { ...state }

  const handleSubmit = useCallback(
    async (data: PlanLinkFormData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          url: Yup.string().required('Link obrigatório'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await api.put(`/link/${planLink.id}`, data)

        addToast({
          type: 'success',
          title: 'Atualizado com sucesso!',
          description: 'Atualizado com sucesso.',
        })

        history.goBack()
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return
        }

        const error = err as AxiosError<{ message: string }>
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            error.response?.data.message ||
            'Ocorreu um erro ao processar a solicitação, tente novamente.',
        })
      }
    },
    [addToast, history, planLink],
  )

  return (
    <Container>
      <HeadTitle title={`Alterar link: ${planLink.name}`} goBack />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          name: planLink.name,
          url: planLink.url,
        }}
      >
        <FormContainer>
          <Input name="name" placeholder="Nome" label="Nome" />
          <Input name="url" placeholder="URL" label="URL" icon="link-alt" />

          <Button type="submit">Atualizar {planLink.name}</Button>
        </FormContainer>
      </Form>
    </Container>
  )
}

export default EditPlanLink
