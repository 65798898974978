import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  row-gap: 2rem;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--contrast-blue);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 30px;
  margin-bottom: 40px;

  > h1 {
    margin-bottom: 20px;
  }

  > img {
    height: 150px;
    object-fit: contain;
    margin-bottom: 20px;
  }

  div {
    /* max-width: 600px; */
    word-wrap: break-word;
  }
`
