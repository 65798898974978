import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  row-gap: 2rem;

  form {
    display: grid;
    row-gap: 1rem;
  }
`

export const FirstLine = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
`
