import styled, { css } from 'styled-components'
import { PlatformType } from '.'
import Button from '../../components/Button'

export const Container = styled.div`
  display: grid;
  row-gap: 3rem;

  form {
    display: grid;
    row-gap: 2rem;

    select {
      width: 100%;
      height: 53px;
    }
  }
`

export const ObsContainer = styled.div`
  display: grid;
  row-gap: 1rem;

  div:first-child {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    i {
      color: orange;
    }
  }
`

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  align-items: end;
`

export const CampaignHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div:last-child {
    display: flex;
    gap: 0.5rem;

    button {
      width: 100px;
      border-radius: 2px;
    }
  }
`

interface CampaignButtonProps {
  active: boolean
}

export const CampaignButton = styled(Button)<CampaignButtonProps>`
  width: 100px;
  border-radius: 8px !important;
  background: transparent;
  border: 1px solid var(--purple);
  color: var(--text);

  ${({ active }) =>
    active &&
    css`
      background: var(--app-gradient);
      color: var(--white);
    `}
`

export const CampaignsContainer = styled.div`
  display: grid;
  row-gap: 1.5rem;
`

interface CampaignProps {
  platform: PlatformType
}

export const Campaign = styled.div<CampaignProps>`
  display: flex;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  background: var(--element);
  justify-content: space-between;
  align-items: center;
  min-height: 144px;

  @media (max-width: 860px) {
    flex-direction: column;
    gap: 1rem;
  }

  button {
    width: 20%;
  }

  div {
    display: flex;
    gap: 1rem;
    align-items: center;

    @media (max-width: 860px) {
      flex-direction: column;
      gap: 1rem;
    }

    img {
      width: 335px;
      height: 124px;
      border-radius: 2px;
    }
  }

  ${({ platform }) =>
    platform === PlatformType.WEB &&
    css`
      div {
        img {
          width: 971px;
          height: 187px;
          zoom: 0.4;
        }
      }
    `}
`

export const CallToAction = styled.div`
  display: grid;
  justify-items: center;
  margin-top: 5rem;

  div {
    text-align: center;
    margin-top: -32px;

    strong {
      font-size: 2rem;
    }

    button {
      width: fit-content;
      margin-top: 1rem;
    }
  }
`
