import styled, { keyframes } from 'styled-components'

import outlineXBackground from '../../assets/outline-x.svg'
import outlineX2Background from '../../assets/outline-x-2.svg'

export const Background = styled.div`
  background: url(${outlineXBackground}), url(${outlineX2Background});
  background-repeat: no-repeat;
  background-position: bottom right;
  height: 100vh;
`

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  max-width: 1278px;
  height: 100vh;
  margin: 0 auto;
  padding: 0 5rem;
  justify-content: center;
  animation: ${appearFromLeft} 1.5s;
  color: var(--text);

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 700px) {
      text-align: center;
    }

    a {
      text-decoration: none;
      color: var(--gray-500);

      transition: filter 0.3s;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  gap: 1rem;

  h1 {
    font-weight: 900;
    font-size: 5rem;
    max-width: 9ch;
  }

  p {
    max-width: 40ch;
  }
`

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 1rem;

  button {
    width: 150px;
  }

  @media (max-width: 600px) {
    align-items: center;
    width: 100%;

    button {
      width: unset;
    }
  }
`
