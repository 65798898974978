import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const Head = styled.div`
  display: grid;
  row-gap: 0.7rem;

  svg {
    font-size: 35px;
    color: var(--green-200);
  }

  h1 {
    font-size: 25px;
  }

  p {
    font-size: 20px;
  }
`

export const Main = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 650px) {
    gap: 1rem;
  }

  button {
    border-radius: 5px;
    background: var(--green-200);
    padding: 0.5rem;

    transition: filter 0.3s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const GoBackContainer = styled.div`
  button {
    background: transparent;
    border: 0;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: color 0.2s ease;
    text-decoration: none;
    color: var(--text);

    &:hover {
      color: var(--gray-500);
      i {
        color: var(--gray-500);
      }
    }
  }
`
