import styled, { css, keyframes } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start !important;

  img {
    height: 50px;
  }
`

export const TabButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`

export const progressBarAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`

export const TabButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  a {
    text-decoration: none;
  }
`

interface TabButtonProps {
  isActive: boolean
}

export const TabButton = styled.button<TabButtonProps>`
  display: flex;
  justify-content: space-between;
  background: var(--element);
  flex: 1;
  align-items: center;
  border-radius: 2px;
  color: var(--text);
  border: 0;
  outline: none;
  position: relative;
  padding: 0 22px;
  height: 50px;

  &:before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;

    ${({ isActive }) =>
      isActive &&
      css`
        background: var(--app-gradient);
      `}
  }

  &:after {
    content: '';
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      &:hover {
        &:before {
          background: var(--app-gradient);
          animation: ${progressBarAnimation} 0.7s forwards;
        }
      }
    `}
`

export const NewPlanButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: var(--app-gradient);
  border-radius: 2px;
  transition: filter 0.5s ease;

  &:hover {
    filter: brightness(0.7);
  }
`

export const NewPlanButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: var(--background);
  border-radius: 50%;
  border: 0;
`

export const PlansContainer = styled.div`
  display: grid;
  gap: 10px;
`

export const PlanContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  background: var(--element);

  @media (max-width: 730px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`

export const PlanInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`

export const PlanImage = styled.img`
  width: 33px;
  height: 33px;
  background: #fff;
  border-radius: 50%;
  object-fit: contain;
`

export const PlanName = styled.p``

export const PlanActionsContainer = styled.div`
  display: flex;
  gap: 10px;

  button {
    width: 6rem;
    padding: 0;
    height: 30px;
    border-radius: 2px;
    background: transparent;
    border: 1px solid transparent;
  }

  button:first-child {
    border-color: var(--red-500);
    color: var(--red-500);
  }

  button:last-child {
    border-color: var(--text);
    color: var(--text);
  }
`

export const ContentContainer = styled.div`
  display: grid;
  gap: 30px;
`
