import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { useField } from '@unform/core'
import { Editor, IAllProps } from '@tinymce/tinymce-react'
import { Container, Error } from './styles'

interface TextAreaProps extends IAllProps {
  name: string
}

const RichTextArea: React.FC<TextAreaProps> = ({ name, ...rest }) => {
  const inputRef = useRef<Editor>(null)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name)

  const handleInputFocus = useCallback(() => {
    clearError()

    setIsFocused(true)
  }, [clearError])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    setIsFilled(!!inputRef.current?.editor?.getContent())
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: Editor) => {
        return ref.editor?.getContent()
      },
    })
  }, [fieldName, registerField])

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <Editor
        apiKey="y1ww7l6e07fqkz8j5qh35p3g2nkig6ljhrxia3f9ccoaudwp"
        init={{
          language: 'pt_BR',
          language_url: 'https://texas-api.s3.sa-east-1.amazonaws.com/pt_BR.js',
          menubar: false,
          plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount',
            'autoresize',
          ],
          toolbar: [
            {
              name: '1',
              items: ['undo', 'redo'],
            },
            {
              name: '2',
              items: ['formatselect'],
            },
            {
              name: '3',
              items: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            {
              name: '4',
              items: ['fontsizeselect', 'forecolor', 'backcolor'],
            },
            {
              name: '4',
              items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
            },
            {
              name: '5',
              items: ['bullist', 'numlist', 'outdent', 'indent'],
            },
            {
              name: '6',
              items: ['table', 'lists'],
            },
            {
              name: '7',
              items: ['media', 'image', 'link'],
            },
            {
              name: '8',
              items: ['removeformat', 'preview', 'print'],
            },
          ],
        }}
        initialValue={defaultValue}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        ref={inputRef}
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" />
        </Error>
      )}
    </Container>
  )
}

export default RichTextArea
